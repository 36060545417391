import {Palette} from 'hubbl-shared';
import {PropsWithChildren, useLayoutEffect, useRef, useState} from 'react';
import {Squircle} from '../squircle/Squircle';
import styled from 'styled-components';

interface WhiteContainerProps extends PropsWithChildren {
  borderRadius?: number;
  color?: Palette;
  gradient?: string;
}

const GradientMask = styled.div<{gradient: string}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.gradient};
  mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,0 h100 v100 h-100 z'/%3E%3C/svg%3E");
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,0 h100 v100 h-100 z'/%3E%3C/svg%3E");
`;

const ContainerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const WhiteContainer = ({
  children,
  borderRadius = 24,
  color = Palette.white,
  gradient,
}: WhiteContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <ContainerWrapper ref={containerRef}>
      {width > 0 && (
        <Squircle width={width} fillColor={color} borderRadius={borderRadius}>
          {gradient && <GradientMask gradient={gradient} />}
          <div style={{padding: 24, position: 'relative', zIndex: 1}}>
            {children}
          </div>
        </Squircle>
      )}
    </ContainerWrapper>
  );
};
