import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '..';

const getEvents = createSelector(
  (state: RootState) => state.events,
  events => events,
);

export const EventSelector = {
  getEvents,
};
