import {HubblAttendee, Palette} from 'hubbl-shared';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Api} from '../../../../../api';
import {AssetIconChevron} from '../../../../../assets';
import {
  Field,
  PaddingBottom,
  Scene,
  TextHeading1,
  TextHeading3,
  TextHeading5,
  WhiteContainer,
  HtmlRenderer,
} from '../../../../../components';
import {AttendeeRack} from '../../../../../components/attendee-rack/AttendeeRack';
import {IconButton} from '../../../../../components/buttons/icon-button/IconButton';
import {UserListModal} from '../../../../../components/modals/UserListModal';
import {useAppSelector} from '../../../../../hooks';
import {EventDTO} from '../../../../../models/event/EventDTO';
import {EventSelector} from '../../../../../store/events/EventSelector';
import {UserSelector} from '../../../../../store/user/UserSelector';
import {EventDetailsQuestionsData} from './components/questions-data/EventDetailsQuestionsData';
import {EventDetailsActionBar} from './components/action-bar/EventDetailsActionBar';
import {RootRoute} from '../../../../../routes/RootRoute';

const StyledHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const StyledDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  padding: 16px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledDescriptionContainer = styled.div`
  height: 60px;
  overflow: hidden;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(transparent, white);
  }
`;

export const SceneEventDetails = () => {
  const {eventId} = useParams(); // Get eventId from URL
  const navigate = useNavigate();
  const events = useAppSelector(EventSelector.getEvents).events;
  const [event, setEvent] = useState<EventDTO | null>(null);
  const [showAttendeesModal, setShowAttendeesModal] = useState(false);
  const [showDeclinedModal, setShowDeclinedModal] = useState(false);

  const userDetails = useAppSelector(UserSelector.getUserDetails);

  useEffect(() => {
    const loadEvent = async () => {
      let foundEvent = events.find(evt => evt.id === eventId);

      if (!foundEvent && userDetails && eventId) {
        foundEvent = await Api.event.getEventById(
          userDetails.companyId,
          eventId,
        );
      }
      setEvent(foundEvent || null);
    };

    loadEvent();
  }, [eventId, events, userDetails]);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const iconBack = useMemo(() => {
    return (
      <div
        style={{
          justifyItems: 'flex-start',
        }}>
        <div style={{rotate: '180deg'}}>
          <IconButton
            hoverColor={Palette.almostBlackTransparent5}
            icon={<AssetIconChevron size={32} />}
            onClick={goBack}
          />
        </div>
      </div>
    );
  }, [goBack]);

  if (!event) {
    return (
      <Scene>
        <StyledHeaderTitle>
          {iconBack}
          <TextHeading1 text="Event Not Found" />
        </StyledHeaderTitle>
      </Scene>
    );
  }
  const onViewEvent = () => {
    window.open(
      `${window.location.origin}${RootRoute.Event}/${event?.id}`,
      '_blank',
    );
  };

  return (
    <Scene>
      {iconBack}
      <PaddingBottom amount={32} />
      <WhiteContainer>
        <EventDetailsActionBar event={event} />
        <div style={{paddingLeft: 16, paddingTop: 16}}>
          <StyledHeaderTitle>
            <TextHeading1 text={`${event.eventTitle.en} ${event.eventEmoji}`} />
          </StyledHeaderTitle>
          <PaddingBottom amount={32} />
          <TextHeading3 text="Event overview" />
        </div>
        <StyledDetailsGrid>
          <Field label="Event title" value={event.eventTitle.en} />
          <Field label="Event emoji" value={event.eventEmoji ?? ''} />
          <Field
            label="Created date"
            value={event.created.toDate().toDateString()}
          />
          <Field
            label="Start date"
            value={event.startDateTime.toDate().toDateString()}
          />
          <Field
            label="End date"
            value={event.endDateTime?.toDate().toDateString() || 'N/A'}
          />
          <Field
            label="Deadline"
            value={event.deadline?.toDate().toDateString() || 'N/A'}
          />
          <Field
            label="Publish date"
            value={event.marketingStartDate?.toDate().toDateString() || 'N/A'}
          />
          <Field
            label="Location"
            value={event.eventLocation || 'No location specified'}
          />
          <FieldContainer onClick={() => setShowAttendeesModal(true)}>
            <TextHeading5
              text={`Attendees ${
                event.attendees.length > 0
                  ? '(' + event.attendees.length + ')'
                  : ''
              }`}
            />
            <AttendeeRack users={event.attendees} />
          </FieldContainer>
          <FieldContainer onClick={() => setShowDeclinedModal(true)}>
            <TextHeading5
              text={`Declined Users ${
                event.declinedUsers?.length > 0
                  ? '(' + event.declinedUsers?.length + ')'
                  : ''
              }`}
            />
            <AttendeeRack
              moreBackgroundColor={Palette.primaryCherokee}
              users={event.declinedUsers?.map(user => user.user)}
            />
          </FieldContainer>
          <Field label="Chat messages" value={event.messages.length - 1} />
          <FieldContainer onClick={onViewEvent}>
            <TextHeading5 text="Description" />
            <StyledDescriptionContainer>
              <HtmlRenderer html={event.eventDescription.en} />
            </StyledDescriptionContainer>
          </FieldContainer>
        </StyledDetailsGrid>
        <PaddingBottom amount={48} />
      </WhiteContainer>
      <PaddingBottom amount={24} />
      {event.questions.length > 0 && (
        <WhiteContainer>
          <EventDetailsQuestionsData event={event} />
        </WhiteContainer>
      )}
      <PaddingBottom amount={54} />

      <UserListModal
        isOpen={showAttendeesModal}
        onClose={() => setShowAttendeesModal(false)}
        title="Attendees"
        users={event.attendees as HubblAttendee[]}
      />
      <UserListModal
        isOpen={showDeclinedModal}
        onClose={() => setShowDeclinedModal(false)}
        title="Declined Users"
        users={event.declinedUsers}
      />
    </Scene>
  );
};
