import {Palette} from 'hubbl-shared';
import {useCallback, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {AppLogo} from '../../assets/branding/AssetAppLogo';
import backgroundImage from '../../assets/images/getStartedBackgroundImage.png';
import {
  CheckMarkWithLabel,
  ManagedInput,
  PaddingBottom,
  PrimaryButton,
  Scene,
  TextHeading1,
  TextHeading7,
} from '../../components';
import {useAppDispatch} from '../../hooks';
import {RootRoute} from '../../routes/RootRoute';
import {fetchCompany} from '../../store/company/CompanyThunks';
import {GlobalActions} from '../../store/global/GlobalActions';
import {
  fetchUserDetails,
  signInAdmin,
  signInUser,
  signOutUser,
} from '../../store/user/UserThunks';
import {GoogleSignInButton} from './components/google-sign-in-button/GoogleSignInButton';

const EMAIL_FIELD = 'EMAIL_FIELD';
const PASSWORD_FIELD = 'PASSWORD_FIELD';

const CONTENT_WIDTH = 450;
const CONTENT_PADDING = 24;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Palette.primarySubtleGreen};
  width: min(${CONTENT_WIDTH}px, 90vw);
  padding: ${CONTENT_PADDING}px;
  border-radius: 24px;
  box-shadow:
    hsla(220, 30%, 5%, 0.1) 0px 5px 15px 0px,
    hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px;

  /* Center the content inside */
  align-items: flex-start;
  justify-content: center;

  /* Make the container as tall as its content */
  max-height: 90vh; /* Avoid taking the full height */
  overflow-y: auto; /* Enable scrolling if content is too tall */
  box-sizing: border-box;
`;

export const SceneLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {control, handleSubmit} = useForm();

  const dispatch = useAppDispatch();
  const [rememberMe, setRememberMe] = useState(false);

  const returnUrl = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('returnUrl');
  }, [location.search]);

  const onClickLogin = useCallback(
    async (data: any) => {
      try {
        const email = data[EMAIL_FIELD] ?? ('' as string);
        const password = data[PASSWORD_FIELD] ?? ('' as string);

        dispatch(GlobalActions.setIsBusy(true));

        // Get return URL from query parameters

        if (returnUrl) {
          await dispatch(signInUser({email, password, rememberMe})).unwrap();
          await dispatch(fetchUserDetails());
          await dispatch(fetchCompany());
          navigate(returnUrl, {replace: true});
        } else {
          try {
            await dispatch(signInAdmin({email, password, rememberMe})).unwrap();
            await dispatch(fetchUserDetails());
            await dispatch(fetchCompany());

            navigate(RootRoute.AuthAdminHome, {replace: true});
          } catch (error) {
            //Show error
            window.alert(`Only admins can access`);
            await dispatch(signOutUser());
          }
        }
      } catch (error) {
        //Show error
        window.alert(`error signing in`);
      } finally {
        dispatch(GlobalActions.setIsBusy(false));
      }
    },
    [dispatch, navigate, rememberMe, returnUrl],
  );

  const onClickRememberMe = useCallback(() => {
    setRememberMe(prev => !prev);
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh' /* Full viewport height */,
        width: '100%' /* Full viewport width */,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden' /* Prevent unnecessary scrollbars */,
        scrollbarWidth: 'none' /* Hide scrollbars visually */,
      }}>
      <Scene>
        <StyledContainer>
          <AppLogo size={80} />
          <PaddingBottom amount={8} />

          <TextHeading1 text="Sign in" />
          <PaddingBottom amount={24} />
          <GoogleSignInButton returnUrl={returnUrl} />
          <PaddingBottom amount={48} />

          <TextHeading7 text={'Email'} />
          <PaddingBottom amount={8} />
          <ManagedInput
            isCompact
            control={control}
            placeholder="myname@email.com"
            controlKey={EMAIL_FIELD}
            isRequiredMessage={'Cannot be left blank'}
            pattern={{
              message: 'Not a valid email',
              value: RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
            }}
          />

          <PaddingBottom amount={24}></PaddingBottom>

          <TextHeading7 text={'Password'} />
          <PaddingBottom amount={8} />
          <ManagedInput
            isCompact
            control={control}
            placeholder="Enter password"
            controlKey={PASSWORD_FIELD}
            isPassword
            isRequiredMessage={'Cannot be left blank'}
            pattern={{
              message: 'Not a valid password',
              value: RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
              ),
            }}
          />
          <PaddingBottom amount={16} />

          <CheckMarkWithLabel
            isChecked={rememberMe}
            onClick={onClickRememberMe}
            label="Remember me"
          />

          <PaddingBottom amount={24} />
          <div
            style={{
              alignSelf: 'center',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <PrimaryButton
              label="Sign in with email"
              customWidth={Math.min(
                CONTENT_WIDTH - CONTENT_PADDING * 2,
                window.innerWidth - 48,
              )}
              isCompact
              onClick={handleSubmit(onClickLogin)}
            />
          </div>
        </StyledContainer>
      </Scene>
    </div>
  );
};
