import moment from 'moment';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Palette} from 'hubbl-shared';
import {getEventById} from '../../api/ApiEvent';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {EventDTO} from '../../models/event/EventDTO';
import {RootRoute} from '../../routes/RootRoute';
import {GlobalActions} from '../../store/global/GlobalActions';
import {getAuthUserCompanyId} from '../../utils';
import {EventLeftPanel} from './components/left-panel/EventLeftPanel';
import {EventRightPanel} from './components/right-panel/EventRightPanel';
import {UserSelector} from '../../store/user/UserSelector';
import {AppLogo} from '../../assets/branding/AssetAppLogo';
import {UserInfoDisplay} from '../../components';
import {useStorageImageUrl} from '../../hooks/api/useStorageImageUrl';
import {useDominantColors} from '../../hooks/useDominantColors';

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 1rem;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  min-height: 100vh;
  background: #f8f9fa;
  position: relative;

  @media (max-width: 768px) {
    display: block;
    padding-top: 60px;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const RightPanelDesktop = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SceneEventEndpoint = () => {
  const {eventId} = useParams<{eventId: string}>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState<EventDTO | undefined>();
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GlobalActions.setIsBusy(isLoading));
  }, [isLoading, dispatch]);

  const coverImageUrl = useStorageImageUrl(event?.coverImageUrl) || null;
  const dominantColors = useDominantColors(coverImageUrl ?? undefined);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        // Get company ID
        const companyId = await getAuthUserCompanyId();
        if (!companyId || !eventId) {
          throw new Error('Missing required data');
        }

        // Get event
        const fetchedEvent = await getEventById(companyId, eventId);
        if (!fetchedEvent || fetchedEvent.companyId !== companyId) {
          throw new Error('Event not found or unauthorized');
        }

        setEvent(fetchedEvent);
      } catch (error) {
        console.error('Error fetching event:', error);
        setError(error instanceof Error ? error.message : 'Unknown error');
        navigate(RootRoute.LogIn, {replace: true});
      } finally {
        setIsLoading(false);
      }
    };

    fetchEventData();
  }, [eventId, navigate]);

  const daysUntilRsvp = useMemo(() => {
    if (!event) return null;

    if (event.deadline) {
      return moment(event.deadline?.toDate()).diff(moment(), 'days');
    }

    return moment(event.startDateTime.toDate()).diff(moment(), 'days');
  }, [event]);

  const userDetails = useAppSelector(UserSelector.getUserDetails);

  const isUserAttending = useMemo(() => {
    if (!userDetails || !event) return false;
    return event.attendees.some(attendee => attendee.id === userDetails.id);
  }, [event, userDetails]);

  const rightPanelContent = useMemo(
    () =>
      event && (
        <EventRightPanel
          isUserAttending={isUserAttending}
          event={event}
          setEvent={setEvent}
        />
      ),
    [isUserAttending, event, setEvent],
  );

  if (isLoading) {
    return null;
  }

  if (!event || error) return <div>Event not found</div>;

  return (
    <>
      <Header>
        <AppLogo
          size={70}
          color={
            isUserAttending ? Palette.almostBlack : dominantColors?.textColor
          }
          transition={'1s ease-in-out'}
        />
        <UserInfoDisplay isCompact />
      </Header>
      <PageContainer>
        <EventLeftPanel
          coverImageUrl={coverImageUrl}
          dominantColors={dominantColors}
          event={event}
          daysUntilRsvp={daysUntilRsvp}
          isUserAttending={isUserAttending}
          rightPanelContent={rightPanelContent}
        />
        <RightPanelDesktop>{rightPanelContent}</RightPanelDesktop>
      </PageContainer>
    </>
  );
};
