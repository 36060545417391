import {Outlet} from 'react-router-dom';
import {Sidebar, Topbar} from '../../../components';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  height: 100%; /* Full height of the viewport */
`;

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent scrolling outside the Scene */
`;

export const SceneAdminHome = () => {
  return (
    <Layout>
      <Sidebar />
      <Main>
        <Topbar />
        <Outlet />
      </Main>
    </Layout>
  );
};
