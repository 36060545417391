import {ReactNode, memo} from 'react';
import styled from 'styled-components';

interface SceneProps {
  children: ReactNode;
}

const StyledScene = styled.div`
  height: 100%; /* Full height of the parent container */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-top: 24px;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
`;

export const Scene = memo(({children}: SceneProps) => {
  return <StyledScene>{children}</StyledScene>;
});
