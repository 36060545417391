import React, {memo} from 'react';
import {Palette} from 'hubbl-shared';
import {FontConfig} from '../../constants/FontConfig';

interface HtmlRendererProps {
  html: string;
  contentWidth?: number;
  textColor?: Palette.white | Palette.almostBlack;
}

const getStyles = (textColor: Palette.white | Palette.almostBlack) => `
  .html-renderer {
    color: ${textColor};
    font-family: ${FontConfig.BodyMedium.fontFamily};
    font-size: ${FontConfig.BodyMedium.fontSize}px;
    line-height: ${FontConfig.BodyMedium.lineHeight};
    transition: color 1s ease-in-out;
  }
  .html-renderer p {
    margin: 0;
    padding: 0;
    color: ${textColor};
    transition: color 1s ease-in-out;
  }
  .html-renderer h2 {
    color: ${textColor};
    font-family: ${FontConfig.Heading2.fontFamily};
    font-size: ${FontConfig.Heading2.fontSize}px;
    line-height: ${FontConfig.Heading2.lineHeight};
    margin-bottom: 4px;
    margin-top: 4px;
    transition: color 1s ease-in-out;
  }
  .html-renderer h3 {
    color: ${textColor};
    font-family: ${FontConfig.Heading3.fontFamily};
    font-size: ${FontConfig.Heading3.fontSize}px;
    line-height: ${FontConfig.Heading3.lineHeight};
    margin-bottom: 4px;
    margin-top: 4px;
    transition: color 1s ease-in-out;
  }
  .html-renderer ul {
    color: ${textColor};
    margin-left: 0;
    transition: color 1s ease-in-out;
  }
  .html-renderer ol {
    color: ${textColor};
    margin-left: 0;
    transition: color 1s ease-in-out;
  }
  .html-renderer li {
    color: ${textColor};
    margin: 2px 0;
    transition: color 1s ease-in-out;
  }
  .html-renderer a {
    color: ${Palette.primaryDustySky};
    text-decoration: underline;
    transition: color 1s ease-in-out;
  }
`;

export const HtmlRenderer = memo(
  ({html, textColor = Palette.almostBlack}: HtmlRendererProps) => {
    const styles = getStyles(textColor);

    return (
      <>
        <style>{styles}</style>
        <div
          className="html-renderer"
          dangerouslySetInnerHTML={{__html: html}}
        />
      </>
    );
  },
);
