import {AnimatePresence, motion} from 'framer-motion';
import {DeclinedUser, HubblAttendee, Palette} from 'hubbl-shared';
import {memo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {AvatarStorageRef, PrimaryButton, TextHeading5} from '..';
import {CloseButton} from '../buttons/close-button/CloseButton';
import {TextHeading2} from '../text/TextHeading2';

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background: ${Palette.white};
  border-radius: 16px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const Header = styled.div`
  padding: 24px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: ${Palette.primarySubtleGreen};
`;

const TableHeaderCell = styled.th`
  padding: 12px 16px;
  text-align: left;
  font-weight: 500;
  color: ${Palette.almostBlack};
  border-bottom: 1px solid ${Palette.almostBlackTransparent5};
`;

const TableBody = styled.tbody`
  tr:hover {
    transition: background-color 0.3s ease-in-out;
    background-color: ${Palette.primaryCherokee};
  }
`;

const TableCell = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid ${Palette.almostBlackTransparent5};
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

interface UserListModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  users: HubblAttendee[] | DeclinedUser[];
}

export const UserListModal = memo(
  ({isOpen, onClose, title, users}: UserListModalProps) => {
    if (!isOpen) return null;

    const isDeclinedUsers = users.length > 0 && 'declinedAt' in users[0];

    return (
      <AnimatePresence>
        <ModalOverlay
          onClick={onClose}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.2, ease: 'easeInOut'}}>
          <ModalContent
            initial={{y: 20, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: -20, opacity: 0}}
            transition={{duration: 0.2, ease: 'easeInOut'}}
            whileHover={{
              scale: 1.01,
              transition: {duration: 0.2, ease: 'easeInOut'},
            }}
            onClick={e => e.stopPropagation()}>
            <CloseButton onClick={onClose} />
            <Header>
              <TextHeading2 text={title} />
            </Header>
            <Table>
              <TableHeader>
                <tr>
                  <TableHeaderCell>
                    <TextHeading5 text="User" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <TextHeading5 text="Email" />
                  </TableHeaderCell>
                  {isDeclinedUsers && (
                    <TableHeaderCell>
                      <TextHeading5 text="Declined At" />
                    </TableHeaderCell>
                  )}
                </tr>
              </TableHeader>
              <TableBody>
                {users.map(user => {
                  const displayUser = 'user' in user ? user.user : user;
                  return (
                    <tr key={displayUser.id}>
                      <TableCell>
                        <UserInfo>
                          <AvatarStorageRef imageRef={displayUser.photoUrl} />
                          <TextHeading5 text={displayUser.fullName} />
                        </UserInfo>
                      </TableCell>
                      <TableCell>
                        <TextHeading5 text={displayUser.email} />
                      </TableCell>
                      {isDeclinedUsers && 'declinedAt' in user && (
                        <TableCell>
                          <TextHeading5
                            text={moment(user.declinedAt).format(
                              'DD. MMM HH:mm',
                            )}
                          />
                        </TableCell>
                      )}
                    </tr>
                  );
                })}
              </TableBody>
            </Table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '16px 16px',
              }}>
              <PrimaryButton label="Close" onClick={onClose} />
            </div>
          </ModalContent>
        </ModalOverlay>
      </AnimatePresence>
    );
  },
);
