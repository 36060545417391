import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {DocumentSnapshot} from 'firebase/firestore';
import {Company, User} from 'hubbl-shared';
import {
  addHRIStoCompany,
  fetchCompany,
  fetchCompanyEmployees,
} from './CompanyThunks';

interface CompanyState {
  employeeOverview: {
    employees: User[];
    lastVisible: DocumentSnapshot<User> | null;
    loading: boolean;
    error: string | null;
    hasMore: boolean;
  };
  companyDetails: Company | null;
  apideckSession: {
    session_token: string | null;
    session_uri: string | null;
  };
}

const initialState: CompanyState = {
  employeeOverview: {
    employees: [],
    lastVisible: null,
    loading: false,
    error: null,
    hasMore: true,
  },
  companyDetails: null,
  apideckSession: {
    session_token: null,
    session_uri: null,
  },
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // setUserDetails: (state, action: PayloadAction<User>) => {
    //   state.userDetails = action.payload;
    // },
    resetCompanyDetails: state => {
      state.employeeOverview = {
        employees: [],
        lastVisible: null,
        loading: false,
        error: null,
        hasMore: true,
      };
      state.companyDetails = null;
    },
    setApideckSession: (
      state,
      action: PayloadAction<{session_token: string; session_uri: string}>,
    ) => {
      state.apideckSession = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      state.companyDetails = action.payload;
    });
    builder
      .addCase(addHRIStoCompany.fulfilled, (state, action) => {
        if (state.companyDetails) {
          state.companyDetails.settings.connectedHRIS = action.payload;
        }
      })
      .addCase(fetchCompanyEmployees.pending, state => {
        state.employeeOverview.loading = true;
        state.employeeOverview.error = null;
      })
      .addCase(fetchCompanyEmployees.fulfilled, (state, action) => {
        state.employeeOverview.loading = false;
        state.employeeOverview.hasMore = action.payload.lastVisible !== null;

        // Check if it's the next page or first page
        if (action.payload.isNextPage) {
          state.employeeOverview.employees = [
            ...state.employeeOverview.employees,
            ...action.payload.employees,
          ]; // Append new events for pagination
        } else {
          state.employeeOverview.employees = action.payload.employees; // Overwrite events on the first page load
        }

        state.employeeOverview.lastVisible = action.payload.lastVisible; // Update the lastVisible for pagination
      })
      .addCase(fetchCompanyEmployees.rejected, (state, action) => {
        state.employeeOverview.loading = false;
        state.employeeOverview.hasMore = false;
        state.employeeOverview.error =
          action.error.message || 'Error fetching employees';
      });
  },
});

export const {resetCompanyDetails, setApideckSession} = companySlice.actions;

export const CompanyReducer = companySlice.reducer;
