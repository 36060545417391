import {Palette} from 'hubbl-shared';
import {AssetIconProps} from '../types';
import {memo} from 'react';

export const AssetIconLoadMore = memo(
  ({size = 24, color = Palette.almostBlack}: AssetIconProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 2.75C9.4787 2.75 7.19427 3.75776 5.52503 5.39414C5.22924 5.68411 4.75439 5.67939 4.46443 5.3836C4.17446 5.08781 4.17918 4.61296 4.47497 4.32299C6.41323 2.42288 9.07039 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C9.07039 22.75 6.41323 21.5771 4.47497 19.677C4.17918 19.387 4.17446 18.9122 4.46443 18.6164C4.75439 18.3206 5.22924 18.3159 5.52503 18.6059C7.19427 20.2422 9.4787 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75Z"
          fill={color}
        />
        <path
          d="M5.50022 2L5.00022 5H8.00022"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  },
);
