import styled from 'styled-components';
import {AssetIconEyeOpen} from '../../../../../../../assets';
import {IconButton} from '../../../../../../../components/buttons/icon-button/IconButton';
import {EventDTO} from '../../../../../../../models/event/EventDTO';
import {RootRoute} from '../../../../../../../routes/RootRoute';
import {ExportEventDataCSVButton} from '../export-data-csv-button/ExportEventDataCSVButton';
const ActionBarContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  right: 16px;
  top: 16px;
`;

interface EventDetailsActionBarProps {
  event: EventDTO;
}

export const EventDetailsActionBar = ({event}: EventDetailsActionBarProps) => {
  const onViewEvent = () => {
    window.open(
      `${window.location.origin}${RootRoute.Event}/${event.id}`,
      '_blank',
    );
  };

  return (
    <ActionBarContainer>
      <ExportEventDataCSVButton event={event} />
      <IconButton
        text="View"
        iconPadding={8}
        icon={<AssetIconEyeOpen />}
        onClick={onViewEvent}
      />
    </ActionBarContainer>
  );
};
