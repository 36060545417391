import {AdminWebRoute} from './AdminWebRoute';

export enum RootRoute {
  LogIn = '/',
  GetStarted = '/get-started',
  GetStartedCompleted = '/get-started-complete',
  //Admin scenes
  AuthAdminHome = '/admin/',

  //Super admin?
  Invited = '/invited',
  AuthAdminLandingPage = '/auth-admin',
  Event = '/events',
}

export type WebRoute = RootRoute | AdminWebRoute;
