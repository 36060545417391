import React, {createContext, useContext, useEffect, useState} from 'react';
import {getAuth, onAuthStateChanged, User} from 'firebase/auth';
import {httpsCallable} from 'firebase/functions';
import {firebaseFunctions} from '../api/firebase';

interface AuthContextProps {
  user: User | null;
  isLoading: boolean;
  isAdmin: boolean | null; // Add this state to track admin status
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  isLoading: true,
  isAdmin: null,
});

export const AuthProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null); // Add admin status

  useEffect(() => {
    const auth = getAuth();

    // Check the authentication state on app initialization
    const unsubscribe = onAuthStateChanged(auth, async currentUser => {
      setIsLoading(true); // Set loading to true until we verify admin status

      if (currentUser) {
        // Perform the admin check after the user is authenticated
        try {
          const checkStatus = httpsCallable<{isAdmin: boolean}>(
            firebaseFunctions,
            'onCheckAuth',
          );
          try {
            const response = await checkStatus();
            //@ts-ignore
            const isAdminStatus = response.data.isAdmin;
            setIsAdmin(isAdminStatus); // Set the admin status
          } catch (error) {
            setIsAdmin(false);
          }
          setUser(currentUser);
        } catch (error) {
          console.error('Error checking admin status:', error);
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(null); // Reset admin status when user is null
      }

      setIsLoading(false); // Set loading to false after the admin check completes
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  return (
    <AuthContext.Provider value={{user, isLoading, isAdmin}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
