import {Palette} from 'hubbl-shared';
import {useEffect, useState} from 'react';
import {BrowserView} from 'react-device-detect';
import styled from 'styled-components';
import appDownloadQRImage from '../../../../assets/images/appDownloadQR.png';
import {
  Confetti,
  HtmlRenderer,
  PaddingBottom,
  SquircleImage,
  TextDisplaySmall,
  TextHeading1,
  TextHeading7,
} from '../../../../components';
import {EventDTO} from '../../../../models/event/EventDTO';
import {Gradient} from './gradient/Gradient';

interface StyledProps {
  $isAlternative?: boolean;
}

const Panel = styled.div<StyledProps>`
  padding: 4rem;
  position: relative;
  overflow-y: auto;
  max-height: 100vh;
  position: sticky;
  top: 0;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 2rem;
    position: static;
    max-height: none;
    overflow: visible;
    height: auto;
    padding-bottom: 2rem;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const GradientWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  height: 100%;

  @media (max-width: 768px) {
    position: fixed;
    height: 100vh;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    position: relative;
    min-height: calc(100vh - 60px);
  }
`;

const CoverImageWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
`;

const RSVPWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  background-color: ${Palette.white};
  padding: 4px 8px;
  border-radius: 12px;
  box-shadow: 0px 2.858px 17.148px 0px rgba(0, 0, 0, 0.2);
`;

const MobileRightPanel = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

interface Props {
  event: EventDTO;
  daysUntilRsvp: number | null;
  isUserAttending: boolean;
  coverImageUrl: string | null;
  dominantColors: {
    color1: string;
    color2: string;
    color3: string;
    textColor: Palette.white | Palette.almostBlack;
  } | null;
  rightPanelContent?: React.ReactNode;
}

export const EventLeftPanel = ({
  event,
  daysUntilRsvp,
  isUserAttending,
  coverImageUrl,
  dominantColors,
  rightPanelContent,
}: Props) => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (isUserAttending) {
      setShowConfetti(true);
    }
  }, [isUserAttending]);

  return (
    <Panel $isAlternative={isUserAttending}>
      <GradientWrapper>
        <Gradient
          isAlternative={isUserAttending}
          alternativeColors={dominantColors || undefined}
        />
      </GradientWrapper>
      <Content>
        <CoverImageWrapper>
          {dominantColors && (
            <Confetti
              isActive={showConfetti}
              onComplete={() => setShowConfetti(false)}
              extraColors={[
                dominantColors.color1,
                dominantColors.color2,
                dominantColors.color3,
              ]}
            />
          )}
          <SquircleImage
            src={coverImageUrl || ''}
            alt={event.eventTitle.en}
            height={225}
            borderRadius={24}
          />

          <RSVPWrapper>
            <TextHeading7 text={`RSVP within ${daysUntilRsvp} days`} />
          </RSVPWrapper>
        </CoverImageWrapper>

        <TextHeading1
          text={`${event.eventTitle.en} ${event.eventEmoji}`}
          color={
            isUserAttending ? Palette.almostBlack : dominantColors?.textColor
          }
          style={{transition: 'color 1s ease-in-out'}}
        />
        <PaddingBottom amount={32} />
        <HtmlRenderer
          html={event.eventDescription.en}
          textColor={
            isUserAttending ? Palette.almostBlack : dominantColors?.textColor
          }
        />
        <PaddingBottom amount={50} />
        <BrowserView
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 200,
          }}>
          <div
            style={{
              position: 'relative',
              display: 'inline-block',
              justifyContent: 'center',
            }}>
            <img
              src={appDownloadQRImage}
              alt="qr code"
              height={'100%'}
              width={100}
            />
            <div
              style={{
                position: 'absolute',
                left: 90,
                width: 100,
                top: -20,
                rotate: '20deg',
              }}>
              <TextDisplaySmall
                color={
                  isUserAttending
                    ? Palette.almostBlack
                    : dominantColors?.textColor
                }
                center
                text="Scan to download app"
              />
            </div>
          </div>
        </BrowserView>

        <MobileRightPanel>
          {rightPanelContent}
          <PaddingBottom amount={48} />
        </MobileRightPanel>
      </Content>
    </Panel>
  );
};
