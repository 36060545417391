import {memo, useCallback} from 'react';

import {saveAs} from 'file-saver';
import {Question, QuestionType} from 'hubbl-shared';
import Papa from 'papaparse';
import {AssetIconDownload} from '../../../../../../../assets';
import {IconButton} from '../../../../../../../components/buttons/icon-button/IconButton';
import {EventDTO} from '../../../../../../../models/event/EventDTO';
import moment from 'moment';

const exportEventDataToCSV = (event: EventDTO) => {
  if (!event.questionResponses || !event.questions) {
    console.warn('No responses or questions available.');
    return;
  }

  // Helper: Map Question ID → Question Data
  const questionMap = new Map<string, Question>(
    event.questions.map(q => [q.id, q]),
  );

  // Get a list of all question IDs in the correct order
  const questionIds = event.questions.map(q => q.id);

  // Extract unique users from responses
  const userResponsesMap = new Map<
    string,
    {
      department: string;
      email: string;
      fullName: string;
      answers: Record<string, string>;
    }
  >();

  Object.entries(event.questionResponses).forEach(([questionId, responses]) => {
    responses.forEach(response => {
      const userId = response.user.id;
      if (!userResponsesMap.has(userId)) {
        userResponsesMap.set(userId, {
          department: response.user.department,
          email: response.user.email,
          fullName: response.user.fullName,
          answers: {},
        });
      }

      const question = questionMap.get(questionId);
      if (!question) return;

      let answerText = '';

      if (question.type === QuestionType.FreeText) {
        answerText = response.freeTextResponse ?? '';
      } else if (question.options) {
        const selectedOptionsText = response.selectedOptions
          .map(
            optionId =>
              question.options?.find(opt => opt.id === optionId)?.text ||
              `Unknown Option (${optionId})`,
          )
          .join(', ');

        if (response.freeTextResponse) {
          answerText = selectedOptionsText
            ? `${selectedOptionsText} (Extra: ${response.freeTextResponse})`
            : response.freeTextResponse;
        } else {
          answerText = selectedOptionsText;
        }
      }

      userResponsesMap.get(userId)!.answers[questionId] =
        answerText || 'No response';
    });
  });

  // Prepare CSV data with three tables
  const csvRows = [];

  if (event.questions.length !== 0) {
    // Table 1: Question Responses
    csvRows.push(['=== Question Responses ===']);
    const responseHeaders = [
      'Department',
      'Email',
      'Full Name',
      ...questionIds.map(
        qId => questionMap.get(qId)?.questionText || `Q-${qId}`,
      ),
    ];
    csvRows.push(responseHeaders);

    userResponsesMap.forEach(({email, fullName, department, answers}) => {
      const row = [
        department,
        email,
        fullName,
        ...questionIds.map(qId => answers[qId] || 'No response'),
      ];
      csvRows.push(row);
    });

    // Add blank lines between tables
    csvRows.push([]);
    csvRows.push([]);
  }

  // Table 2: Attending Users
  csvRows.push(['=== Attending Users ===']);
  csvRows.push(['Department', 'Email', 'Full Name']);
  event.attendees.forEach(user => {
    csvRows.push([user.department.label.en, user.email, user.fullName]);
  });

  // Add blank lines between tables
  csvRows.push([]);
  csvRows.push([]);

  // Table 3: Declined Users
  csvRows.push(['=== Declined Users ===']);
  csvRows.push(['Department', 'Email', 'Full Name', 'Declined At']);
  event.declinedUsers?.forEach(declinedUser => {
    csvRows.push([
      declinedUser.user.department.label.en,
      declinedUser.user.email,
      declinedUser.user.fullName,
      moment(declinedUser.declinedAt).format('DD. MMM HH:mm'),
    ]);
  });

  // Convert to CSV
  const csv = Papa.unparse(csvRows);

  // Create and trigger file download
  const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  saveAs(blob, `event_${event.id}_responses.csv`);
};

interface ExportEventDataCSVButtonProps {
  event: EventDTO;
}

export const ExportEventDataCSVButton = memo(
  ({event}: ExportEventDataCSVButtonProps) => {
    const onClick = useCallback(() => {
      exportEventDataToCSV(event);
    }, [event]);

    return (
      <IconButton
        text="CSV"
        iconPadding={8}
        icon={<AssetIconDownload />}
        onClick={onClick}
      />
    );
  },
);
