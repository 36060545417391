import {memo} from 'react';
import {AssetIconCheckmarkEmpty, AssetIconCheckmarkFilled} from '../../assets';

interface CheckMarkProps {
  isChecked: boolean;
  onClick: () => void;
}

const SIZE = 16;
export const CheckMark = memo(({isChecked, onClick}: CheckMarkProps) => {
  return (
    <div style={{height: SIZE, width: SIZE}} onClick={onClick}>
      {isChecked ? (
        <AssetIconCheckmarkFilled size={SIZE} />
      ) : (
        <AssetIconCheckmarkEmpty size={SIZE} />
      )}
    </div>
  );
});
