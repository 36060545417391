import {
  arrayUnion,
  doc,
  getDoc,
  runTransaction,
  setDoc,
} from 'firebase/firestore';
import {
  DeclinedUser,
  FirestoreCollection,
  QuestionResponses,
  User,
} from 'hubbl-shared';
import {EventDTO} from '../models/event/EventDTO';
import {firestore} from './firebase';

import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';

import {DocumentSnapshot} from 'firebase/firestore';
import {mapUserToUserDTO} from './utils/map-incoming-users/mapUserToUserDTO';

const addEvent = async (companyId: string, eventDto: EventDTO) => {
  const promise = new Promise<void>(async (resolve, reject) => {
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );

      const eventsRef = doc(
        companyRef,
        FirestoreCollection.Events,
        eventDto.id,
      );

      await setDoc(eventsRef, eventDto);

      resolve();
    } catch (error) {
      console.log('🚀🚀🚀🚀 ~ promise ~ error:', error);
      reject(error);
    }
  });
  return promise;
};

export const getEvents = async (
  companyId: string,
  limitCount: number,
  lastVisible: DocumentSnapshot<EventDTO> | null,
): Promise<{
  events: EventDTO[];
  lastVisibleSnapshot: DocumentSnapshot<EventDTO> | null;
}> => {
  try {
    const companyRef = collection(
      firestore,
      FirestoreCollection.Companies,
      companyId,
      FirestoreCollection.Events,
    );

    // Start the query after the last visible document if it's provided (for pagination)
    const eventsQuery = lastVisible
      ? query(
          companyRef,
          orderBy('created', 'desc'),
          limit(limitCount),
          startAfter(lastVisible),
        )
      : query(companyRef, orderBy('created', 'desc'), limit(limitCount));

    const querySnapshot = await getDocs(eventsQuery);

    // Map the querySnapshot to Event data
    const events: EventDTO[] = querySnapshot.docs.map(doc => {
      const data = doc.data() as EventDTO;
      return {
        ...data,
        id: doc.id,
      };
    });

    // Get the last visible document to continue pagination
    const lastVisibleSnapshot =
      querySnapshot.docs[querySnapshot.docs.length - 1] || null;

    return {
      events,

      lastVisibleSnapshot: lastVisibleSnapshot
        ? (lastVisibleSnapshot as DocumentSnapshot<EventDTO>)
        : null,
    };
  } catch (error) {
    console.error('Error fetching events:', error);
    throw new Error('Error fetching events');
  }
};
// Get a Single Event by ID
export const getEventById = async (
  companyId: string,
  eventId: string,
): Promise<EventDTO | undefined> => {
  try {
    // Reference to the event document
    const eventRef = doc(
      firestore,
      FirestoreCollection.Companies,
      companyId,
      FirestoreCollection.Events,
      eventId,
    );

    // Get the document snapshot
    const eventDoc = await getDoc(eventRef);

    // Check if the document exists
    if (eventDoc.exists()) {
      const data = eventDoc.data() as EventDTO;
      return {
        ...data,
        id: eventDoc.id,
      };
    } else {
      console.log('No such event!');
      return undefined; // Event doesn't exist
    }
  } catch (error) {
    console.error('Error fetching event:', error);
    throw new Error('Error fetching event');
  }
};

const joinEvent = async (
  companyId: string,
  eventId: string,
  userAcceptor: User,
) => {
  try {
    const eventRef = doc(
      firestore,
      FirestoreCollection.Companies,
      companyId,
      FirestoreCollection.Events,
      eventId,
    );

    await runTransaction(firestore, async transaction => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists()) {
        throw new Error('Event not found');
      }

      const eventData = eventDoc.data() as EventDTO;

      // Check if user is already in attendees to prevent duplicates
      if (
        eventData.attendees.some(attendee => attendee.id === userAcceptor.id)
      ) {
        throw new Error('User is already attending this event');
      }

      // Add the new user to attendees within the transaction
      transaction.update(eventRef, {
        attendees: [...eventData.attendees, mapUserToUserDTO(userAcceptor)],
      });
    });
  } catch (error) {
    console.error('Error in joinEvent transaction:', error);
    throw new Error('Error joining event');
  }
};

const leaveEvent = async (
  companyId: string,
  eventId: string,
  userLeaving: User,
) => {
  try {
    const eventRef = doc(
      firestore,
      FirestoreCollection.Companies,
      companyId,
      FirestoreCollection.Events,
      eventId,
    );

    await runTransaction(firestore, async transaction => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists()) {
        throw new Error('Event not found');
      }

      const eventData = eventDoc.data() as EventDTO;
      // Filter out the leaving user
      const updatedAttendees = eventData.attendees.filter(
        attendee => attendee.id !== userLeaving.id,
      );

      const currentResponses = eventData.questionResponses || {};

      // Remove the withdrawn user's responses from questionResponses

      Object.keys(currentResponses).forEach(questionId => {
        currentResponses[questionId] = currentResponses[questionId].filter(
          (response: any) => response.user.id !== userLeaving.id,
        );

        // If no responses remain for the question, delete the key
        if (currentResponses[questionId].length === 0) {
          delete currentResponses[questionId];
        }
      });

      // Update the event with the new attendees array within the transaction
      transaction.update(eventRef, {
        attendees: updatedAttendees,
        questionResponses: currentResponses,
      });
    });
  } catch (error) {
    console.error('Error in leaveEvent transaction:', error);
    throw new Error('Error leaving event');
  }
};

const declineEvent = async (
  companyId: string,
  eventId: string,
  declinedUser: DeclinedUser,
) => {
  try {
    const eventRef = doc(
      firestore,
      FirestoreCollection.Companies,
      companyId,
      FirestoreCollection.Events,
      eventId,
    );

    await runTransaction(firestore, async transaction => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists()) {
        throw new Error('Event not found');
      }

      const eventData = eventDoc.data() as EventDTO;

      // Check if user is already in attendees to prevent duplicates
      if (
        eventData.declinedUserIds.some(
          declinedUserId => declinedUserId === declinedUser.user.id,
        )
      ) {
        throw new Error('User has already declined this event');
      }

      // Add the new user to declinedUserIds within the transaction
      transaction.update(eventRef, {
        declinedUserIds: arrayUnion(declinedUser.user.id),
        declinedUsers: arrayUnion(declinedUser),
      });
    });
  } catch (error) {
    console.error('Error in declineEvent transaction:', error);
    throw new Error('Error declining event');
  }
};
const acceptEventWithQuestionResponses = async (
  companyId: string,
  eventId: string,
  userAcceptor: User,
  responses: QuestionResponses,
) => {
  try {
    const eventRef = doc(
      firestore,
      FirestoreCollection.Companies,
      companyId,
      FirestoreCollection.Events,
      eventId,
    );
    await runTransaction(firestore, async transaction => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists) {
        throw new Error('Event not found');
      }

      const remoteEvent = eventDoc.data() as EventDTO;
      const currentResponses = remoteEvent.questionResponses || {};
      const updatedResponses = {...currentResponses};

      Object.entries(responses).forEach(([questionId, response]) => {
        if (updatedResponses[questionId]) {
          updatedResponses[questionId] = [
            ...updatedResponses[questionId],
            ...response,
          ];
        } else {
          updatedResponses[questionId] = response;
        }
      });

      const updates: Record<string, any> = {
        questionResponses: updatedResponses,
      };

      // Add the new user to the attendees list
      updates.attendees = arrayUnion(mapUserToUserDTO(userAcceptor));

      transaction.update(eventRef, updates);
    });
  } catch (error) {
    console.error('Transaction failed:', error);
    throw error;
  }
};

const undeclineEvent = async (
  companyId: string,
  eventId: string,
  userId: string,
) => {
  try {
    const eventRef = doc(
      firestore,
      FirestoreCollection.Companies,
      companyId,
      FirestoreCollection.Events,
      eventId,
    );

    await runTransaction(firestore, async transaction => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists()) {
        throw new Error('Event not found');
      }

      const eventData = eventDoc.data() as EventDTO;

      // Remove user from declined lists
      transaction.update(eventRef, {
        declinedUserIds: eventData.declinedUserIds.filter(id => id !== userId),
        declinedUsers: eventData.declinedUsers.filter(
          (user: DeclinedUser) => user.user.id !== userId,
        ),
      });
    });
  } catch (error) {
    console.error('Error in undeclineEvent transaction:', error);
    throw new Error('Error undececlining event');
  }
};

export const event = {
  addEvent,
  getEvents,
  getEventById,
  joinEvent,
  leaveEvent,
  acceptEventWithQuestionResponses,
  declineEvent,
  undeclineEvent,
};
