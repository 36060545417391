import {Palette} from 'hubbl-shared';
import {useCallback} from 'react';
import {Api} from '../../../../api';
import {AssetIconLogOut} from '../../../../assets';
import {useAppDispatch} from '../../../../hooks';
import {resetUserDetails} from '../../../../store/user/UserReducer';
import {IconButton} from '../../../buttons/icon-button/IconButton';
import {resetCompanyDetails} from '../../../../store/company/CompanyReducer';
import {persistor} from '../../../../store';
import {resetEvents} from '../../../../store/events/EventReducer';

interface MenuItemsContainerProps {
  isCollapsed: boolean;
}

export const SidebarFooter = ({isCollapsed}: MenuItemsContainerProps) => {
  const dispatch = useAppDispatch();

  const onLogout = useCallback(async () => {
    await Api.user.onLogOut();
    dispatch(resetUserDetails());
    dispatch(resetCompanyDetails());
    dispatch(resetEvents());
    await persistor.purge();
  }, [dispatch]);

  return (
    <div style={{paddingBottom: 64, justifyContent: 'center', display: 'flex'}}>
      <IconButton
        color={Palette.systemError}
        icon={<AssetIconLogOut color={Palette.systemError} />}
        text={isCollapsed ? undefined : 'Log out'}
        onClick={onLogout}
        hoverColor={Palette.whiteTransparent60}
      />
    </div>
  );
};
