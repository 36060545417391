import {GridColDef} from '@mui/x-data-grid';
import {
  AvatarStorageRef,
  DataGridColumnHeader,
  Scene,
  TextHeading3,
} from '../../../../components';
import {DataGridCellText} from '../../../../components/data-grid/components/cell-text/DataGridCellText';
import styled from 'styled-components';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';
import {Api} from '../../../../api';
import {useAppSelector} from '../../../../hooks';
import {UserSelector} from '../../../../store/user/UserSelector';

//@ts-ignore
const columns: GridColDef[] = [
  {
    field: 'photoUrl',
    headerName: 'Image',
    width: 70,
    renderCell: params => <AvatarStorageRef imageRef={params.value} />,
    renderHeader: params => (
      <DataGridColumnHeader text={params.colDef.headerName} />
    ),
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    width: 150,
    renderHeader: params => (
      <DataGridColumnHeader text={params.colDef.headerName} />
    ),
    renderCell: params => <DataGridCellText text={params.value} />,
  },
  {
    field: 'jobTitle',
    headerName: 'Job title',
    width: 150,
    renderHeader: params => (
      <DataGridColumnHeader text={params.colDef.headerName} />
    ),
    renderCell: params => <DataGridCellText text={params.value} />,
  },

  {
    field: 'department',
    headerName: 'Department',
    width: 150,
    renderHeader: params => (
      <DataGridColumnHeader text={params.colDef.headerName} />
    ),
    renderCell: params => <DataGridCellText text={params.value.label.en} />,
  },

  {
    field: 'email',
    headerName: 'Email',
    width: 150,
    renderHeader: params => (
      <DataGridColumnHeader text={params.colDef.headerName} />
    ),
    renderCell: params => <DataGridCellText text={params.value} />,
  },
];

// yup schema
const schema = yup.object().shape({
  description: yup.string().required('description is required.'),
  endDateTime: yup.string(),
  startDateTime: yup.string().required('startDateTime is required.'),
  emoji: yup.string().required('emoji is required.'),
  location: yup.string(),
});

interface IFormInput {
  endDateTime?: string;
  description: string;
  startDateTime: string;
  emoji: string;
  location?: string;
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  height: 35px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;
const StyledSubmitButton = styled.input.attrs({
  type: 'submit',
})`
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #0056b3;
  }
`;

const StyledContainer = styled.div`
  display: flex;
`;

export const _SceneEmployees = () => {
  //  const companyUsers = useAppSelector(CompanySelector.getCompanyUsers);
  const userDetails = useAppSelector(UserSelector.getUserDetails);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onCreateHubblSuggestion = useCallback(
    async ({
      description,
      emoji,
      endDateTime,
      startDateTime,
      location,
    }: IFormInput) => {
      const finalLocation = location ?? null;

      await Api.superAdmin.addHubblGroupSuggestion({
        companyId: userDetails?.companyId ?? '',
        startDateTime,
        endDateTime,
        location: finalLocation,
        emoji,
        description,
      });
    },
    [userDetails?.companyId],
  );

  return (
    <Scene>
      {/* <DataGrid
        rows={companyUsers}
        density="comfortable"
        disableEval
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        style={{
          accentColor: 'red',
          columnRuleColor: 'red',
        }}
        disableRowSelectionOnClick
      /> */}
      <StyledContainer>
        <StyledForm onSubmit={handleSubmit(onCreateHubblSuggestion)}>
          <TextHeading3 text={'Create hubbl suggestion'} />
          <StyledLabel>Description</StyledLabel>
          <StyledInput {...register('description')} />
          {errors.description && <p>{errors.description.message}</p>}
          <StyledLabel>Start date time</StyledLabel>
          <StyledInput {...register('startDateTime')} />
          {errors.startDateTime && <p>{errors.startDateTime.message}</p>}
          <StyledLabel>End date time</StyledLabel>
          <StyledInput {...register('endDateTime')} />
          {errors.endDateTime && <p>{errors.endDateTime.message}</p>}
          <StyledLabel>location?</StyledLabel>
          <StyledInput {...register('location')} />
          <StyledLabel>Emoji</StyledLabel>
          <StyledInput {...register('emoji')} />
          {errors.emoji && <p>{errors.emoji.message}</p>}

          <StyledSubmitButton type="submit" />
        </StyledForm>
      </StyledContainer>
    </Scene>
  );
};
