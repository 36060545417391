import {yupResolver} from '@hookform/resolvers/yup';
import {HubblGroupSuggestion} from 'hubbl-shared';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import {Api} from '../../../../api';
import {Scene, TextHeading3} from '../../../../components';

// yup schema
const schema = yup.object().shape({
  danish: yup.string().required('Danish is required.'),
  english: yup.string().required('English is required.'),
  emoji: yup.string().required('emoji is required.'),
});

interface IFormInput {
  danish: string;
  english: string;
  emoji: string;
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  height: 35px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;
const StyledSubmitButton = styled.input.attrs({
  type: 'submit',
})`
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #0056b3;
  }
`;

const StyledContainer = styled.div`
  display: flex;
`;

export const _SceneAddHubblSuggestion = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onCreateHubblSuggestion = useCallback(
    async ({danish, emoji, english}: IFormInput) => {
      const hubblSuggest: HubblGroupSuggestion = {
        description: {
          da: danish,
          en: english,
        },
        emoji,
      };

      await Api.superAdmin.addGeneralHubblSuggestion(hubblSuggest);
    },
    [],
  );

  return (
    <Scene>
      <StyledContainer>
        <StyledForm onSubmit={handleSubmit(onCreateHubblSuggestion)}>
          <TextHeading3 text={'Add general hubbl suggestion'} />
          <StyledLabel>Danish label</StyledLabel>
          <StyledInput {...register('danish')} />
          {errors.danish && <p>{errors.danish.message}</p>}
          <StyledLabel>English label</StyledLabel>
          <StyledInput {...register('english')} />
          {errors.english && <p>{errors.english.message}</p>}
          <StyledLabel>Emoji</StyledLabel>
          <StyledInput {...register('emoji')} />
          {errors.emoji && <p>{errors.emoji.message}</p>}

          <StyledSubmitButton type="submit" />
        </StyledForm>
      </StyledContainer>
    </Scene>
  );
};
