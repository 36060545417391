import {Palette} from 'hubbl-shared';
import {memo} from 'react';

interface AppLogoProps {
  size?: number;
  color?: string;
  transition?: string;
}

export const AppLogo = memo(
  ({size = 166, color = Palette.almostBlack, transition}: AppLogoProps) => {
    const pathStyle = transition
      ? {transition: `fill ${transition}`}
      : undefined;

    return (
      <svg
        width={size}
        height={size * (40 / 166)}
        viewBox="0 0 166 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_306_67)">
          <path
            d="M65.9482 29.2619V11.2581H60.2654V25.966C60.2654 29.718 57.6769 32.2556 53.8219 32.2556C50.1684 32.2556 47.7834 29.7714 47.7834 26.0688V11.2581H42.1006V27.7393C42.1006 33.7741 46.0584 37.7295 51.9962 37.7295C55.7504 37.7295 58.6946 36.1063 60.6231 33.1659C61.4352 35.7528 63.6681 37.1213 67.1694 37.1213H68.8451V31.6433H68.3372C66.8651 31.6433 65.9523 30.731 65.9523 29.2598L65.9482 29.2619Z"
            fill={color}
            style={pathStyle}
          />
          <path
            d="M86.905 10.6498C83.0479 10.6498 79.8509 12.4251 77.7702 15.2648V0.608215H72.0874V37.1233H77.7702V33.1166C79.8509 35.9562 83.0479 37.7316 86.905 37.7316C94.1606 37.7316 99.6419 31.9001 99.6419 24.1907C99.6419 16.4812 94.1606 10.6498 86.905 10.6498ZM85.7886 32.2536C81.2222 32.2536 77.7702 28.7543 77.7702 24.1907C77.7702 19.6271 81.2201 16.1278 85.7886 16.1278C90.357 16.1278 93.7556 19.6271 93.7556 24.1907C93.7556 28.7543 90.3549 32.2536 85.7886 32.2536Z"
            fill={color}
            style={pathStyle}
          />
          <path
            d="M118.771 10.6498C114.914 10.6498 111.717 12.4251 109.636 15.2648V0.608215H103.953V37.1233H109.636V33.1166C111.717 35.9562 114.914 37.7316 118.771 37.7316C126.027 37.7316 131.508 31.9001 131.508 24.1907C131.508 16.4812 126.027 10.6498 118.771 10.6498ZM117.654 32.2536C113.088 32.2536 109.636 28.7543 109.636 24.1907C109.636 19.6271 113.086 16.1278 117.654 16.1278C122.223 16.1278 125.621 19.6271 125.621 24.1907C125.621 28.7543 122.221 32.2536 117.654 32.2536Z"
            fill={color}
            style={pathStyle}
          />
          <path
            d="M146.885 29.5659C150.995 24.7475 153.379 18.5606 153.379 12.2217C153.379 4.51431 149.777 0 144.245 0C138.41 0 134.604 4.91909 134.604 13.9457C134.604 20.1325 136.177 25.5592 138.969 29.6152C137.497 30.7824 135.873 31.7953 134.098 32.5577L136.28 37.1213C138.564 36.2583 140.694 35.0419 142.622 33.622C145.412 35.8535 148.865 37.1213 152.722 37.1213H154.498V31.6433H153.077C150.744 31.6433 148.663 30.883 146.887 29.5639L146.885 29.5659ZM140.439 13.5409C140.439 8.06288 141.911 5.47799 144.093 5.47799C146.274 5.47799 147.542 7.91288 147.542 12.2238C147.542 16.9394 145.97 21.5051 143.178 25.2571C141.401 22.1133 140.437 18.0552 140.437 13.5429L140.439 13.5409Z"
            fill={color}
            style={pathStyle}
          />
          <path
            d="M161.939 29.4653C159.655 29.4653 157.879 31.2406 157.879 33.5234C157.879 35.8063 159.655 37.5302 161.939 37.5302C164.224 37.5302 165.998 35.8063 165.998 33.5234C165.998 31.2406 164.273 29.4653 161.939 29.4653Z"
            fill={color}
            style={pathStyle}
          />
          <path
            d="M37.2629 0.608215H31.5061V16.2593C27.0816 16.2942 21.7627 16.3621 18.1113 16.4997C18.0969 15.9285 18.0825 15.3552 18.0681 14.7861C17.9468 9.99025 17.7124 3.63488 17.5829 0.608215L11.8323 0.856841C11.9618 3.83213 12.1941 10.1628 12.3154 14.9319C12.3319 15.5997 12.3504 16.2716 12.3668 16.9415C7.91147 17.7387 4.13872 20.0647 1.95936 23.3791C-0.822405 27.6078 -0.618861 33.2583 2.44252 36.8131C4.16545 38.8144 6.84647 40 9.63234 40C9.66935 40 9.70842 40 9.74542 40C12.3545 39.9692 14.7271 38.8863 16.2547 37.0268C18.7281 34.0165 18.5574 30.0735 18.4443 27.4639C18.3847 26.079 18.3251 24.0653 18.2675 22.0455C21.5407 21.7825 26.7341 21.7394 31.5082 21.7455V37.1233H37.265V21.7661C38.0832 21.7702 38.844 21.7722 39.5204 21.7722V16.2244C39.3785 16.2244 38.5458 16.2244 37.265 16.2285V0.608215H37.2629ZM11.3923 33.7844C10.9461 34.3289 9.96542 34.8508 9.05872 34.8611C7.92998 34.8734 7.013 34.2302 6.39004 33.4679C4.79869 31.52 5.41138 28.5961 6.76628 26.5372C7.92792 24.7722 10.0271 23.2126 12.521 22.5818C12.5765 24.4763 12.632 26.3811 12.6896 27.7105C12.7883 30.0057 12.3853 32.5762 11.3923 33.7844Z"
            fill={color}
            style={pathStyle}
          />
        </g>
        <defs>
          <clipPath id="clip0_306_67">
            <rect width="166" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
