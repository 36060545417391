import {Palette} from 'hubbl-shared';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {useNavigate} from 'react-router-dom';
import styled, {keyframes} from 'styled-components';
import {AssetIconClose} from '../../assets';

// Backdrop styles
const Backdrop = styled.div<{isVisible: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  z-index: 998;
  pointer-events: 'auto';
`;

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const SheetContainer = styled.div<{isClosing: boolean}>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 80%; /* Limit the height of the bottom sheet */
  background: ${() => Palette.white};
  border-radius: 24px 24px 0 0;
  z-index: 999;
  box-shadow: 0px 2.858px 17.148px 0px rgba(0, 0, 0, 0.08);
  animation: ${props => (props.isClosing ? slideOut : slideIn)} 0.5s
    cubic-bezier(0.33, 1, 0.68, 1);

  display: flex;
  flex-direction: column; /* Ensures content flows vertically */
`;

const ContentWrapper = styled.div`
  padding: 16px;
  overflow-y: auto; /* Enable scrolling when content exceeds max-height */
  max-height: calc(
    80vh - 32px
  ); /* Ensure content respects the max height of the sheet */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  color: ${Palette.almostBlack};
  cursor: pointer;
  padding: 0;
  transition: color 0.3s;
`;

interface BottomSheetProps {
  children: React.ReactNode;
}

export interface BottomSheetRef {
  close: () => void;
}

export const BottomSheet = forwardRef<BottomSheetRef, BottomSheetProps>(
  ({children}, ref) => {
    const navigate = useNavigate();
    const [isClosing, setIsClosing] = useState(false);
    const [isVisible, setIsVisible] = useState(false); // New state for visibility

    const handleClose = useCallback(() => {
      if (isClosing) return;

      setIsClosing(true);
      setTimeout(() => {
        navigate(-1); // Navigate back after the animation completes
      }, 500); // Match the animation duration
    }, [navigate, isClosing]);

    // Expose `handleClose` method to parent component through the ref
    useImperativeHandle(
      ref,
      () => ({
        close() {
          handleClose();
        },
      }),
      [handleClose],
    );

    // Set `isVisible` to true after the component mounts
    useEffect(() => {
      setIsVisible(true);
    }, []);

    // Handle Esc key to close the sheet
    useEffect(() => {
      const handleEsc = (event: KeyboardEvent) => {
        if (event.key === 'Escape') handleClose();
      };
      window.addEventListener('keydown', handleEsc);
      return () => window.removeEventListener('keydown', handleEsc);
    }, [handleClose]);

    return (
      <>
        <Backdrop isVisible={isVisible && !isClosing} onClick={handleClose} />

        <SheetContainer isClosing={isClosing}>
          <ContentWrapper>
            {/* Close button in the top-right corner */}

            {children}
            <CloseButton onClick={handleClose}>
              <AssetIconClose />
            </CloseButton>
          </ContentWrapper>
        </SheetContainer>
      </>
    );
  },
);
