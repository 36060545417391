import {NotificationReminder, Palette} from 'hubbl-shared';
import moment from 'moment';
import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {Droppable, DroppableProps} from 'react-beautiful-dnd'; // For reordering
import {useForm} from 'react-hook-form';
import {Outlet, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {AssetIconAdd, AssetIconTrash} from '../../../../assets';
import {
  ImageSelectorInput,
  ManagedDateTimePicker,
  ManagedEmojiSelectorPopover,
  ManagedInput,
  PaddingBottom,
  PrimaryButton,
  Scene,
  Squircle,
  TextHeading3,
  TextHeading7,
  Toast,
  ToastRef,
} from '../../../../components';
import {IconButton} from '../../../../components/buttons/icon-button/IconButton';
import {useAppDispatch} from '../../../../hooks';
import {CreateEventFormType, EventFields} from '../../../../models';
import {AdminWebRoute} from '../../../../routes/AdminWebRoute';
import {RootRoute} from '../../../../routes/RootRoute';
import {EventActions} from '../../../../store/events/EventActions';
import {GlobalActions} from '../../../../store/global/GlobalActions';
import {UserActions} from '../../../../store/user/UserActions';
import {QuestionsList} from './components/questions-list/QuestionsList';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {FontConfig} from '../../../../constants/FontConfig';

const CONTENT_WIDTH = 600;
const CONTENT_PADDING = 24;
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

export const StrictModeDroppable = ({children, ...props}: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${CONTENT_WIDTH}px;
  padding: ${CONTENT_PADDING}px;
  border-radius: 24px;
  box-shadow:
    hsla(220, 30%, 5%, 0.1) 0px 5px 15px 0px,
    hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px;

  /* Center the content inside */
  align-items: flex-start;
  justify-content: center;
  position: relative;
  /* Make the container as tall as its content */
  max-height: 90%; /* Avoid taking the full height */
  overflow: hidden;
  box-sizing: border-box;
`;

const StyledQuill = styled(ReactQuill)`
  .ql-container {
    min-height: 200px;
    border-radius: 8px;
    background: ${Palette.primarySubtleGreen};
    width: 100%;
    color: ${Palette.almostBlack};
    border-width: 0px;
    font-family: ${FontConfig.BodySmall.fontFamily};
    font-size: ${FontConfig.BodySmall.fontSize}px;
    line-height: ${FontConfig.BodySmall.lineHeight};
  }
  .ql-toolbar {
    border-radius: 8px 8px 8px 8px;
    border-width: 0px;
    background: ${Palette.primaryCherokee};
  }
  .ql-editor {
    border-width: 0px;
    min-height: 150px;
  }
  .ql-snow .ql-picker {
    color: ${Palette.almostBlack};
  }
  .ql-snow .ql-picker-label {
    color: ${Palette.almostBlack};
  }
  .ql-editor h1 {
    font-family: ${FontConfig.Heading3.fontFamily};
    font-size: ${FontConfig.Heading3.fontSize}px;
    line-height: ${FontConfig.Heading3.lineHeight};
    font-weight: ${FontConfig.Heading3.fontWeight};
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .ql-editor h2 {
    font-family: ${FontConfig.Heading4.fontFamily};
    font-size: ${FontConfig.Heading4.fontSize}px;
    line-height: ${FontConfig.Heading4.lineHeight};
    font-weight: ${FontConfig.Heading4.fontWeight};
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .ql-editor h3 {
    font-family: ${FontConfig.Heading5.fontFamily};
    font-size: ${FontConfig.Heading5.fontSize}px;
    line-height: ${FontConfig.Heading5.lineHeight};
    font-weight: ${FontConfig.Heading5.fontWeight};
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .ql-editor p {
    margin: 0;
    padding: 0;
  }
  .ql-editor ul,
  .ql-editor ol {
    margin-left: 0px;
  }
  .ql-editor li {
    margin: 2px 0;
  }
`;

export const SceneCreateEvent = memo(() => {
  const {control, handleSubmit, setValue, watch} = useForm();
  const toastRef = useRef<ToastRef>(null);
  const dispatch = useAppDispatch();

  const startDateTime = watch(EventFields.START_DATE_TIME);

  const deadline = watch(EventFields.DEADLINE);

  useEffect(() => {
    setValue(EventFields.REMINDERS, []);
    if (startDateTime) {
      // Parse the start date and time to a Date object for easier calculations
      const startDate = new Date(startDateTime);

      // Set the reminder for 1 week before the start date
      const weekBeforeNotificationReminder: NotificationReminder = {
        id: 'weekBeforeNotificationReminder',
        timestamp: new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16), // 7 days before the start
      };

      // Set the reminder for 1 day before the start date
      const dayBeforeNotificationReminder: NotificationReminder = {
        id: 'dayBeforeNotificationReminder',
        timestamp: new Date(startDate.getTime() - 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16), // 1 day before the start
      };

      // Set these reminders in the form value
      setValue(EventFields.REMINDERS, [
        weekBeforeNotificationReminder,
        dayBeforeNotificationReminder,
      ]);
    }

    if (deadline && new Date(deadline) < new Date(startDateTime)) {
      // Adjust reminders if the deadline is earlier than the start time
      const deadlineDate = new Date(deadline);

      // Adjust notification reminders based on the new deadline
      const adjustedDayBeforeNotificationReminder: NotificationReminder = {
        id: 'dayBeforeNotificationReminderAdjusted',
        timestamp: new Date(deadlineDate.getTime() - 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16),
      };

      // You can adjust the logic here if you need to also account for a specific week reminder based on the deadline
      const adjustedWeekBeforeNotificationReminder: NotificationReminder = {
        id: 'weekBeforeNotificationReminderAdjusted',
        timestamp: new Date(deadlineDate.getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16), // 1 week before the deadline
      };

      // Set adjusted reminders based on the deadline
      setValue(EventFields.REMINDERS, [
        adjustedWeekBeforeNotificationReminder,
        adjustedDayBeforeNotificationReminder,
      ]);
    }
  }, [startDateTime, deadline, setValue]);

  const startDateMin = moment().format(DATE_FORMAT); // Start date cannot be in the past

  useEffect(() => {
    setValue(
      EventFields.END_DATE_TIME,
      moment(startDateTime).add(2, 'hours').format(DATE_FORMAT),
    );
  }, [setValue, startDateTime]);

  const endDateMin = startDateTime
    ? moment(startDateTime).add(1, 'minute').format(DATE_FORMAT)
    : startDateMin;

  const deadlineMax = startDateTime
    ? moment(startDateTime).subtract(1, 'minute').format(DATE_FORMAT)
    : moment().format(DATE_FORMAT);
  const publishDateMax = deadlineMax
    ? moment(deadlineMax).subtract(1, 'minute').format(DATE_FORMAT)
    : startDateTime
      ? moment(startDateTime).subtract(1, 'minute').format(DATE_FORMAT)
      : moment().format(DATE_FORMAT);

  const reminders: NotificationReminder[] = watch(EventFields.REMINDERS) || [];

  // Add Reminder
  const addReminder = useCallback(() => {
    if (!startDateTime) return;
    const newReminder = {
      id: new Date().getTime(),
      timestamp: moment(new Date()).format(DATE_FORMAT),
    };
    setValue(EventFields.REMINDERS, [...reminders, newReminder]);
  }, [reminders, setValue, startDateTime]);

  // Remove Reminder
  const removeReminder = useCallback(
    (id: string) => {
      setValue(
        EventFields.REMINDERS,
        reminders.filter(r => r.id !== id),
      );
    },
    [reminders, setValue],
  );

  useEffect(() => {
    dispatch(UserActions.resetEventQuestions());
  }, [dispatch]);
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (data: CreateEventFormType) => {
      // Convert all date fields to include the correct offset
      const formattedData: CreateEventFormType = {
        ...data,
        [EventFields.START_DATE_TIME]: moment(
          data[EventFields.START_DATE_TIME],
        ).format('YYYY-MM-DDTHH:mm:ssZ'),
        [EventFields.END_DATE_TIME]: data[EventFields.END_DATE_TIME]
          ? moment(data[EventFields.END_DATE_TIME]).format(
              'YYYY-MM-DDTHH:mm:ssZ',
            )
          : null,
        [EventFields.MARKETING_DATE_TIME]: moment(
          data[EventFields.MARKETING_DATE_TIME],
        ).format('YYYY-MM-DDTHH:mm:ssZ'),
        [EventFields.DEADLINE]: data[EventFields.DEADLINE]
          ? moment(data[EventFields.DEADLINE]).format('YYYY-MM-DDTHH:mm:ssZ')
          : null,
        [EventFields.REMINDERS]: data[EventFields.REMINDERS]?.map(reminder => ({
          ...reminder,
          timestamp: moment(reminder.timestamp).format('YYYY-MM-DDTHH:mm:ssZ'),
        })),
      };
      try {
        dispatch(GlobalActions.setIsBusy(true));

        const newEvent = await dispatch(UserActions.createEvent(formattedData))
          .unwrap()
          .catch(error => {
            throw Error(error);
          });
        dispatch(UserActions.resetEventQuestions());
        dispatch(EventActions.addEvent(newEvent));

        navigate(`${RootRoute.AuthAdminHome}${AdminWebRoute.EventsOverview}`);
      } catch (error) {
        toastRef.current?.showToast();
      } finally {
        dispatch(GlobalActions.setIsBusy(false));
      }
    },
    [dispatch, navigate],
  );

  const onFileChange = useCallback(
    (file: File) => {
      setValue(EventFields.COVER_IMAGE, file);
    },
    [setValue],
  );

  const onNavAddQuestions = useCallback(
    () => navigate(AdminWebRoute.CreateEventAddEditQuestions),
    [navigate],
  );

  return (
    <Scene>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Squircle width={CONTENT_WIDTH}>
          <StyledContainer>
            <TextHeading3 text={`Create Hubbl Event`} />
            <PaddingBottom amount={16} />

            {/* Event Title */}
            <TextHeading7 text={'Event Title *'} />
            <PaddingBottom amount={8} />
            <ManagedInput
              useAlternativeTheme
              isCompact
              control={control}
              placeholder="Enter event title"
              controlKey={EventFields.EVENT_TITLE}
              isRequiredMessage={'Event title cannot be blank'}
            />
            <PaddingBottom amount={16} />

            {/* Event Description */}
            <TextHeading7 text={'Event Description *'} />
            <PaddingBottom amount={8} />
            <StyledQuill
              style={{width: '100%'}}
              value={watch(EventFields.EVENT_DESCRIPTION) || ''}
              onChange={(content: string) =>
                setValue(EventFields.EVENT_DESCRIPTION, content)
              }
              modules={{
                toolbar: [
                  [{header: [2, 3, false]}],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{list: 'ordered'}, {list: 'bullet'}],
                  ['link'],
                  ['clean'],
                ],
              }}
            />
            <PaddingBottom amount={16} />

            {/* Event Emoji */}
            <TextHeading7 text={'Event Emoji *'} />
            <PaddingBottom amount={8} />
            <ManagedEmojiSelectorPopover
              control={control}
              controlKey={EventFields.EVENT_EMOJI}
              isRequiredMessage={'You have to select an emoji'}
            />

            <PaddingBottom amount={16} />

            {/* Event Location */}
            <TextHeading7 text={'Event Location *'} />
            <PaddingBottom amount={8} />
            <ManagedInput
              isCompact
              useAlternativeTheme
              control={control}
              placeholder="Enter event location"
              controlKey={EventFields.EVENT_LOCATION}
              isRequiredMessage={'Event location cannot be blank'}
            />
            <PaddingBottom amount={16} />

            {/* Start Date Time */}
            <TextHeading7 text={'Start Date & Time *'} />
            <PaddingBottom amount={8} />
            <ManagedDateTimePicker
              control={control}
              controlKey={EventFields.START_DATE_TIME}
              minDate={startDateMin}
              isRequiredMessage={'Start date and time cannot be blank'}
            />
            <PaddingBottom amount={16} />
            {/* End Date Time */}
            <TextHeading7 text={'End Date & Time'} />
            <PaddingBottom amount={8} />
            <ManagedDateTimePicker
              control={control}
              minDate={endDateMin}
              controlKey={EventFields.END_DATE_TIME}
            />
            <PaddingBottom amount={16} />

            {/* Marketing Date Time */}
            <TextHeading7 text={'Publish Date & Time *'} />
            <PaddingBottom amount={8} />
            <ManagedDateTimePicker
              control={control}
              minDate={moment(startDateMin)
                .add(5, 'minutes')
                .format(DATE_FORMAT)}
              maxDate={publishDateMax} // Publish date must be before start date
              controlKey={EventFields.MARKETING_DATE_TIME}
              isRequiredMessage={'Publish date and time cannot be blank'}
            />
            <PaddingBottom amount={16} />

            {/* Deadline */}
            <TextHeading7 text={'Want to add a deadline for when to reply?'} />
            <PaddingBottom amount={8} />
            <ManagedDateTimePicker
              control={control}
              maxDate={deadlineMax}
              controlKey={EventFields.DEADLINE}
            />
            <PaddingBottom amount={16} />
            <TextHeading7
              text={'Event Reminders (defaults to week and day before)'}
            />
            <PaddingBottom amount={8} />

            {reminders.map((reminder, index) => (
              <div
                key={reminder.id}
                style={{display: 'flex', alignItems: 'center', gap: '24px'}}>
                <ManagedDateTimePicker
                  control={control}
                  minDate={moment().format(DATE_FORMAT)}
                  maxDate={startDateTime}
                  controlKey={`reminders.${index}.timestamp`}
                  isRequiredMessage={'Reminder time is required'}
                  //maxDateTime={startDateTime} // Ensures reminder is before event start
                />
                <IconButton
                  hoverColor={Palette.systemError}
                  icon={<AssetIconTrash size={16} />}
                  onClick={() => removeReminder(reminder.id)}
                />
              </div>
            ))}
            <PaddingBottom amount={8}></PaddingBottom>

            <IconButton
              text="Add Reminder"
              backgroundColor={Palette.primaryCherokee}
              hoverColor={Palette.primaryTerraCotta}
              icon={<AssetIconAdd />}
              onClick={addReminder}
            />
            <PaddingBottom amount={16} />
            <TextHeading7 text={'Cover Image *'} />
            <PaddingBottom amount={8} />
            <ImageSelectorInput
              isRequiredMessage="Have to upload a cover image"
              control={control}
              controlKey={EventFields.COVER_IMAGE}
              onFileChange={onFileChange} // Sets the uploaded image URL
            />
            <PaddingBottom amount={16} />
            {/* Questions Section */}
            <TextHeading7 text={'Questions to ask before attendance'} />
            <PaddingBottom amount={8} />
            <IconButton
              backgroundColor={Palette.primaryCherokee}
              hoverColor={Palette.primaryTerraCotta}
              icon={<AssetIconAdd />}
              iconPadding={8}
              text="Add questions"
              onClick={onNavAddQuestions}
            />
            <PaddingBottom amount={16} />
            <QuestionsList />

            {/* Submit Button */}
            <div
              style={{
                alignSelf: 'center',
              }}>
              <PrimaryButton
                label="Create Event"
                customWidth={CONTENT_WIDTH - CONTENT_PADDING * 2}
                isCompact
                //@ts-ignore
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </StyledContainer>
        </Squircle>
      </div>
      <Toast ref={toastRef} isError message={'Error creating event!'} />
      <Outlet />
      <PaddingBottom amount={54} />
    </Scene>
  );
});
