import {useMemo} from 'react';
import styled, {keyframes} from 'styled-components';
import {useStorageImageUrl} from '../../../../../../hooks/api/useStorageImageUrl';
import {UserDTO} from '../../../../../../models/user/UserDTO';

const floatLeft = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  33% {
    transform: translate(-40px, -20px) rotate(120deg);
  }
  66% {
    transform: translate(-20px, 30px) rotate(240deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
`;

const floatRight = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  33% {
    transform: translate(40px, -30px) rotate(-120deg);
  }
  66% {
    transform: translate(20px, 20px) rotate(-240deg);
  }
  100% {
    transform: translate(0, 0) rotate(-360deg);
  }
`;

interface FloatingAvatarProps {
  delay: number;
  duration: number;
  top: number;
  left: number;
  isLeftSide: boolean;
}

const FloatingAvatar = styled.div<FloatingAvatarProps>`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0.4;
  animation: ${props => (props.isLeftSide ? floatLeft : floatRight)}
    ${props => props.duration}s infinite ease-in-out;
  animation-delay: ${props => props.delay}s;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  pointer-events: none;
  filter: grayscale(30%);
`;

interface SingleAvatarProps {
  attendee: UserDTO;
  delay: number;
  duration: number;
  top: number;
  left: number;
  isLeftSide: boolean;
}

const SingleAvatar = ({
  attendee,
  delay,
  duration,
  top,
  left,
  isLeftSide,
}: SingleAvatarProps) => {
  const photoUrl = useStorageImageUrl(attendee.photoUrl);

  return (
    <FloatingAvatar
      delay={delay}
      duration={duration}
      top={top}
      left={left}
      isLeftSide={isLeftSide}>
      <img
        src={photoUrl}
        alt={attendee.fullName}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </FloatingAvatar>
  );
};

interface Props {
  attendees: UserDTO[];
}

export const FloatingAvatars = ({attendees}: Props) => {
  const floatingAvatars = useMemo(() => {
    // Create avatars for both sides
    return attendees.map(attendee => {
      // Determine if avatar should be on left or right side
      const isLeftSide = Math.random() > 0.5;

      // Position avatars on the edges
      const left = isLeftSide
        ? Math.random() * 15 // Left side: 0-15%
        : 85 + Math.random() * 15; // Right side: 85-100%

      return {
        attendee: attendee,
        delay: 0, // Random delay between 0-8s
        duration: 20 + Math.random() * 15, // Random duration between 20-35s
        top: Math.random() * 100, // Random position between 0-100%
        left,
        isLeftSide,
      };
    });
  }, [attendees]);

  return (
    <>
      {floatingAvatars.map((avatar, index) => (
        <SingleAvatar
          key={`${avatar.attendee.id}-${index}`}
          attendee={avatar.attendee}
          delay={avatar.delay}
          duration={avatar.duration}
          top={avatar.top}
          left={avatar.left}
          isLeftSide={avatar.isLeftSide}
        />
      ))}
    </>
  );
};
