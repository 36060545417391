import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';
import {
  Company,
  CreateCompanyFromGetStartedWebDataProps,
  FirestoreCollection,
  HRIS,
  User,
} from 'hubbl-shared';
import {CompanyDTO, UserDTO} from '../models';
import {firebaseFunctions, firestore} from './firebase';
import {mapCompanyDTOToCompany} from './utils/map-incoming-company/mapCompanyDTOToCompany';
import {mapUserDTOToUser} from './utils/map-incoming-users/mapUserDTOToUser';

const getLoggedInUserCompany = async (
  companyId: string,
): Promise<Company | null> => {
  const companyPromise = new Promise<Company | null>(
    async (resolve, reject) => {
      try {
        const companyRef = doc(
          firestore,
          FirestoreCollection.Companies,
          companyId,
        );
        const companySnapshot = await getDoc(companyRef);

        if (companySnapshot.exists()) {
          //TODO: PARSE CREATED AT LIKE IN APP
          resolve(mapCompanyDTOToCompany(companySnapshot.data() as CompanyDTO));
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    },
  );
  return companyPromise;
};

export const getCompanyEmployees = async (
  companyId: string,
  limitCount: number,
  lastVisible: DocumentSnapshot<User> | null,
): Promise<{
  employees: User[];
  lastVisibleSnapshot: DocumentSnapshot<User> | null;
}> => {
  try {
    const companyRef = doc(firestore, FirestoreCollection.Companies, companyId);
    const usersRef = collection(companyRef, FirestoreCollection.Users);

    // Start the query after the last visible document if it's provided (for pagination)
    const usersQuery = lastVisible
      ? query(
          usersRef,
          orderBy('fullName', 'asc'),
          limit(limitCount),
          startAfter(lastVisible),
        )
      : query(usersRef, orderBy('fullName', 'asc'), limit(limitCount));

    const querySnapshot = await getDocs(usersQuery);

    const employees: User[] = querySnapshot.docs.map(doc => {
      const data = doc.data() as UserDTO;
      return mapUserDTOToUser(data);
    });

    // Get the last visible document to continue pagination
    const lastVisibleSnapshot =
      querySnapshot.docs[querySnapshot.docs.length - 1] || null;

    return {
      employees,
      lastVisibleSnapshot: lastVisibleSnapshot
        ? (lastVisibleSnapshot as DocumentSnapshot<User>)
        : null,
    };
  } catch (error) {
    console.error('Error fetching events:', error);
    throw new Error('Error fetching events');
  }
};

const addHRIStoCompany = async (companyId: string, hris: HRIS) => {
  const companyPromise = new Promise<void>(async (resolve, reject) => {
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );

      await updateDoc(companyRef, {'settings.connectedHRIS': hris});

      resolve();
    } catch (error) {
      reject(error);
    }
  });
  return companyPromise;
};

const createCompany = async (
  dataProps: CreateCompanyFromGetStartedWebDataProps,
) => {
  const callableFunc = httpsCallable(
    firebaseFunctions,
    'onCompanyCreatedFromGetStartedWeb',
  );
  return callableFunc(dataProps)
    .then(result => {
      console.log(result.data);
      return result.data as {pincode: string};
    })
    .catch(error => {
      console.log(`error: ${JSON.stringify(error)}`);
      throw error;
    });
};

const getCompanyByDomain = async (
  userEmail: string,
): Promise<Company | undefined> => {
  const emailDomain = userEmail.split('@')[1];

  return new Promise<Company | undefined>(async (resolve, _) => {
    const companiesRef = collection(firestore, FirestoreCollection.Companies);
    const q = query(
      companiesRef,
      where('settings.restrictedMailDomains', 'array-contains', emailDomain),
    );

    const querySnapshot = await getDocs(q);

    const companies: Company[] = querySnapshot.docs.map(doc =>
      mapCompanyDTOToCompany(doc.data() as CompanyDTO),
    );

    if (companies.length === 1) {
      resolve(companies[0]);
    } else {
      resolve(undefined);
    }
  });
};

export const company = {
  getCompanyEmployees,
  addHRIStoCompany,
  getLoggedInUserCompany,
  createCompany,
  getCompanyByDomain,
};
