import imageCompression from 'browser-image-compression';
import {Palette} from 'hubbl-shared';
import {memo, useState} from 'react';
import {Control, Controller} from 'react-hook-form';
import styled from 'styled-components';
import {FontConfig} from '../../constants/FontConfig';
import {FieldError} from './FieldError';

interface ImageSelectorInputProps {
  control: Control;
  controlKey: string;
  isRequiredMessage?: string;
  onFileChange: (file: File) => void;
}

export const ImageSelectorInput = memo(
  ({
    control,
    controlKey,
    isRequiredMessage,
    onFileChange,
  }: ImageSelectorInputProps) => {
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    //const [uploading, setUploading] = useState<boolean>(false);

    const handleFileChange = async (file: File) => {
      try {
        setError(null);

        // Validate file type
        if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
          throw new Error('Only JPG, JPEG, or PNG files are allowed.');
        }

        // Validate file size (e.g., max 5MB)
        if (file.size > 5 * 1024 * 1024) {
          throw new Error('File size exceeds 5MB.');
        }

        // Compress the image
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.5, // Compress to 1MB
          maxWidthOrHeight: 2000, // Resize for large images
          useWebWorker: true,
        });

        // Generate preview
        const objectUrl = URL.createObjectURL(file);
        setPreviewUrl(objectUrl);

        onFileChange(compressedFile); // Pass the URL back to parent form logic
      } catch (err: any) {
        setError(err.message || 'Failed to upload image.');
      }
    };

    return (
      <Controller
        control={control}
        name={controlKey}
        rules={{required: isRequiredMessage}}
        render={({fieldState: {error: fieldError}}) => (
          <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <FileInput
              type="file"
              accept="image/jpeg, image/png, image/jpg"
              onChange={e => {
                if (e.target.files?.[0]) {
                  handleFileChange(e.target.files[0]);
                }
              }}
            />
            {previewUrl && (
              <PreviewImage src={previewUrl} alt="Cover preview" />
            )}
            {error ||
              (fieldError?.message && (
                <FieldError
                  errorMessage={error || fieldError.message}
                  hasError={Boolean(error || fieldError)}
                />
              ))}
          </div>
        )}
      />
    );
  },
);
const InputFontConfig = FontConfig.Heading3;

const FileInput = styled.input`
  font-weight: ${() => InputFontConfig.fontWeight};
  font-family: ${() => InputFontConfig.fontFamily};
  font-size: 12px;
  padding: 10px; /* Adjust the padding to make the input larger */
  border-radius: 8px; /* Round the corners */
  border: 1px solid ${() => Palette.primaryBianca};
  color: ${() => Palette.almostBlack}; /* Text color inside the input */
  background-color: ${() => Palette.primarySubtleGreen}; /* Light background */
  cursor: pointer; /* Show pointer cursor */

  &:hover {
    border-color: #888; /* Change border color on hover */
  }

  &::file-selector-button {
    font-weight: ${() => InputFontConfig.fontWeight};
    font-family: ${() => InputFontConfig.fontFamily};
    font-size: 12px;
    padding: 10px 20px; /* Padding for the button */
    margin-right: 16px;
    background-color: ${() => Palette.primaryCherokee};
    color: ${() => Palette.almostBlack}; /* Text color of the button */
    border: none; /* Remove border */
    border-radius: 4px; /* Round the button corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: ease background-color 250ms;
    &:hover {
      background-color: ${() => Palette.primaryTerraCotta};
    }
  }

  &:focus {
    border-color: ${() => Palette.white};
    outline: none; /* Remove outline on focus */
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  max-height: 200px;
  border-radius: 8px;
  object-fit: contain;
`;
