import {NotificationReminder} from 'hubbl-shared';

export enum EventFields {
  EVENT_TITLE = 'eventTitle',
  EVENT_DESCRIPTION = 'eventDescription',
  EVENT_EMOJI = 'eventEmoji',
  EVENT_LOCATION = 'eventLocation',
  START_DATE_TIME = 'startDateTime',
  END_DATE_TIME = 'endDateTime',
  MARKETING_DATE_TIME = 'marketingStartDate',
  DEADLINE = 'deadline',
  COVER_IMAGE = 'coverImage',
  REMINDERS = 'reminders',
  MAX_ATTENDEES = 'maxAttendees',
}

export type CreateEventFormType = {
  [EventFields.EVENT_TITLE]: string;
  [EventFields.EVENT_DESCRIPTION]: string;
  [EventFields.EVENT_EMOJI]: string;
  [EventFields.EVENT_LOCATION]: string;
  [EventFields.START_DATE_TIME]: string;
  [EventFields.END_DATE_TIME]: string | null;
  [EventFields.DEADLINE]: string | null;
  [EventFields.MARKETING_DATE_TIME]: string;
  [EventFields.COVER_IMAGE]: File;
  [EventFields.REMINDERS]: NotificationReminder[];
  [EventFields.MAX_ATTENDEES]: number | null;
};
