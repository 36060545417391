import {Palette} from 'hubbl-shared';
import {ForwardedRef, forwardRef} from 'react';
import {Control, Controller} from 'react-hook-form';
import styled from 'styled-components';
import {FontConfig} from '../../constants/FontConfig';
import {FieldError} from './FieldError';

interface ManagedDateTimePickerProps {
  control: Control;
  controlKey: string;
  isRequiredMessage?: string;
  placeholder?: string;
  minDate?: string; // Minimum selectable date
  maxDate?: string; // Maximum selectable date
}
const InputFontConfig = FontConfig.Heading3;

const StyledDateTimeInput = styled.input<{hasError: boolean}>`
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: ${() => InputFontConfig.fontWeight};
  font-family: ${() => InputFontConfig.fontFamily};
  font-size: 14px;
  border-width: 1px;
  color: ${() => Palette.almostBlack};
  border: 1px solid
    ${({hasError}) => (hasError ? Palette.systemError : Palette.white)};
  background-color: ${Palette.primarySubtleGreen};
  border-radius: 8px;
  outline: none;

  &[type='datetime-local'] {
    appearance: none; /* Remove browser-specific styles */
  }
`;

export const ManagedDateTimePicker = forwardRef<
  HTMLInputElement,
  ManagedDateTimePickerProps
>(
  (
    {
      control,
      controlKey,
      isRequiredMessage,
      placeholder,
      maxDate,
      minDate,
    }: ManagedDateTimePickerProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Controller
        control={control}
        rules={{required: isRequiredMessage}}
        render={({fieldState: {error}, field: {onBlur, onChange, value}}) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              outline: 'none',
            }}>
            <StyledDateTimeInput
              ref={ref}
              type="datetime-local"
              value={value || ''}
              hasError={Boolean(error)}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              min={minDate}
              max={maxDate}
            />
            {error?.message && (
              <FieldError
                errorMessage={error.message}
                hasError={Boolean(error)}
              />
            )}
          </div>
        )}
        name={controlKey}
      />
    );
  },
);
