import ConfettiExplosion from 'react-confetti-explosion';
import {useEffect, useState} from 'react';

interface Props {
  isActive: boolean;
  onComplete?: () => void;
  extraColors: string[];
}

export const Confetti = ({isActive, onComplete, extraColors}: Props) => {
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    if (isActive) {
      setIsExploding(true);
      const timer = setTimeout(() => {
        setIsExploding(false);
        onComplete?.();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isActive, onComplete]);

  if (!isExploding) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
      <ConfettiExplosion
        force={0.8}
        zIndex={0}
        duration={3000}
        particleCount={250}
        width={1600}
        colors={[
          '#FFD700',
          '#FFA500',
          '#FF69B4',
          '#87CEEB',
          '#98FB98',
          ...extraColors,
        ]}
      />
    </div>
  );
};
