import {Palette} from 'hubbl-shared';
import {memo} from 'react';
import styled, {css} from 'styled-components';
import {TextHeading5} from '../../text/TextHeading5';

// Define styles for the Button with dynamic behavior for compact mode
const Button = styled.button<{isCompact?: boolean; customWidth?: number}>`
  background-color: ${Palette.transparent};
  color: ${Palette.almostBlack};
  border: 2px solid ${Palette.almostBlack};
  height: ${({isCompact}) => (isCompact ? '36px' : '48px')};
  width: ${({isCompact, customWidth}) =>
    customWidth ? `${customWidth}px` : isCompact ? '140px' : '180px'};
  border-radius: ${({isCompact}) => (isCompact ? '16px' : '20px')};
  outline: 0;
  cursor: pointer;
  transition:
    ease background-color 250ms,
    transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  ${({isCompact}) =>
    isCompact &&
    css`
      font-size: 14px;
      padding: 0 10px;
    `}
`;

interface SecondaryButtonProps {
  onClick: () => void;
  label: string;
  isCompact?: boolean; // To toggle compact mode
  customWidth?: number; // To specify a custom width
}

export const SecondaryButton = memo(
  ({label, onClick, isCompact = false, customWidth}: SecondaryButtonProps) => {
    return (
      <Button onClick={onClick} isCompact={isCompact} customWidth={customWidth}>
        <TextHeading5
          text={label}
          color={isCompact ? Palette.almostBlack : undefined}
          style={{
            fontSize: isCompact ? '12px' : 'inherit', // Adjust font size in compact mode
          }}
        />
      </Button>
    );
  },
);
