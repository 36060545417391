import {SortDirection} from '@tanstack/react-table';
import {memo} from 'react';
import styled from 'styled-components';
import {AssetIconChevron} from '../../assets';
import {TextHeading5} from '../text/TextHeading5';
import {Palette} from 'hubbl-shared';

export const TableHeader = styled.th`
  background-color: ${() => Palette.white};
  padding: 12px 15px;
  text-align: left;
  cursor: pointer;
`;

interface TableHeaderContentProps {
  label: string;
  isSorted?: false | SortDirection;
}

export const TableHeaderContent = memo(
  ({label, isSorted}: TableHeaderContentProps) => {
    return (
      <Container>
        <TextHeading5 text={label} />
        {label !== 'Actions' && isSorted !== false && (
          <SortButton className={isSorted === 'desc' ? 'desc' : 'asc'}>
            <AssetIconChevron />
          </SortButton>
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

// Styled Button for the Table Header Sorting
const SortButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  margin-left: 0px;

  svg {
    transform: rotate(90deg); /* Rotate to indicate default sort (ASC) */
    transition: transform 0.3s ease;
  }

  &.desc svg {
    transform: rotate(-90deg); /* Rotate for descending order */
  }

  &:hover {
    opacity: 0.7;
  }
`;
