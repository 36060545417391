import {memo} from 'react';
import {PaddingBottom} from '../padding/PaddingBottom';
import {TextHeading7} from '../text/TextHeading7';
import {CheckMark} from './CheckMark';

interface CheckMarkWithLabelProps {
  isChecked: boolean;
  onClick: () => void;
  label: string;
}

export const CheckMarkWithLabel = memo(
  ({isChecked, onClick, label}: CheckMarkWithLabelProps) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
          alignItems: 'center',
        }}>
        <CheckMark isChecked={isChecked} onClick={onClick} />
        <div>
          <PaddingBottom amount={3} />
          <TextHeading7 text={label} />
        </div>
      </div>
    );
  },
);
