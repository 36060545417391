import {memo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {AssetIconChevron} from '../../assets';
import {AdminWebRoute} from '../../routes/AdminWebRoute';
import {IconButton} from '../buttons/icon-button/IconButton';
import {TextHeading6} from '../text/TextHeading6';
import {Palette} from 'hubbl-shared';
import {AvatarStorageRef} from '../avatar/AvatarStorageRef';

export const TableCell = styled.td`
  padding: 12px 15px;
  border-bottom: 0.5px solid ${() => Palette.almostBlack250};
  align-items: center;
  justify-content: center;
  word-break: break-word; /* Break long words if needed */
  overflow-wrap: break-word; /* Alternative for older browsers */
  white-space: normal; /* Allows multiple lines */
`;

interface TableCellContentProps {
  value: unknown;
  columnId: string;
}

export const TableCellContent = memo(
  ({value, columnId}: TableCellContentProps) => {
    const navigate = useNavigate();
    const onNavEvent = useCallback(() => {
      navigate(`${AdminWebRoute.EventDetails}/${value}`);
    }, [navigate, value]);

    if (columnId === 'id') {
      return <IconButton icon={<AssetIconChevron />} onClick={onNavEvent} />;
    }

    if (columnId === 'photoUrl' && typeof value === 'string') {
      return <AvatarStorageRef imageRef={value} />;
    }

    if (!value) {
      return (
        <TextHeading6 color={Palette.almostBlackTransparent40} text={'-'} />
      );
    }

    // Handle different types of values (strings, dates, etc.)
    if (value instanceof Date) {
      return <TextHeading6 text={value.toDateString()} />;
    }

    if (typeof value === 'string') {
      return <TextHeading6 text={value.toString()} />;
    }

    // Default rendering for strings, numbers, etc.
    return <span>{value as React.ReactNode}</span>;
  },
);
