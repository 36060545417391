import EmojiPicker, {EmojiClickData, SuggestionMode} from 'emoji-picker-react';
import {Palette} from 'hubbl-shared';
import {memo, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {AssetIconChevron} from '../../assets';
import {PaddingRight} from '../padding/PaddingRight';
import {TextHeading2} from '../text/TextHeading2';
import {TextHeading7} from '../text/TextHeading7';
import {TextHeading8} from '../text/TextHeading8';

const PopoverContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PopoverButton = styled.button`
  background-color: ${() => Palette.primaryCherokee};
  display: flex;
  align-items: center;
  border: none;
  border-radius: 12px;
  padding: 8px 16px;
  cursor: pointer;

  transition: ease background-color 250ms;
  &:hover {
    background-color: ${() => Palette.primaryTerraCotta};
  }
`;

const PopoverContent = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: ${() => Palette.primarySubtleGreen};
  border-radius: 16px;
  -webkit-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  z-index: 1000;

  /* Add specific CSS variables for the EmojiPicker here */
  .EmojiPickerReact {
    --epr-hover-bg-color: ${() =>
      Palette.primaryCherokee}; /* Example: Set the hover color */
    --epr-bg-color: ${() => Palette.white}; /* Example: Set background color */
    --epr-category-label-bg-color: ${() =>
      Palette.white}; /* Set background for category labels */
    --epr-text-color: ${() => Palette.almostBlack}; /* Set text color */
    --epr-highlight-color: ${() => Palette.primaryCherokee};
    --epr-search-input-bg-color: ${() => Palette.primarySubtleGreen};
    --epr-search-input-bg-color-active: ${() => Palette.primarySubtleGreen};
  }
`;

interface EmojiSelectorPopoverProps {
  onChangeEmoji: (emoji: string) => void;
  selectedEmoji: string;
}

export const EmojiSelectorPopover = memo(
  ({onChangeEmoji, selectedEmoji}: EmojiSelectorPopoverProps) => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);

    const handleEmojiClick = (emojiData: EmojiClickData) => {
      onChangeEmoji(emojiData.emoji);
      setPopoverOpen(false); // Close the popover after selecting emoji
    };

    const popoverRef = useRef<HTMLDivElement | null>(null); // Reference to the popover container

    // Close popover if clicked outside
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target as Node)
        ) {
          setPopoverOpen(false); // Close the popover if the click is outside
        }
      };

      // Add event listener on mount
      document.addEventListener('mousedown', handleClickOutside);

      // Cleanup the event listener on unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <PopoverContainer>
        <PopoverButton onClick={() => setPopoverOpen(!isPopoverOpen)}>
          <TextHeading7
            color={Palette.almostBlack}
            text={selectedEmoji ? `Change emoji` : 'Pick emoji'}
          />
          <PaddingRight amount={8} />
          <div
            style={{
              transformOrigin: 'center',
              rotate: isPopoverOpen ? '-90deg' : '90deg',
            }}>
            <AssetIconChevron />
          </div>
        </PopoverButton>
        {selectedEmoji ? (
          <TextHeading2 text={selectedEmoji} />
        ) : (
          <TextHeading8 text={'No emoji selected...'} />
        )}

        {isPopoverOpen && (
          <PopoverContent ref={popoverRef}>
            <EmojiPicker
              suggestedEmojisMode={SuggestionMode.RECENT}
              onEmojiClick={handleEmojiClick}
            />
          </PopoverContent>
        )}
      </PopoverContainer>
    );
  },
);
