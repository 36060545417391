import {Palette} from 'hubbl-shared';
import styled from 'styled-components';
import {LoadingAnimation} from './components/loading-animation/LoadingAnimation';
import {RootNavigator} from './navigation/RootNavigator';
import {AuthProvider} from './store/AuthProvider';
import {RootRoute} from './routes/RootRoute';

function checkScreenSize() {
  const minDesktopWidth = 720; // Adjust based on your needs
  const isMobile = window.innerWidth < minDesktopWidth;

  const overlayId = 'mobile-warning-overlay';
  let overlay = document.getElementById(overlayId);

  // Get the current route from the URL
  const currentPath = window.location.pathname as RootRoute;

  // Define which routes should NOT show the overlay
  const excludedRoutes = [
    RootRoute.GetStarted,
    RootRoute.LogIn,
    RootRoute.GetStartedCompleted,
    RootRoute.Invited,
    RootRoute.Event,
  ];

  if (
    isMobile &&
    !excludedRoutes.includes(currentPath) &&
    !currentPath.includes(RootRoute.Event)
  ) {
    if (!overlay) {
      overlay = document.createElement('div');
      overlay.id = overlayId;
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100vw';
      overlay.style.height = '100vh';
      overlay.style.backgroundColor = Palette.primarySubtleGreen;
      overlay.style.display = 'flex';
      overlay.style.alignItems = 'center';
      overlay.style.justifyContent = 'center';
      overlay.style.textAlign = 'center';
      overlay.style.zIndex = '9999';
      overlay.innerHTML = `
        <div>
          <h2>Admin Dashboard is only accessible from a desktop.</h2>
          <p>Please visit this page on a larger screen, while we are working on a mobile version 😇</p>
        </div>
      `;
      document.body.appendChild(overlay);
    }
  } else {
    if (overlay) {
      overlay.remove(); // Remove the overlay when resizing back to desktop
    }
  }
}

// Run on page load
checkScreenSize();

// Continuously listen for screen size changes
window.addEventListener('resize', checkScreenSize);
const AppContainer = styled.div`
  background-color: ${Palette.primarySubtleGreen};
  height: 100%; /* Full height of the viewport */
  display: flex;
  flex-direction: column;
`;

export const App = () => {
  return (
    <AuthProvider>
      <AppContainer className="app">
        <main className="content">
          <RootNavigator />
        </main>
        <LoadingAnimation />
      </AppContainer>
    </AuthProvider>
  );
};
