import {getCoolEmoji} from 'hubbl-shared';
import {memo} from 'react';
import styled from 'styled-components';
import comingSoonDashboardMockImage from '../../../../assets/images/comingSoonDashboard.png';
import {
  PaddingBottom,
  Scene,
  TextHeading1,
  TextHeading4,
} from '../../../../components';
import {useAppSelector} from '../../../../hooks';
import {UserSelector} from '../../../../store/user/UserSelector';
export const SceneDashboard = memo(() => {
  const userDetails = useAppSelector(UserSelector.getUserDetails);
  return (
    <Scene>
      <TextHeading1 text={`Welcome, ${userDetails?.firstName} 👋`} />
      <PaddingBottom amount={16} />
      <TextHeading4
        text={`See below for what's happening at ${userDetails?.companyName} ${getCoolEmoji()}`}
      />
      <PaddingBottom amount={50} />

      <div style={{position: 'relative'}}>
        <ComingSoonDashboardMockImage />
        <div
          style={{
            position: 'absolute',
            justifySelf: 'center',
            top: 200,
            bottom: 0,
            right: 0,
            left: 0,
            marginLeft: 20,
            rotate: '-15deg',
          }}>
          <TextHeading1 text="Coming soon ✨" />
        </div>
      </div>
    </Scene>
  );
});

const ComingSoonDashboardMockImage = styled.img.attrs({
  src: `${comingSoonDashboardMockImage}`,
})`
  alt: 'profile-user';
  width: 100%;
  filter: blur(5px);
  object-fit: cover;
`;
