import {Palette} from 'hubbl-shared';
import {User} from 'hubbl-shared';
import {useStorageImageUrl} from '../../hooks/api/useStorageImageUrl';
import styled from 'styled-components';

const AvatarContainer = styled.div`
  position: relative;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: ${Palette.almostBlack};
  color: ${Palette.white};
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 1000;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px;
    border-style: solid;
    border-color: ${Palette.almostBlack} transparent transparent transparent;
  }
`;

type RequiredUserProps = Pick<User, 'id' | 'fullName' | 'photoUrl'>;

interface Props<T extends RequiredUserProps> {
  attendee: T;
  isFirst?: boolean;
}

export const AttendeeAvatar = <T extends RequiredUserProps>({
  attendee,
  isFirst = false,
}: Props<T>) => {
  const photoUrl = useStorageImageUrl(attendee.photoUrl);
  return (
    <AvatarContainer
      style={{
        marginLeft: isFirst ? 0 : '-12px',
      }}>
      <div
        style={{
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          border: `2px solid ${Palette.white}`,
          overflow: 'hidden',
          position: 'relative',
        }}>
        <img
          src={photoUrl}
          alt={attendee.fullName}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
      <Tooltip className="tooltip">{attendee.fullName}</Tooltip>
    </AvatarContainer>
  );
};
