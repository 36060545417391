import {Palette} from 'hubbl-shared';

/**
 * Calculates the relative luminance of a color
 * @param r Red component (0-255)
 * @param g Green component (0-255)
 * @param b Blue component (0-255)
 * @returns Relative luminance value (0-1)
 */
export const getRelativeLuminance = (
  r: number,
  g: number,
  b: number,
): number => {
  const [rs, gs, bs] = [r, g, b].map(val => {
    val = val / 255;
    return val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
};

/**
 * Determines if white or black text would be more readable on a given background color
 * @param r Red component (0-255)
 * @param g Green component (0-255)
 * @param b Blue component (0-255)
 * @returns 'white' or 'black' based on which would be more readable
 */
export const getContrastColor = (
  r: number,
  g: number,
  b: number,
): Palette.white | Palette.almostBlack => {
  const luminance = getRelativeLuminance(r, g, b);
  // Using a threshold of 0.5 for good contrast
  return luminance > 0.5 ? Palette.almostBlack : Palette.white;
};
