import {Palette} from 'hubbl-shared';
import {memo} from 'react';
import styled from 'styled-components';
import {useAppSelector} from '../../hooks';
import {useStorageImageUrl} from '../../hooks/api/useStorageImageUrl';
import {UserSelector} from '../../store/user/UserSelector';
import {PaddingRight} from '../padding/PaddingRight';
import {TextHeading7} from '../text/TextHeading7';
import {TextHeading8} from '../text/TextHeading8';

interface UserInfoDisplayProps {
  isCompact?: boolean;
}

export const UserInfoDisplay = memo(
  ({isCompact = false}: UserInfoDisplayProps) => {
    const userDetails = useAppSelector(UserSelector.getUserDetails);
    const profilePic = useStorageImageUrl(userDetails?.photoUrl);

    return (
      <Container>
        <StyledProfilePicture src={profilePic} $isCompact={isCompact} />
        <PaddingRight amount={8} />
        <div>
          <TextHeading7 text={userDetails?.fullName} />
          {!isCompact && (
            <TextHeading8
              color={Palette.almostBlack750}
              text={userDetails?.companyName}
            />
          )}
        </div>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledProfilePicture = styled.img<{$isCompact: boolean}>`
  width: ${props => (props.$isCompact ? '24px' : '32px')};
  height: ${props => (props.$isCompact ? '24px' : '32px')};
  border-radius: ${props => (props.$isCompact ? '12px' : '16px')};
  object-fit: cover;
  object-position: center;
`;
