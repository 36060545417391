import {Palette} from 'hubbl-shared';
import {AssetIconProps} from '../types';
import {memo} from 'react';

export const AssetIconEdit = memo(
  ({size = 24, color = Palette.almostBlack}: AssetIconProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9664 2.25L11 2.25H13.125C13.5392 2.25 13.875 2.58579 13.875 3C13.875 3.41421 13.5392 3.75 13.125 3.75H11C9.58749 3.75 8.57322 3.75058 7.77708 3.81563C6.9897 3.87996 6.48197 4.00359 6.07054 4.21322C5.27085 4.62068 4.62068 5.27085 4.21322 6.07054C4.00359 6.48197 3.87996 6.9897 3.81563 7.77708C3.75058 8.57322 3.75 9.58749 3.75 11V13C3.75 14.4125 3.75058 15.4268 3.81563 16.2229C3.87996 17.0103 4.00359 17.518 4.21322 17.9295C4.62068 18.7291 5.27085 19.3793 6.07054 19.7868C6.48197 19.9964 6.9897 20.12 7.77708 20.1844C8.57322 20.2494 9.58749 20.25 11 20.25H13C14.4125 20.25 15.4268 20.2494 16.2229 20.1844C17.0103 20.12 17.518 19.9964 17.9295 19.7868C18.7291 19.3793 19.3793 18.7291 19.7868 17.9295C19.9964 17.518 20.12 17.0103 20.1844 16.2229C20.2494 15.4268 20.25 14.4125 20.25 13V10.875C20.25 10.4608 20.5858 10.125 21 10.125C21.4142 10.125 21.75 10.4608 21.75 10.875V13V13.0336C21.75 14.4053 21.75 15.4807 21.6794 16.3451C21.6075 17.2252 21.4586 17.9523 21.1233 18.6104C20.572 19.6924 19.6924 20.572 18.6104 21.1233C17.9523 21.4586 17.2252 21.6075 16.3451 21.6794C15.4807 21.75 14.4053 21.75 13.0336 21.75H13H11H10.9664C9.59471 21.75 8.51928 21.75 7.65494 21.6794C6.77479 21.6075 6.04769 21.4586 5.38955 21.1233C4.30762 20.572 3.42798 19.6924 2.87671 18.6104C2.54138 17.9523 2.39252 17.2252 2.32061 16.3451C2.24999 15.4807 2.25 14.4053 2.25 13.0336L2.25 13V11L2.25 10.9664C2.25 9.59472 2.24999 8.51929 2.32061 7.65494C2.39252 6.77479 2.54138 6.04769 2.87671 5.38955C3.42798 4.30762 4.30762 3.42798 5.38955 2.87671C6.04769 2.54138 6.77479 2.39252 7.65494 2.32061C8.51929 2.24999 9.59472 2.25 10.9664 2.25Z"
          fill={color}
        />
        <path
          d="M9.8837 11.6963L18.3154 3.26462C18.7114 2.86861 18.9094 2.6706 19.1377 2.59641C19.3386 2.53115 19.5549 2.53115 19.7558 2.59641C19.9841 2.6706 20.1821 2.86861 20.5781 3.26462L20.9701 3.65661C21.3661 4.05263 21.5641 4.25063 21.6383 4.47896C21.7036 4.67981 21.7036 4.89615 21.6383 5.097C21.5641 5.32532 21.3661 5.52333 20.9701 5.91935L12.5384 14.351L9.46041 14.7743L9.8837 11.6963Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.3343 4.97913L18.9891 7.63385"
          stroke={color}
          stroke-width="1.5"
        />
      </svg>
    );
  },
);
