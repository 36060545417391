import {getRandomPrimaryColor, Palette} from 'hubbl-shared';
import React from 'react';
import styled from 'styled-components';
import {SelectablePill} from '../pill/SelectablePill';

interface RadioOption {
  label: string;
  value: string;
}

interface RadioGroupProps {
  options: RadioOption[];
  selectedValue: string;
  onChange: (value: string) => void;
}

const RadioGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  selectedValue,
  onChange,
}) => {
  return (
    <RadioGroupContainer>
      {options.map(option => (
        <SelectablePill
          isCompact
          isSelected={selectedValue === option.value}
          label={option.label}
          onClick={() => onChange(option.value)}
          selectedColor={
            getRandomPrimaryColor(option.value) ?? Palette.primaryCherokee
          }
        />
      ))}
    </RadioGroupContainer>
  );
};
