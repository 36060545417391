import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User, Question} from 'hubbl-shared';
import {fetchUserDetails} from './UserThunks';

interface UserState {
  userDetails: User | null;
  eventCreation: {
    newQuestions: Question[];
  };
}

const initialState: UserState = {
  userDetails: null,
  eventCreation: {
    newQuestions: [],
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Reset user details
    resetUserDetails: state => {
      state.userDetails = null;
    },

    // Add a question to the event
    addEventQuestion: (state, action: PayloadAction<Question>) => {
      state.eventCreation.newQuestions.push(action.payload);
    },
    resetEventQuestions: state => {
      state.eventCreation.newQuestions = [];
    },

    // Remove a question from the event by id
    removeEventQuestion: (state, action: PayloadAction<string>) => {
      state.eventCreation.newQuestions =
        state.eventCreation.newQuestions.filter(
          question => question.id !== action.payload,
        );
    },

    // Update a specific question (e.g., edit question text or options)
    updateEventQuestion: (state, action: PayloadAction<Question>) => {
      const index = state.eventCreation.newQuestions.findIndex(
        question => question.id === action.payload.id,
      );
      if (index !== -1) {
        state.eventCreation.newQuestions[index] = action.payload;
      }
    },

    // Reorder questions using indices
    reorderQuestions: (
      state,
      action: PayloadAction<{sourceIndex: number; destinationIndex: number}>,
    ) => {
      const {sourceIndex, destinationIndex} = action.payload;
      const [movedQuestion] = state.eventCreation.newQuestions.splice(
        sourceIndex,
        1,
      );
      state.eventCreation.newQuestions.splice(
        destinationIndex,
        0,
        movedQuestion,
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.userDetails = action.payload;
    });
  },
});

export const {
  resetUserDetails,
  addEventQuestion,
  reorderQuestions,
  removeEventQuestion,
  updateEventQuestion,
  resetEventQuestions,
} = userSlice.actions;

export const UserReducer = userSlice.reducer;
