import {useCallback} from 'react';
import {AssetIconDownloadCalendar} from '../../../../../../assets';
import {IconButton} from '../../../../../../components/buttons/icon-button/IconButton';
import {EventDTO} from '../../../../../../models/event/EventDTO';
import {Palette} from 'hubbl-shared';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  position: relative;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  padding: 4px 8px;
  background-color: ${Palette.almostBlack};
  color: ${Palette.white};
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 1000;

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 10px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent transparent ${Palette.almostBlack} transparent;
  }
`;

interface DownloadEventCalendarButtonProps {
  event: EventDTO;
  shouldPulse: boolean;
}

export const DownloadEventCalendarButton = ({
  event,
  shouldPulse,
}: DownloadEventCalendarButtonProps) => {
  const generateICSFile = useCallback(() => {
    if (!event) return;

    const startDate = event.startDateTime.toDate();
    const endDate =
      event.endDateTime?.toDate() ||
      new Date(startDate.getTime() + 60 * 60 * 1000);

    // Format dates to iCal format
    const formatDate = (date: Date) => {
      return date.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
    };

    // Get the current URL of the event
    const eventUrl = window.location.href;

    const icsContent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'CALSCALE:GREGORIAN',
      'METHOD:PUBLISH',
      'BEGIN:VEVENT',
      `SUMMARY:${event.eventTitle.en} ${event.eventEmoji}`,
      `DTSTART:${formatDate(startDate)}`,
      `DTEND:${formatDate(endDate)}`,
      `DESCRIPTION:${event.eventDescription.en.replace(
        /\n/g,
        '\\n',
      )}\\n\\nEvent link: ${eventUrl}`,
      event.eventLocation ? `LOCATION:${event.eventLocation}` : '',
      `URL:${eventUrl}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ]
      .filter(Boolean)
      .join('\r\n');

    const blob = new Blob([icsContent], {type: 'text/calendar;charset=utf-8'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute(
      'download',
      `${event.eventTitle.en.replace(/\s+/g, '-')}.ics`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [event]);

  return (
    <ButtonContainer>
      <IconButton
        icon={
          <AssetIconDownloadCalendar
            color={shouldPulse ? Palette.secondaryOrange : Palette.almostBlack}
          />
        }
        onClick={generateICSFile}
        text=""
        backgroundColor={Palette.white}
      />
      <Tooltip className="tooltip">Download to calendar</Tooltip>
    </ButtonContainer>
  );
};
