import {AnimatePresence, motion} from 'framer-motion';
import {memo} from 'react';
import styled from 'styled-components';
import {PrimaryButton, SecondaryButton, WhiteContainer} from '..';
import {TextHeading3} from '../text/TextHeading3';
import {TextHeading5} from '../text/TextHeading5';

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
`;

const ModalWrapper = styled(motion.div)`
  width: 400px;
  max-width: 100%;
  transform-origin: center;
  margin: auto;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ModalInnerContent = styled.div`
  text-align: center;

  @media (max-width: 480px) {
    padding: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

const MessageContainer = styled(motion.div)`
  margin-top: 1rem;
  line-height: 1.5;
  text-align: center;

  @media (max-width: 480px) {
    margin-top: 0.75rem;
  }
`;

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
}

export const ConfirmationModal = memo(
  ({
    isOpen,
    onClose,
    onConfirm,
    title,
    message,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
  }: ConfirmationModalProps) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <ModalOverlay
            onClick={onClose}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.2, ease: 'easeInOut'}}>
            <ModalWrapper
              onClick={e => e.stopPropagation()}
              initial={{y: 20, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -20, opacity: 0}}
              transition={{duration: 0.2, ease: 'easeInOut'}}
              whileHover={{
                scale: 1.01,
                transition: {duration: 0.2, ease: 'easeInOut'},
              }}>
              <WhiteContainer>
                <ModalInnerContent>
                  <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.1, duration: 0.2, ease: 'easeInOut'}}>
                    <TextHeading3 text={title} />
                  </motion.div>
                  <MessageContainer
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.2, duration: 0.2, ease: 'easeInOut'}}>
                    <TextHeading5 text={message} />
                  </MessageContainer>
                  <ButtonContainer>
                    <SecondaryButton label={confirmLabel} onClick={onConfirm} />
                    <PrimaryButton label={cancelLabel} onClick={onClose} />
                  </ButtonContainer>
                </ModalInnerContent>
              </WhiteContainer>
            </ModalWrapper>
          </ModalOverlay>
        )}
      </AnimatePresence>
    );
  },
);
