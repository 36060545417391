import {createSlice} from '@reduxjs/toolkit';
import {fetchEvents} from './EventThunks';
import {DocumentSnapshot} from 'firebase/firestore';
import {EventDTO} from '../../models/event/EventDTO';

interface EventState {
  events: EventDTO[];
  lastVisible: DocumentSnapshot<EventDTO> | null;
  loading: boolean;
  error: string | null;
  hasMore: boolean;
}

const initialState: EventState = {
  events: [],
  lastVisible: null,
  loading: false,
  error: null,
  hasMore: true,
};

export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    resetEvents: state => {
      state.events = [];
      state.lastVisible = null;
      state.hasMore = true;
    },
    addEvent: (state, action) => {
      state.events = [action.payload, ...state.events];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.hasMore = action.payload.lastVisible !== null;

        // Check if it's the next page or first page
        if (action.payload.isNextPage) {
          state.events = [...state.events, ...action.payload.events]; // Append new events for pagination
        } else {
          state.events = action.payload.events; // Overwrite events on the first page load
        }

        state.lastVisible = action.payload.lastVisible; // Update the lastVisible for pagination
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.hasMore = false;
        state.error = action.error.message || 'Error fetching events';
      });
  },
});

export const {resetEvents, addEvent} = eventSlice.actions;

export const EventReducer = eventSlice.reducer;
