import {memo, useCallback, useMemo} from 'react';
import {DragDropContext, Draggable, DropResult} from 'react-beautiful-dnd'; // For reordering
import {useAppDispatch, useAppSelector} from '../../../../../../hooks';
import {UserActions} from '../../../../../../store/user/UserActions';
import {UserSelector} from '../../../../../../store/user/UserSelector';
import {DraggableQuestionItem} from './components/draggable-question-item/DraggableQuestionItem';
import {StrictModeDroppable} from './components/strict-droppable/StrictModeDroppable';

export const QuestionsList = memo(() => {
  const dispatch = useAppDispatch();

  const questions = useAppSelector(UserSelector.getEventCreationQuestions);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return; // Dropped outside the list
      dispatch(
        UserActions.reorderQuestions({
          sourceIndex: result.source.index,
          destinationIndex: result.destination.index,
        }),
      );
    },
    [dispatch],
  );

  const questionComponents = useMemo(
    () =>
      questions.map((question, index) => (
        <Draggable key={question.id} draggableId={question.id} index={index}>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
              }}>
              <DraggableQuestionItem index={index} question={question} />
            </div>
          )}
        </Draggable>
      )),
    [questions],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable
        droppableId="questions"
        renderClone={(provided, _snapshot, rubric) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
              ...provided.draggableProps.style,
            }}>
            <DraggableQuestionItem
              isBeingDragged
              index={rubric.source.index}
              question={questions[rubric.source.index]}
            />
          </div>
        )}>
        {provided => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{width: '100%'}}>
              {questionComponents}
              {provided.placeholder}
            </div>
          );
        }}
      </StrictModeDroppable>
    </DragDropContext>
  );
});
