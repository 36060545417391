import {Palette} from 'hubbl-shared';
import moment from 'moment';
import {useCallback, useMemo, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {Api} from '../../../../api';
import {
  AssetAppleAppStoreButton,
  AssetGooglePlayButton,
  AssetIconPin,
} from '../../../../assets';
import {
  PaddingBottom,
  PrimaryButton,
  SecondaryButton,
  Squircle,
  TextHeading2,
  TextHeading4,
  TextHeading5,
  TextHeading6,
  WhiteContainer,
} from '../../../../components';
import {ConfirmationModal} from '../../../../components/modals/ConfirmationModal';
import {useAppDispatch} from '../../../../hooks/store/useAppDispatch';
import {useAppSelector} from '../../../../hooks/store/useAppSelector';
import {EventDTO} from '../../../../models/event/EventDTO';
import {GlobalActions} from '../../../../store/global/GlobalActions';
import {UserSelector} from '../../../../store/user/UserSelector';
import {DownloadEventCalendarButton} from './components/download-event-calendar-button/DownloadEventCalendarButton';
import {FloatingAvatars} from './components/floating-avatars/FloatingAvatars';
import {QuestionsModal} from './components/questions-modal/QuestionsModal';
import {MobileView} from 'react-device-detect';
import {AttendeeRack} from '../../../../components/attendee-rack/AttendeeRack';

const Panel = styled.div`
  padding: 4rem;
  background: ${Palette.primarySubtleGreen};
  max-height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0;
    position: static;
    max-height: none;
    overflow: visible;
    height: auto;
    background: transparent;
    margin-bottom: 2rem;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledWhiteContainer = styled(WhiteContainer)`
  @media (max-width: 768px) {
    background: transparent;
    box-shadow: none;
    margin-bottom: 2rem;
  }
`;

const DeclinedBadge = styled.div`
  background: ${Palette.primaryTerraCotta};

  padding: 4px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 16px;
`;

const DateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 24px;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

const DayNumber = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 4px;
  color: ${Palette.almostBlack};

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const EventInfoContainer = styled.div`
  margin-bottom: 2rem;
`;

const DateBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 1rem;
`;

const TimeLocation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedButtonContainer = styled.div<{shouldPulse: boolean}>`
  position: absolute;
  top: 0;
  right: 0;
  animation: ${props => (props.shouldPulse ? pulseAnimation : 'none')} 2s
    ease-in-out 2;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  gap: 8px;
`;

interface Props {
  event: EventDTO;
  setEvent: (event: EventDTO) => void;
  isUserAttending: boolean;
}

export const EventRightPanel = ({event, setEvent, isUserAttending}: Props) => {
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(UserSelector.getUserDetails);
  const startDate = moment(event.startDateTime.toDate());
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isQuestionsModalOpen, setIsQuestionsModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [shouldPulseDownloadButton, setShouldPulseDownloadButton] =
    useState(false);

  const isUserDeclined = useMemo(() => {
    if (!userDetails) return false;
    return event.declinedUserIds?.includes(userDetails.id) || false;
  }, [event.declinedUserIds, userDetails]);

  const endDateTime = useMemo(() => {
    if (!event.endDateTime) {
      return moment(startDate).add(2, 'hours').format('HH:mm');
    }

    return moment(event.endDateTime.toDate()).format('HH:mm');
  }, [event.endDateTime, startDate]);

  const daysUntilRsvp = useMemo(() => {
    if (!event) return null;

    if (event.deadline) {
      return moment(event.deadline?.toDate()).diff(moment(), 'days');
    }

    return moment(event.startDateTime.toDate()).diff(moment(), 'days');
  }, [event]);

  const rsvpColor = useMemo(() => {
    if (!daysUntilRsvp) {
      return Palette.white;
    }
    if (daysUntilRsvp < 3) {
      return Palette.primaryTerraCotta;
    } else if (daysUntilRsvp < 7) {
      return Palette.primaryCherokee;
    } else {
      return Palette.white;
    }
  }, [daysUntilRsvp]);

  const onJoinEvent = useCallback(async () => {
    if (event.questions && event.questions.length > 0) {
      setIsQuestionsModalOpen(true);
      return;
    }

    dispatch(GlobalActions.setIsBusy(true));

    try {
      if (!userDetails) {
        throw new Error('User details not found');
      }

      await Api.event.joinEvent(event.companyId, event.id, userDetails);
      // Refresh the event data after joining
      const updatedEvent = await Api.event.getEventById(
        event.companyId,
        event.id,
      );
      if (updatedEvent) {
        setEvent(updatedEvent);
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch(GlobalActions.setIsBusy(false));
    }
  }, [
    dispatch,
    userDetails,
    event.companyId,
    event.id,
    setEvent,
    event.questions,
  ]);

  const onDeclineEvent = useCallback(async () => {
    dispatch(GlobalActions.setIsBusy(true));

    try {
      if (!userDetails) {
        throw new Error('User details not found');
      }

      await Api.event.declineEvent(event.companyId, event.id, {
        declinedAt: moment().format(),
        user: {
          firstName: userDetails.firstName,
          id: userDetails.id,
          fullName: userDetails.fullName,
          photoUrl: userDetails.photoUrl,
          email: userDetails.email,
          department: userDetails.department,
        },
      });

      // Refresh the event data after declining
      const updatedEvent = await Api.event.getEventById(
        event.companyId,
        event.id,
      );
      if (updatedEvent) {
        setEvent(updatedEvent);
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch(GlobalActions.setIsBusy(false));
    }
  }, [dispatch, event.companyId, event.id, userDetails, setEvent]);

  const onLeaveEvent = useCallback(async () => {
    dispatch(GlobalActions.setIsBusy(true));

    try {
      if (!userDetails) {
        throw new Error('User details not found');
      }

      await Api.event.leaveEvent(event.companyId, event.id, userDetails);
      // Refresh the event data after leaving
      const updatedEvent = await Api.event.getEventById(
        event.companyId,
        event.id,
      );
      if (updatedEvent) {
        setEvent(updatedEvent);
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch(GlobalActions.setIsBusy(false));
    }
  }, [dispatch, userDetails, event.companyId, event.id, setEvent]);

  const onUndeclineEvent = useCallback(async () => {
    if (!userDetails) return;

    dispatch(GlobalActions.setIsBusy(true));

    try {
      await Api.event.undeclineEvent(event.companyId, event.id, userDetails.id);
      // Refresh the event data after undececlining
      const updatedEvent = await Api.event.getEventById(
        event.companyId,
        event.id,
      );
      if (updatedEvent) {
        setEvent(updatedEvent);
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch(GlobalActions.setIsBusy(false));
    }
  }, [dispatch, event.companyId, event.id, userDetails, setEvent]);

  const handleLeaveClick = useCallback(() => {
    setIsLeaveModalOpen(true);
  }, []);

  const handleLeaveConfirm = useCallback(async () => {
    setIsLeaveModalOpen(false);
    await onLeaveEvent();
  }, [onLeaveEvent]);

  const handleLeaveCancel = useCallback(() => {
    setIsLeaveModalOpen(false);
  }, []);

  const handleQuestionsSuccess = useCallback(async () => {
    // Refresh event data after questions are submitted
    const updatedEvent = await Api.event.getEventById(
      event.companyId,
      event.id,
    );
    if (updatedEvent) {
      setEvent(updatedEvent);
      setShouldPulseDownloadButton(true);
      // Reset the pulse after animation (2s * 2 iterations)
      setTimeout(() => setShouldPulseDownloadButton(false), 4000);
    }
  }, [event.companyId, event.id, setEvent]);

  const handleDeclineClick = useCallback(() => {
    setIsDeclineModalOpen(true);
  }, []);

  const handleDeclineConfirm = useCallback(async () => {
    setIsDeclineModalOpen(false);
    await onDeclineEvent();
  }, [onDeclineEvent]);

  const handleDeclineCancel = useCallback(() => {
    setIsDeclineModalOpen(false);
  }, []);

  return (
    <Panel>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          zIndex: -200,
        }}>
        <FloatingAvatars attendees={event.attendees} />
      </div>
      <StyledWhiteContainer color={Palette.white}>
        <div style={{position: 'relative', zIndex: 1}}>
          {isUserDeclined && (
            <DeclinedBadge>
              <TextHeading5 text="You've declined this event" />
            </DeclinedBadge>
          )}
          <TextHeading2
            text={
              isUserAttending
                ? "You're attending!"
                : isUserDeclined
                  ? "You've declined this event"
                  : 'Register for event'
            }
          />
          <PaddingBottom amount={24} />
          <EventInfoContainer>
            <DateBox>
              <Squircle fillColor={Palette.white} borderRadius={16} width={64}>
                <DateContent>
                  <PaddingBottom amount={4} />
                  <DayNumber>
                    {moment(event.startDateTime.toDate()).format('DD')}
                  </DayNumber>
                  <TextHeading6
                    color={Palette.almostBlack}
                    text={moment(event.startDateTime.toDate())
                      .format('MMM')
                      .toUpperCase()}
                  />
                </DateContent>
              </Squircle>
              <TimeLocation>
                <TextHeading4
                  color={Palette.almostBlack}
                  text={startDate.format('dddd DD. MMMM')}
                />
                <TextHeading5
                  color={Palette.almostBlack}
                  text={`${startDate.format('HH:mm')}
                  ${endDateTime ? ` - ${endDateTime}` : ''}`}
                />
              </TimeLocation>
            </DateBox>
          </EventInfoContainer>

          <EventInfoContainer>
            <DateBox>
              <Squircle fillColor={rsvpColor} borderRadius={16} width={64}>
                <DateContent>
                  <PaddingBottom amount={4} />
                  <DayNumber>{daysUntilRsvp}</DayNumber>
                  <TextHeading6
                    color={Palette.almostBlack}
                    text={daysUntilRsvp === 1 ? 'DAY' : 'DAYS'}
                  />
                </DateContent>
              </Squircle>
              <TimeLocation>
                <TextHeading4
                  color={Palette.almostBlack}
                  text={'Left to respond'}
                />

                {event.deadline && (
                  <TextHeading5
                    color={Palette.almostBlack}
                    text={`Deadline: ${moment(event.deadline?.toDate()).format(
                      'dddd DD. MMMM',
                    )}`}
                  />
                )}
              </TimeLocation>
            </DateBox>
          </EventInfoContainer>

          {event.eventLocation && (
            <EventInfoContainer>
              <DateBox>
                <Squircle
                  fillColor={Palette.white}
                  borderRadius={16}
                  width={64}>
                  <div
                    style={{
                      height: 84,
                      backgroundColor: Palette.white,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <AssetIconPin size={36} />
                  </div>
                </Squircle>
                <TextHeading4
                  color={Palette.almostBlack}
                  text={event.eventLocation}
                />
              </DateBox>
            </EventInfoContainer>
          )}

          <EventInfoContainer>
            <DateBox>
              <Squircle fillColor={Palette.white} borderRadius={16} width={64}>
                <div
                  style={{
                    height: 84,
                    backgroundColor: Palette.white,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <TextHeading2 text={event.attendees.length} />
                </div>
              </Squircle>
              <TimeLocation>
                <TextHeading4
                  color={Palette.almostBlack}
                  text={'Colleagues are attending'}
                />
                <AttendeeRack users={event.attendees} />
              </TimeLocation>
            </DateBox>
          </EventInfoContainer>

          <ButtonContainer>
            {isUserAttending ? (
              <SecondaryButton label="Leave event" onClick={handleLeaveClick} />
            ) : isUserDeclined ? (
              <PrimaryButton
                label="Change response"
                onClick={onUndeclineEvent}
              />
            ) : (
              <>
                <SecondaryButton
                  label="Decline event"
                  onClick={handleDeclineClick}
                />
                <PrimaryButton label="Join event" onClick={onJoinEvent} />
              </>
            )}
          </ButtonContainer>
          <AnimatedButtonContainer shouldPulse={shouldPulseDownloadButton}>
            <DownloadEventCalendarButton
              shouldPulse={shouldPulseDownloadButton}
              event={event}
            />
          </AnimatedButtonContainer>
        </div>
      </StyledWhiteContainer>
      <MobileView>
        <div
          style={{
            marginTop: 32,
            flexDirection: 'row',
            display: 'flex',
            gap: 16,
            justifyContent: 'center',
          }}>
          <Linkbutton
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/hubbl-connecting-colleagues/id1661817144">
            <AssetAppleAppStoreButton />
          </Linkbutton>
          <Linkbutton
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.emilstepanian.workbreak">
            <AssetGooglePlayButton />
          </Linkbutton>
        </div>
      </MobileView>
      <ConfirmationModal
        isOpen={isLeaveModalOpen}
        onClose={handleLeaveCancel}
        onConfirm={handleLeaveConfirm}
        title="Leave Event"
        message="Are you sure you want to leave this event?"
        confirmLabel="Leave"
        cancelLabel="Stay in event"
      />

      <ConfirmationModal
        isOpen={isDeclineModalOpen}
        onClose={handleDeclineCancel}
        onConfirm={handleDeclineConfirm}
        title="Decline Event"
        message="Are you sure you want to decline this event? By declining, you are actively selecting to not get notifications and reminders for this event."
        confirmLabel="Decline"
        cancelLabel="Keep invitation"
      />

      {event.questions && event.questions.length > 0 && (
        <QuestionsModal
          isOpen={isQuestionsModalOpen}
          onClose={() => setIsQuestionsModalOpen(false)}
          eventId={event.id}
          companyId={event.companyId}
          questions={event.questions}
          onSuccess={handleQuestionsSuccess}
        />
      )}
    </Panel>
  );
};

const Linkbutton = styled.a`
  cursor: pointer;
  transition:
    ease background-color 250ms,
    transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;
