import {useEffect, useState, useRef} from 'react';
import {useStorageImageUrl} from './api/useStorageImageUrl';
import ColorThief from 'colorthief';
import {getContrastColor} from '../utils';
import {Palette} from 'hubbl-shared';

interface DominantColors {
  color1: string;
  color2: string;
  color3: string;
  textColor: Palette.white | Palette.almostBlack;
}

export const useDominantColors = (
  imageUrl: string | undefined,
): DominantColors | null => {
  const [colors, setColors] = useState<DominantColors | null>(null);
  const [error, setError] = useState<string | null>(null);
  const processingRef = useRef(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const coverImageUrl = useStorageImageUrl(imageUrl) || null;

  useEffect(() => {
    if (!coverImageUrl || processingRef.current) {
      return;
    }

    processingRef.current = true;

    // Clean up previous image if it exists
    if (imgRef.current) {
      imgRef.current.onload = null;
      imgRef.current.onerror = null;
    }

    const img = new Image();
    imgRef.current = img;

    const handleError = () => {
      console.error('Image load error:', {
        url: coverImageUrl,
        imgState: {
          complete: img.complete,
          naturalWidth: img.naturalWidth,
          naturalHeight: img.naturalHeight,
        },
      });
      setError('Failed to load image');
      setColors(null);
      processingRef.current = false;
    };

    const handleLoad = async () => {
      try {
        const colorThief = new ColorThief();
        const palette = colorThief.getPalette(img, 3);

        // Determine text color based on the dominant color (first color in palette)
        const textColor = getContrastColor(
          palette[0][0],
          palette[0][1],
          palette[0][2],
        );

        setColors({
          color1: `rgb(${palette[0][0]}, ${palette[0][1]}, ${palette[0][2]})`,
          color2: `rgb(${palette[1][0]}, ${palette[1][1]}, ${palette[1][2]})`,
          color3: `rgb(${palette[2][0]}, ${palette[2][1]}, ${palette[2][2]})`,
          textColor,
        });
        setError(null);
      } catch (err) {
        console.error('Error processing image:', err);
        setError('Failed to extract colors');
        setColors(null);
      } finally {
        processingRef.current = false;
      }
    };

    img.onerror = handleError;
    img.onload = handleLoad;

    // Add a timeout to handle cases where the image might not load
    const timeoutId = setTimeout(() => {
      if (processingRef.current) {
        console.error('Timeout loading image:', {
          url: coverImageUrl,
          imgState: {
            complete: img.complete,
            naturalWidth: img.naturalWidth,
            naturalHeight: img.naturalHeight,
          },
        });
        setError('Timeout loading image');
        setColors(null);
        processingRef.current = false;
      }
    }, 15000);

    // Set crossOrigin before src to avoid CORS issues on Safari
    img.crossOrigin = 'anonymous';
    img.src = coverImageUrl;

    return () => {
      clearTimeout(timeoutId);
      processingRef.current = false;
      if (imgRef.current) {
        imgRef.current.onload = null;
        imgRef.current.onerror = null;
      }
    };
  }, [coverImageUrl]); // Only depend on coverImageUrl

  if (error) {
    console.error('useDominantColors error:', error, 'for URL:', coverImageUrl);
  }

  return colors;
};
