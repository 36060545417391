import {Palette} from 'hubbl-shared';
import {AssetIconProps} from '../types';
import {memo} from 'react';

export const AssetIconAdd = memo(
  ({size = 24, color = Palette.almostBlack}: AssetIconProps) => {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
          d="M4.94974 11.9498H18.9497"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M11.9498 4.94977V18.9498"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    );
  },
);
