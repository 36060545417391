import {getSvgPath} from 'figma-squircle';
import {Palette} from 'hubbl-shared';
import {
  memo,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

interface SquircleProps extends PropsWithChildren {
  width: number;
  height?: number; // Optional for dynamic height
  fillColor?: Palette;
  borderRadius?: number;
}

export const Squircle = memo(
  ({
    height,
    width,
    children,
    fillColor = Palette.white,
    borderRadius = 24,
  }: SquircleProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [calculatedHeight, setCalculatedHeight] = useState<number>(
      height || 100, // Default to 100 if no height is provided
    );

    useLayoutEffect(() => {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          const contentHeight = entry.target.scrollHeight;
          if (contentHeight !== calculatedHeight) {
            setCalculatedHeight(contentHeight);
          }
        }
      });

      if (contentRef.current) {
        resizeObserver.observe(contentRef.current);
      }

      return () => {
        if (contentRef.current) {
          resizeObserver.unobserve(contentRef.current);
        }
      };
    }, [children, height, calculatedHeight]);

    const svgPath = getSvgPath({
      width,
      height: calculatedHeight,
      cornerRadius: borderRadius,
      cornerSmoothing: 1,
    });

    return (
      <StyledSvg style={{borderRadius, width, height: calculatedHeight}}>
        <path d={svgPath} fill={fillColor} />
        <foreignObject
          x="0"
          y="0"
          width={width}
          height={calculatedHeight}
          style={{borderRadius}}>
          <StyledContentWrapper ref={contentRef}>
            {children}
          </StyledContentWrapper>
        </foreignObject>
      </StyledSvg>
    );
  },
);

const StyledSvg = styled.svg`
  box-shadow: 0px 2.858px 17.148px 0px rgba(0, 0, 0, 0.08);
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto; /* Adjust to content height */
  overflow: hidden; /* Prevent content overflow */
`;
