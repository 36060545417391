import {memo} from 'react';
import {TextHeading5, TextHeading8} from '..';

interface FieldProps {
  label: string;
  value: string | number;
}

export const Field = memo(({label, value}: FieldProps) => {
  return (
    <div>
      <TextHeading5 text={label} />
      <TextHeading8 text={value} />
    </div>
  );
});
