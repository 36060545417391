import React, {memo} from 'react';
import {useStorageImageUrl} from '../../hooks/api/useStorageImageUrl';

interface AvatarStorageRefProps {
  imageRef: string | undefined;
}

export const AvatarStorageRef = memo(({imageRef}: AvatarStorageRefProps) => {
  const imageUrl = useStorageImageUrl(imageRef);
  if (!imageUrl) {
    return null;
  }
  return (
    <img
      width={40}
      height={40}
      style={{objectFit: 'cover', borderRadius: 50}}
      src={imageUrl}
      alt="profile avatar"
    />
  );
});
