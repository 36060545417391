import {Palette} from 'hubbl-shared';
import {memo, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {TextHeading4} from '../text/TextHeading4';
import {RadioButtonCircle} from './components/radio-button-circle/RadioButtonCircle';
import {TextHeading7} from '../text/TextHeading7';

interface SelectablePillProps {
  label: string;
  emoji?: string;
  selectedColor: Palette;
  defaultColor?: Palette;
  selectedTextColor?: Palette;
  isCompact?: boolean;
  onClick: () => void;
  isSelected: boolean;
}

export const SelectablePill = memo(
  ({
    label,
    selectedColor = Palette.primarySubtleGreen,
    emoji = '',
    onClick,
    defaultColor = Palette.white,
    selectedTextColor,
    isCompact = false, // Default to false if not provided
    isSelected,
  }: SelectablePillProps) => {
    const [textWidth, setTextWidth] = useState<string | number | undefined>(
      isCompact ? 'auto' : window.innerWidth < 780 ? 220 : 400,
    );

    useEffect(() => {
      if (isCompact) return; // Skip resizing logic if wrapping is enabled

      function handleResize() {
        setTextWidth(window.innerWidth < 780 ? 220 : 400);
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [isCompact]);

    const textComponents = useMemo(() => {
      if (isCompact) {
        return (
          <>
            <TextHeading7
              style={{
                width: 'auto',
                whiteSpace: 'normal',
              }}
              color={isSelected ? selectedTextColor : undefined}
              text={label}
            />
            <TextHeading7
              color={isSelected ? selectedTextColor : undefined}
              text={emoji}
            />
          </>
        );
      }

      return (
        <>
          <TextHeading4
            style={{
              width: textWidth,
            }}
            color={isSelected ? selectedTextColor : undefined}
            text={label}
          />
          <TextHeading4
            color={isSelected ? selectedTextColor : undefined}
            text={emoji}
          />
        </>
      );
    }, [emoji, isCompact, isSelected, label, selectedTextColor, textWidth]);

    return (
      <SelectablePillButton
        isSelected={isSelected}
        isCompact={isCompact}
        selectedColor={selectedColor}
        defaultColor={defaultColor}
        onClick={onClick}>
        <PillContent>
          <RadioButtonCircle isSelected={isSelected} />
          {textComponents}
        </PillContent>
      </SelectablePillButton>
    );
  },
);

const PillContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; /* Aligns radio button and text on the same line */
  gap: 8px; /* Adds space between the radio button and the text */
`;

const SelectablePillButton = styled.button<{
  isSelected: boolean;
  selectedColor: Palette;
  defaultColor: Palette;
  isCompact?: boolean;
}>`
  display: flex;
  background-color: ${({isSelected, selectedColor, defaultColor}) =>
    isSelected ? selectedColor : defaultColor};

  -webkit-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  justify-content: flex-start; /* Align content to the start */
  align-items: center; /* Align items properly */
  word-wrap: break-word; /* Handle long text wrapping */
  overflow-wrap: break-word; /* Ensure text doesn't overflow */
  flex-wrap: wrap;
  margin: 4px;
  padding: ${({isCompact}) =>
    isCompact ? `12px` : `16px`}; /* Combined padding for consistency */
  border-width: 0px;
  outline: 0;
  cursor: pointer;
  transition:
    background-color 250ms ease,
    box-shadow 250ms ease,
    transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;
