import {Palette} from 'hubbl-shared';
import {ForwardedRef, forwardRef, Fragment, useState} from 'react';
import styled, {css} from 'styled-components';
import {FontConfig} from '../../constants/FontConfig';
import {FieldError} from './FieldError';
import {AssetIconEyeClosed, AssetIconEyeOpen} from '../../assets';

export interface InputProps {
  hasError?: boolean;
  validationErrorMessage?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  multiline?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  value?: string | number | readonly string[] | undefined;
  isCompact?: boolean; // New property to switch between styles
  isPassword?: boolean; // New property for password functionality
  useAlternativeTheme?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      hasError,
      onBlur,
      value,
      onChange,
      useAlternativeTheme,
      validationErrorMessage,
      multiline,
      isDisabled,
      isPassword,
      isCompact = false, // Default to false for the big style
      ...props
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [showPassword, setShowPassword] = useState(false); // Toggle visibility for passwords

    return (
      <Fragment>
        <InputRow>
          <InputContainer
            isCompact={isCompact}
            style={{
              backgroundColor: useAlternativeTheme
                ? Palette.primarySubtleGreen
                : Palette.whiteTransparent60,
              borderColor: hasError ? Palette.systemError : Palette.white,
              borderWidth: 1,
            }}>
            <StyledInput
              ref={ref}
              value={value}
              type={isPassword && !showPassword ? 'password' : 'text'} // Password or text type
              onChange={onChange}
              onBlur={onBlur}
              placeholder={props.placeholder}
              disabled={isDisabled}
              hasError={hasError}
              multiline={multiline}
              isCompact={isCompact}
              {...props}
            />
            {isPassword && (
              <p
                style={{
                  paddingTop: 6,
                }}
                onClick={() => setShowPassword(prev => !prev)}>
                {showPassword ? (
                  <AssetIconEyeClosed color={Palette.almostBlack500} />
                ) : (
                  <AssetIconEyeOpen color={Palette.almostBlack500} />
                )}
              </p>
            )}
          </InputContainer>
        </InputRow>
        {validationErrorMessage && (
          <FieldError
            errorMessage={validationErrorMessage}
            hasError={hasError}
          />
        )}
      </Fragment>
    );
  },
);

// Styled Components

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const InputContainer = styled.div<{isCompact: boolean}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({isCompact}) => (isCompact ? '40px' : '60px')};
  padding-left: ${({isCompact}) => (isCompact ? '12px' : '16px')};
  padding-right: ${({isCompact}) => (isCompact ? '12px' : '16px')};
  border-radius: ${({isCompact}) => (isCompact ? '8px' : '24px')};
  background-color: black;
  border: 1px solid #ccc;
`;

const InputFontConfig = FontConfig.Heading3;

const StyledInput = styled.input<InputProps & {hasError?: boolean}>`
  width: 100%;
  height: 100%;
  font-weight: ${() => InputFontConfig.fontWeight};
  font-size: ${({isCompact}) =>
    isCompact
      ? '14px' // Compact font size
      : `${InputFontConfig.fontSize}px`}; /* Standard font size */
  font-family: ${() => InputFontConfig.fontFamily};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  color: ${() => Palette.almostBlack};
  outline: none;

  &.disabled {
    background-color: #f0f0f0;
    color: #999;
  }

  ${({multiline}) =>
    multiline &&
    css`
      height: 166px;
      padding: 16px;
      text-align: left;
      vertical-align: top;
    `}
`;
