import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signOut,
} from 'firebase/auth';
import {Timestamp} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';
import {createInitialSystemMessage, Keys, sanitizeString} from 'hubbl-shared';
import {Api} from '../../api';
import {firebaseFunctions} from '../../api/firebase';
import {CreateEventFormType} from '../../models';
import {EventDTO} from '../../models/event/EventDTO';
import {getAuthUser, getAuthUserCompanyId} from '../../utils';
import {createAppAsyncThunk} from '../appAsyncThunk';
import {UserSelector} from './UserSelector';

export const createEvent = createAppAsyncThunk(
  'user/createEvent',
  async (
    {
      coverImage,
      eventDescription,
      eventEmoji,
      eventLocation,
      eventTitle,
      marketingStartDate,
      endDateTime,
      deadline,
      reminders,
      startDateTime,
    }: CreateEventFormType,
    thunkApi,
  ) => {
    try {
      const userCompanyId = await getAuthUserCompanyId();

      const userDetails = await Api.user.getUserDetails(
        userCompanyId,
        getAuthUser()?.uid ?? '',
      );
      const now = new Date();

      const eventId = `${sanitizeString(eventTitle)}_${now.getTime()}`;

      const imageRef = await Api.storage.uploadImage(
        coverImage,
        userCompanyId,
        eventId,
      );

      if (!userDetails) {
        throw Error('No user details');
      }

      const questions = UserSelector.getEventCreationQuestions(
        thunkApi.getState(),
      );

      const newEventItem: EventDTO = {
        attendees: [],
        maxAttendees: null,
        waitlist: [],
        companyId: userCompanyId,
        coverImageUrl: imageRef,
        created: Timestamp.fromDate(now),
        creatorId: userDetails.id,
        deadline: deadline ? Timestamp.fromDate(new Date(deadline)) : null,
        declinedUserIds: [],
        declinedUsers: [],
        endDateTime: endDateTime
          ? Timestamp.fromDate(new Date(endDateTime))
          : null,
        eventDescription: {
          da: eventDescription,
          en: eventDescription,
        },
        reminders,
        eventEmoji,
        eventLocation,
        eventTags: [],
        eventTitle: {
          da: eventTitle,
          en: eventTitle,
        },
        questionResponses: {},
        filters: [],
        id: eventId,
        isRepeatedHubbl: false,
        isRepeatEnabled: false,
        marketingStartDate: Timestamp.fromDate(new Date(marketingStartDate)),
        messages: [createInitialSystemMessage()],
        officeId: Keys.defaultOffice,
        startDateTime: Timestamp.fromDate(new Date(startDateTime)),
        invitedUsers: [],
        questions,
      };

      await Api.event.addEvent(userCompanyId, newEventItem);

      return newEventItem;
    } catch (error) {
      throw Error(`Error happened: ${JSON.stringify(error)}`);
    }
  },
);
export const fetchUserDetails = createAppAsyncThunk(
  'user/fetchUserDetails',
  async () => {
    const userCompanyId = await getAuthUserCompanyId();

    const userDetails = await Api.user.getUserDetails(
      userCompanyId,
      getAuthUser()?.uid ?? '',
    );

    return userDetails;
  },
);
interface SignInUserProps {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const signInAdmin = createAppAsyncThunk(
  'user/signInAdmin',
  async ({email, password, rememberMe}: SignInUserProps, thunkApi) => {
    try {
      const persistenceMode = rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      const auth = getAuth();
      await setPersistence(auth, persistenceMode);

      const userCredentials = await Api.user.onLogIn(email, password);

      // Call Firebase Function to check if user is an admin

      const checkStatus = httpsCallable<{isAdmin: boolean}>(
        firebaseFunctions,
        'onCheckAuth',
      );

      const response = await checkStatus();

      //@ts-ignore
      const isAdmin = response.data.isAdmin as Boolean;

      if (!isAdmin) {
        await signOut(auth);
        throw new Error('Access denied. Admins only.');
      }

      return userCredentials;
    } catch (error) {
      console.error('Error signing in:', error);
      throw Error('Error with sign in');
    }
  },
);

export const signInUser = createAppAsyncThunk(
  'user/signInUser',
  async ({email, password, rememberMe}: SignInUserProps, thunkApi) => {
    try {
      const persistenceMode = rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      const auth = getAuth();
      await setPersistence(auth, persistenceMode);

      const userCredentials = await Api.user.onLogIn(email, password);

      return userCredentials;
    } catch (error) {
      console.error('Error signing in:', error);
      throw Error('Error with sign in');
    }
  },
);

export const signOutUser = createAppAsyncThunk('user/signOutUser', async () => {
  await signOut(getAuth());
});
