import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {
  AssetIconCreateEvent,
  AssetIconDashboard,
  AssetIconEmployees,
  AssetIconEyeOpen,
} from '../../../../assets';
import {AdminWebRoute} from '../../../../routes/AdminWebRoute';
import {SidebarGroupTitle} from './components/SidebarGroupTitle';
import {SidebarItem} from './components/SidebarItem';

interface MenuItemsContainerProps {
  isCollapsed: boolean;
}

const StyledMenuItemsContainer = styled.div<{isCollapsed: boolean}>`
  padding-left: ${props => (props.isCollapsed ? undefined : '0%')};
`;

export const MenuItemsContainer = ({isCollapsed}: MenuItemsContainerProps) => {
  const location = useLocation();
  const currentRoute = location.pathname; // Get the current route from URL

  return (
    <StyledMenuItemsContainer isCollapsed={isCollapsed}>
      <SidebarGroupTitle isCollapsed={isCollapsed} text="General" />
      <SidebarItem
        title="Dashboard"
        to={AdminWebRoute.Dashboard}
        icon={<AssetIconDashboard />}
        currentRoute={currentRoute}
      />
      <SidebarItem
        title="Employee overview"
        to={AdminWebRoute.EmployeesOverview}
        icon={<AssetIconEmployees />}
        currentRoute={currentRoute}
      />

      <SidebarItem
        title="Events overview"
        to={AdminWebRoute.EventsOverview}
        icon={<AssetIconEyeOpen />}
        currentRoute={currentRoute}
      />
      <SidebarItem
        title="Create Hubbl Event"
        to={AdminWebRoute.CreateEvent}
        icon={<AssetIconCreateEvent />}
        currentRoute={currentRoute}
      />
    </StyledMenuItemsContainer>
  );
};
