import {Palette} from 'hubbl-shared';
import {User} from 'hubbl-shared';
import {AttendeeAvatar} from './AttendeeAvatar';
import styled from 'styled-components';
import {TextHeading8} from '../text/TextHeading8';

const MoreContainer = styled.div`
  position: relative;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: ${Palette.almostBlack};
  color: ${Palette.white};
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 1000;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px;
    border-style: solid;
    border-color: ${Palette.almostBlack} transparent transparent transparent;
  }
`;

type RequiredUserProps = Pick<User, 'id' | 'fullName' | 'photoUrl'>;

interface Props<T extends RequiredUserProps> {
  users: T[];
  moreBackgroundColor?: Palette;
}

export const AttendeeRack = <T extends RequiredUserProps>({
  users = [],
  moreBackgroundColor = Palette.white,
}: Props<T>) => {
  if (users.length === 0) {
    return <TextHeading8 text="None" />;
  }

  return (
    <div style={{display: 'flex', alignItems: 'center', marginTop: '0px'}}>
      <div style={{display: 'flex'}}>
        {users.slice(0, 7).map((user, index) => (
          <AttendeeAvatar key={user.id} attendee={user} isFirst={index === 0} />
        ))}
        {users.length > 7 && (
          <MoreContainer>
            <div
              style={{
                marginLeft: '-12px',
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                border: `2px solid ${Palette.white}`,
                backgroundColor: moreBackgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontWeight: 'bold',
                color: Palette.almostBlack,
                zIndex: 999,
              }}>
              +{users.length - 7}
            </div>
            <Tooltip className="tooltip">
              {users.length - 7} more{' '}
              {users.length - 7 === 1 ? 'person' : 'people'}
            </Tooltip>
          </MoreContainer>
        )}
      </div>
    </div>
  );
};
