import {global} from './ApiGlobal';
import {user} from './ApiUser';
import {hris} from './ApiHRIS';
import {company} from './ApiCompany';
import {superAdmin} from './ApiSuperAdmin';
import {storage} from './storage/ApiStorage';
import {event} from './ApiEvent';

export const Api = {
  global,
  event,
  user,
  hris,
  storage,
  company,
  superAdmin,
};
