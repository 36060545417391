import {Palette, Question} from 'hubbl-shared';
import {memo, useCallback} from 'react';
import styled from 'styled-components';
import {AssetIconEdit, AssetIconTrash} from '../../../../../../../../assets';
import {TextHeading7} from '../../../../../../../../components';
import {IconButton} from '../../../../../../../../components/buttons/icon-button/IconButton';
import {useAppDispatch} from '../../../../../../../../hooks';
import {UserActions} from '../../../../../../../../store/user/UserActions';
import {useNavigate} from 'react-router-dom';
import {AdminWebRoute} from '../../../../../../../../routes/AdminWebRoute';

interface DraggableQuestionItemProps {
  question: Question;
  index: number;
  isBeingDragged?: boolean;
}

// Styled container with a transition effect for box shadow
const DraggableContainer = styled.div<{isBeingDragged: boolean}>`
  margin-bottom: 12px;
  padding: 8px 8px 8px 16px;
  border-radius: 8px;
  background-color: ${() => Palette.primarySubtleGreen};
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Smooth box-shadow transition */
  transition: box-shadow 1s ease-in-out;

  /* Apply shadow conditionally */
  box-shadow: ${({isBeingDragged}) =>
    isBeingDragged
      ? '0px 2.858px 17.148px 0px rgba(0, 0, 0, 0.15)'
      : '0px 0px 0px 0px rgba(0, 0, 0, 0)'};
`;

export const DraggableQuestionItem = memo(
  ({question, isBeingDragged = false, index}: DraggableQuestionItemProps) => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const handleRemoveQuestion = useCallback(() => {
      dispatch(UserActions.removeEventQuestion(question.id));
    }, [question, dispatch]);

    const handleEditQuestion = useCallback(() => {
      navigate(AdminWebRoute.CreateEventAddEditQuestions, {
        state: {
          question,
        },
      });
    }, [question, navigate]);

    return (
      <DraggableContainer isBeingDragged={isBeingDragged}>
        <TextHeading7 text={`${index + 1}. ${question.questionText}`} />

        <div style={{display: 'flex', gap: '10px'}}>
          <IconButton icon={<AssetIconEdit />} onClick={handleEditQuestion} />
          <IconButton
            icon={<AssetIconTrash color={Palette.systemError} />}
            onClick={handleRemoveQuestion}
          />
        </div>
      </DraggableContainer>
    );
  },
);
