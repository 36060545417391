enum Font {
  Default = 'Poppins',
  Handwritten = 'Gochi Hand',
}

export const FontConfig: FontConfigSystem = {
  Heading1: {
    fontFamily: Font.Default,
    fontWeight: 700,
    fontSize: 40,
    letterSpacing: 0,
    lineHeight: 1,
  },
  Heading2: {
    fontFamily: Font.Default,
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: 0,
  },
  Heading3: {
    fontFamily: Font.Default,
    fontWeight: 600,
    fontSize: 18,
    letterSpacing: 0,
  },
  Heading4: {
    fontFamily: Font.Default,
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 0,
  },
  Heading5: {
    fontFamily: Font.Default,
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 0,
  },
  Heading6: {
    fontFamily: Font.Default,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0,
  },
  Heading7: {
    fontFamily: Font.Default,
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0,
  },
  Heading8: {
    fontFamily: Font.Default,
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0,
  },
  Heading9: {
    fontFamily: Font.Default,
    fontWeight: 700,
    fontSize: 10,
    letterSpacing: 0.2,
  },
  Heading10: {
    fontFamily: Font.Default,
    fontWeight: 400,
    fontSize: 10,
    letterSpacing: 0,
  },
  BodyLarge: {
    fontFamily: Font.Default,
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0,
  },
  BodyMedium: {
    fontFamily: Font.Default,
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0,
  },
  BodySmall: {
    fontFamily: Font.Default,
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: 0,
  },
  Link: {
    fontFamily: Font.Default,
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0,
  },
  DisplayLarge: {
    fontFamily: Font.Handwritten,
    fontWeight: 400,
    fontSize: 24,
    letterSpacing: 0,
  },
  DisplaySmall: {
    fontFamily: Font.Handwritten,
    fontWeight: 400,
    fontSize: 20,
    letterSpacing: 0,
  },
  Button: {
    fontFamily: Font.Default,
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: 0.16,
  },
};

type FontConfigType = Pick<
  React.CSSProperties,
  | 'fontFamily'
  | 'fontSize'
  | 'height'
  | 'letterSpacing'
  | 'fontWeight'
  | 'lineHeight'
>;

type FontConfigSystem = {
  Heading1: FontConfigType;
  Heading2: FontConfigType;
  Heading3: FontConfigType;
  Heading4: FontConfigType;
  Heading5: FontConfigType;
  Heading6: FontConfigType;
  Heading7: FontConfigType;
  Heading8: FontConfigType;
  Heading9: FontConfigType;
  Heading10: FontConfigType;
  BodyLarge: FontConfigType;
  BodyMedium: FontConfigType;
  BodySmall: FontConfigType;
  Link: FontConfigType;
  DisplayLarge: FontConfigType;
  DisplaySmall: FontConfigType;
  Button: FontConfigType;
};
