import {Palette} from 'hubbl-shared';
import {AssetIconProps} from '../types';
import {memo} from 'react';

export const AssetIconEyeClosed = memo(
  ({size = 24, color = Palette.almostBlack}: AssetIconProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.46967 2.46967C2.76256 2.17678 3.23744 2.17678 3.53033 2.46967L6.35611 5.29545L10.409 9.3483L14.6516 13.5909L18.7046 17.6439L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L18.0737 19.1343C16.4783 20.1062 14.4611 20.75 12 20.75C8.28446 20.75 5.5928 19.2834 3.83476 17.4577C2.10654 15.663 1.25 13.485 1.25 12C1.25 10.3097 2.36419 7.67946 4.68861 5.74927L2.46967 3.53033C2.17678 3.23744 2.17678 2.76256 2.46967 2.46967ZM5.75447 6.81513C3.65136 8.50541 2.75 10.7939 2.75 12C2.75 13.015 3.39346 14.837 4.91524 16.4173C6.4072 17.9666 8.71554 19.25 12 19.25C14.0186 19.25 15.6642 18.7655 16.9768 18.0374L14.0679 15.1286C13.4752 15.521 12.7639 15.75 11.9999 15.75C9.92884 15.75 8.24991 14.0711 8.24991 12C8.24991 11.236 8.47892 10.5247 8.87133 9.93199L5.75447 6.81513ZM9.96907 11.0297C9.82845 11.3235 9.74991 11.6523 9.74991 12C9.74991 13.2426 10.7573 14.25 11.9999 14.25C12.3476 14.25 12.6764 14.1715 12.9702 14.0308L9.96907 11.0297ZM12 4.75C10.9651 4.75 10.0305 4.87735 9.19036 5.09792C9.03236 5.1394 8.87761 5.1842 8.72609 5.23211C8.33114 5.35697 7.90975 5.13803 7.78489 4.74309C7.66002 4.34814 7.87897 3.92675 8.27391 3.80189C8.44903 3.74652 8.62754 3.69485 8.80946 3.64709C9.77804 3.3928 10.8409 3.25 12 3.25C15.7155 3.25 18.4072 4.71662 20.1652 6.54227C21.8935 8.33695 22.75 10.515 22.75 12C22.75 13.0899 22.2895 14.5321 21.3966 15.9082C21.2795 16.0887 21.1545 16.2688 21.0215 16.4476C20.7743 16.78 20.3044 16.849 19.9721 16.6018C19.6397 16.3546 19.5707 15.8847 19.8179 15.5524C19.9317 15.3994 20.0385 15.2456 20.1383 15.0918C20.9206 13.886 21.25 12.7242 21.25 12C21.25 10.985 20.6065 9.16305 19.0848 7.58273C17.5928 6.03338 15.2845 4.75 12 4.75Z"
          fill={color}
        />
      </svg>
    );
  },
);
