import {Palette, QuestionType} from 'hubbl-shared';

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import {memo} from 'react';
import {Pie} from 'react-chartjs-2';
import styled from 'styled-components';
import {
  PaddingBottom,
  TextHeading3,
  TextHeading5,
  TextHeading7,
} from '../../../../../../../components';
import {EventDTO} from '../../../../../../../models/event/EventDTO';
import {ChartContainer} from './components/chart-container/ChartContainer';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const StyledChartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
`;

interface EventDetailsQuestionsDataProps {
  event: EventDTO;
}

export const EventDetailsQuestionsData = memo(
  ({event}: EventDetailsQuestionsDataProps) => {
    return (
      <div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <TextHeading3 text="Aggregated Responses" />
        </div>
        <StyledChartContainer>
          {event.questions.map(question => {
            // Directly access grouped responses for each question
            const questionResponses =
              event.questionResponses[question.id] || [];

            if (question.type === QuestionType.FreeText) {
              return (
                <ChartContainer key={question.id}>
                  <TextHeading5 text={question.questionText} />
                  {questionResponses.length > 0 ? (
                    <ul>
                      {questionResponses.map((response, index) => (
                        <li key={index}>{response.freeTextResponse}</li>
                      ))}
                    </ul>
                  ) : (
                    <TextHeading7 text={'No responses yet.'}></TextHeading7>
                  )}
                </ChartContainer>
              );
            }

            // Aggregate response counts for single/multiple choice questions
            const responseCounts: Record<string, number> = {};
            question.options?.forEach(option => {
              responseCounts[option.text] = 0; // Initialize counts based on option text
            });

            questionResponses.forEach(response => {
              response.selectedOptions.forEach(optionId => {
                const matchingOption = question.options?.find(
                  opt => opt.id === optionId,
                );
                if (matchingOption) {
                  responseCounts[matchingOption.text]++; // Increment count for the text label
                }
              });
            });

            // If no responses, show a message
            if (Object.values(responseCounts).every(count => count === 0)) {
              return (
                <ChartContainer key={question.id}>
                  <TextHeading5 text={question.questionText} />
                  <PaddingBottom amount={8} />
                  <TextHeading7 text={'No responses yet'} />
                </ChartContainer>
              );
            }

            // Chart data setup
            const chartData = {
              labels: Object.keys(responseCounts),
              datasets: [
                {
                  label: 'Number of Responses',
                  data: Object.values(responseCounts),
                  backgroundColor: [
                    Palette.primaryCherokee,
                    Palette.primaryDustySky,
                    Palette.primaryPadua,
                    Palette.primaryTerraCotta,
                    Palette.secondaryOrange,
                    Palette.secondaryPurple,
                  ],
                },
              ],
            };

            return (
              <ChartContainer key={question.id}>
                <TextHeading5 text={question.questionText} />
                <PaddingBottom amount={8} />
                <Pie data={chartData} />
              </ChartContainer>
            );
          })}
        </StyledChartContainer>
      </div>
    );
  },
);
