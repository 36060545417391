import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Palette} from 'hubbl-shared';
import {AssetIconLoadMore} from '../../../../assets';
import {
  PaddingBottom,
  Scene,
  Table,
  TableBody,
  TableCell,
  TableCellContent,
  TableHead,
  TableHeader,
  TableHeaderContent,
  TableRow,
  TextHeading1,
  WhiteContainer,
} from '../../../../components';
import {IconButton} from '../../../../components/buttons/icon-button/IconButton';
import {useAppSelector} from '../../../../hooks';
import {EventDTO} from '../../../../models/event/EventDTO';
import {AdminWebRoute} from '../../../../routes/AdminWebRoute';
import {EventActions} from '../../../../store/events/EventActions';
import {EventSelector} from '../../../../store/events/EventSelector';

export const SceneEventsOverview = () => {
  const dispatch = useDispatch();
  const {events, loading, hasMore} = useAppSelector(EventSelector.getEvents);

  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    dispatch(EventActions.fetchEvents());
  }, [dispatch]);

  const loadMoreEvents = () => {
    if (loadingMore || !hasMore) return;

    setLoadingMore(true);
    dispatch(EventActions.fetchEvents());
    setLoadingMore(false);
  };

  const navigate = useNavigate();

  const onNavEvent = useCallback(
    (eventId: string) => {
      navigate(`${AdminWebRoute.EventDetails}/${eventId}`);
    },
    [navigate],
  );

  // Define table columns
  const columns = useMemo<ColumnDef<EventDTO>[]>(
    () => [
      {
        accessorKey: 'created',
        header: 'Created',
        accessorFn: row => row.created.toDate(),
        Cell: ({value}: {value: Date}) => value.toDateString(),
        sortingFn: 'datetime',
      },
      {
        accessorKey: 'eventTitle.en',
        header: 'Title',
        accessorFn: row => `${row.eventTitle.en} ${row.eventEmoji}`,
      },
      {
        accessorKey: 'startDateTime',
        header: 'Start Date',
        accessorFn: row => row.startDateTime.toDate(),
        Cell: ({value}: {value: Date}) => value.toDateString(),
        sortingFn: 'datetime',
      },
      {
        accessorKey: 'marketingStartDate',
        header: 'Publish date',
        accessorFn: row => row.marketingStartDate.toDate(),
        Cell: ({value}: {value: Date}) => value.toDateString(),
        sortingFn: 'datetime',
      },
      {
        accessorKey: 'deadline',
        header: 'RSVP deadline',
        accessorFn: row => (row.deadline ? row.deadline.toDate() : null),
        Cell: ({value}: {value: Date | null}) =>
          value ? value.toDateString() : null,
        sortingFn: 'datetime',
      },
      {
        accessorKey: 'attendees',
        header: 'Attendees',
        accessorFn: row => row.attendees.length,
        Cell: ({value}: {value: number}) => value,
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'declinedUserIds',
        header: 'Declined users amount',
        accessorFn: row => row.declinedUserIds.length,
        Cell: ({value}: {value: number}) => value,
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'questions',
        header: 'Pre-attending questions',
        accessorFn: row => (row.questions ? row.questions.length : 0),
        Cell: ({value}: {value: number}) => value,
        sortingFn: 'alphanumeric',
      },

      {
        accessorKey: 'id',
        header: 'Actions',
        enableSorting: false,
      },
    ],
    [],
  );

  const table = useReactTable({
    data: events,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Scene>
      <TextHeading1 text="Events Overview" />

      <PaddingBottom amount={24} />
      <WhiteContainer>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}>
                    <TableHeaderContent
                      isSorted={header.column.getIsSorted()}
                      label={header.column.columnDef.header as string}
                    />
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow
                onClick={() => onNavEvent(row.getValue('id'))}
                key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <TableCell key={cell.id}>
                    <TableCellContent
                      columnId={cell.column.id}
                      value={cell.getValue()}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <p>Loading...</p>}

        {hasMore && !loadingMore && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <IconButton
              onClick={loadMoreEvents}
              icon={<AssetIconLoadMore />}
              text="Load more"
              backgroundColor={Palette.primarySubtleGreen}
            />
          </div>
        )}

        {loadingMore && <p>Loading more events...</p>}
      </WhiteContainer>
      <PaddingBottom amount={54} />
    </Scene>
  );
};
