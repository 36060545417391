import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from '../store/AuthProvider';
import {LoadingAnimation} from '../components/loading-animation/LoadingAnimation';
import {RootRoute} from './RootRoute';

const NavigateToLogin = () => {
  return (
    <Navigate
      to={`${RootRoute.LogIn}?returnUrl=${encodeURIComponent(
        window.location.pathname,
      )}`}
      replace
    />
  );
};

export const ProtectedEventRoute = () => {
  const {user, isLoading} = useAuth();

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (!user) {
    return <NavigateToLogin />;
  }

  return <Outlet />;
};
