import {Palette, Question, QuestionType} from 'hubbl-shared';
import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AssetIconAdd, AssetIconTrash} from '../../../../../assets';
import {
  BottomSheet,
  BottomSheetRef,
  CheckMarkWithLabel,
  Input,
  PaddingBottom,
  PrimaryButton,
  RadioGroup,
  SecondaryButton,
  TextHeading3,
  TextHeading7,
  Toast,
  ToastRef,
} from '../../../../../components';
import {IconButton} from '../../../../../components/buttons/icon-button/IconButton';
import {UserActions} from '../../../../../store/user/UserActions';
import {useLocation} from 'react-router-dom';

export const SceneBottomSheetCreateEventAddEditQuestions = memo(() => {
  const location = useLocation();

  // Access the question passed via state
  const question = location.state?.question as Question | undefined;

  const dispatch = useDispatch();
  const [questionText, setQuestionText] = useState<string>('');
  const [questionType, setQuestionType] = useState<QuestionType>(
    QuestionType.SingleChoice,
  );
  const [options, setOptions] = useState<string[]>(['']);
  const [isRequired, setIsRequired] = useState<boolean>(true);
  const [allowFreeText, setAllowFreeText] = useState(false);
  const [errors, setErrors] = useState<{
    questionText?: string;
    options?: boolean[];
  }>({
    questionText: undefined,
    options: [],
  });
  const toastRef = useRef<ToastRef>(null);
  const bottomSheetRef = useRef<BottomSheetRef>(null);

  useEffect(() => {
    // If editing, pre-fill the form with question data
    if (question) {
      setQuestionText(question.questionText);
      setQuestionType(question.type);
      setIsRequired(question.isRequired);
      setOptions(question.options?.map(option => option.text) || []);
      setAllowFreeText(question.allowFreeText);
    }
  }, [question]);

  const validateInputs = () => {
    const newErrors: typeof errors = {
      questionText: !questionText.trim()
        ? 'Question text cannot be empty'
        : undefined,
      options:
        questionType !== QuestionType.FreeText
          ? options.map(option => !option.trim())
          : [],
    };
    setErrors(newErrors);
    return (
      !newErrors.questionText && newErrors.options?.every(isValid => !isValid)
    );
  };

  const handleAddQuestion = () => {
    if (!validateInputs()) {
      return;
    }

    const newQuestion: Question = {
      id: question?.id || `${Date.now()}`, // If editing, use the existing question's ID
      questionText,
      type: questionType,
      options:
        questionType !== QuestionType.FreeText
          ? options.map(text => ({id: `${Date.now() + Math.random()}`, text}))
          : null,
      isRequired,
      allowFreeText,
    };

    toastRef.current?.showToast();
    if (question) {
      dispatch(UserActions.updateEventQuestion(newQuestion)); // Update existing question
    } else {
      dispatch(UserActions.addEventQuestion(newQuestion)); // Add new question
      setQuestionText('');
      setIsRequired(true);
      setOptions(['']);
      setAllowFreeText(false);
      setErrors({});
    }
  };

  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);

    setErrors(prev => ({
      ...prev,
      options: prev.options?.map((error, i) => (i === index ? false : error)),
    }));
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);

    setErrors(prev => ({
      ...prev,
      options: prev.options?.filter((_, i) => i !== index),
    }));
  };
  const onClose = useCallback(() => bottomSheetRef.current?.close(), []);
  return (
    <BottomSheet ref={bottomSheetRef}>
      <PaddingBottom amount={24} />
      <TextHeading3 text={question ? 'Edit question' : 'Add new question'} />
      <PaddingBottom amount={16} />

      <Input
        isCompact
        useAlternativeTheme
        value={questionText}
        onChange={e => setQuestionText(e.target.value)}
        placeholder="Write your question here"
        hasError={!!errors.questionText}
        validationErrorMessage={errors.questionText}
      />

      <PaddingBottom amount={16} />
      <TextHeading7 text="Question Type" />
      <PaddingBottom amount={8} />
      <RadioGroup
        options={[
          {label: 'Single Choice', value: QuestionType.SingleChoice},
          {label: 'Multiple Choice', value: QuestionType.MutipleChoice},
          {label: 'Free Text', value: QuestionType.FreeText},
        ]}
        selectedValue={questionType}
        onChange={value => setQuestionType(value as QuestionType)}
      />

      {/* Options */}
      {questionType !== QuestionType.FreeText && (
        <div>
          <PaddingBottom amount={16} />
          <TextHeading7 text="Options" />
          <PaddingBottom amount={8} />
          {options.map((option, index, arr) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}>
              <div style={{width: '100%'}}>
                <Input
                  value={option}
                  isCompact
                  useAlternativeTheme
                  onChange={e => handleOptionChange(index, e.target.value)}
                  placeholder={`Enter option ${index + 1} here`}
                  hasError={!!errors.options?.[index]}
                  validationErrorMessage={
                    errors.options?.[index]
                      ? 'Option cannot be empty'
                      : undefined
                  }
                />
              </div>

              {arr.length !== 1 && (
                <IconButton
                  icon={<AssetIconTrash color={Palette.systemError} />}
                  onClick={() => handleRemoveOption(index)}
                />
              )}
            </div>
          ))}
          <IconButton
            backgroundColor={Palette.primarySubtleGreen}
            hoverColor={Palette.primaryCherokee}
            icon={<AssetIconAdd />}
            iconPadding={8}
            text="Add option"
            onClick={() => {
              setOptions([...options, '']);
              setErrors(prev => ({
                ...prev,
                options: [...(prev.options || []), false],
              }));
            }}
          />
          <PaddingBottom amount={16} />
          <CheckMarkWithLabel
            isChecked={allowFreeText}
            onClick={() => setAllowFreeText(prev => !prev)}
            label="Allow free text answer"
          />
        </div>
      )}
      <PaddingBottom amount={16} />
      <CheckMarkWithLabel
        isChecked={isRequired}
        onClick={() => setIsRequired(prev => !prev)}
        label="Question is required"
      />

      <PaddingBottom amount={24} />
      <Toast
        ref={toastRef}
        message={
          question
            ? 'Changes saved successfully!'
            : 'Question added successfully!'
        }
      />
      <div style={{gap: 8, display: 'flex'}}>
        <PrimaryButton
          label={question ? 'Save changes' : 'Save question'}
          onClick={handleAddQuestion}
        />
        <SecondaryButton label={'Cancel'} onClick={onClose} />
      </div>
    </BottomSheet>
  );
});
