import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Palette, User} from 'hubbl-shared';
import {AssetIconLoadMore} from '../../../../assets';
import {
  PaddingBottom,
  Scene,
  Table,
  TableBody,
  TableCell,
  TableCellContent,
  TableHead,
  TableHeader,
  TableHeaderContent,
  TableRow,
  TextHeading1,
  WhiteContainer,
} from '../../../../components';
import {IconButton} from '../../../../components/buttons/icon-button/IconButton';
import {useAppSelector} from '../../../../hooks';
import {CompanyActions} from '../../../../store/company/CompanyActions';
import {CompanySelector} from '../../../../store/company/CompanySelector';
import {UserSelector} from '../../../../store/user/UserSelector';

export const SceneEmployeesOverview = () => {
  const dispatch = useDispatch();
  const {employees, loading, hasMore} = useAppSelector(
    CompanySelector.getCompanyEmployees,
  );

  const userDetails = useAppSelector(UserSelector.getUserDetails)!;

  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    dispatch(CompanyActions.fetchCompanyEmployees());
  }, [dispatch]);

  const loadMoreUsers = () => {
    if (loadingMore || !hasMore) return;

    setLoadingMore(true);
    dispatch(CompanyActions.fetchCompanyEmployees());
    setLoadingMore(false);
  };

  // Define table columns for Users
  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'photoUrl',
        header: '',
        accessorFn: row => row.photoUrl,
        id: 'photoUrl', // Use the photo URL as the first column
      },
      {
        accessorKey: 'fullName',
        header: 'Full Name',
        accessorFn: row => row.fullName,
      },
      {
        accessorKey: 'jobTitle',
        header: 'Job Title',
        accessorFn: row => row.jobTitle,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        accessorFn: row => row.email,
      },
      {
        accessorKey: 'department.label',
        header: 'Department',
        accessorFn: row =>
          row.department.label[userDetails.settings.preferredLanguage],
      },
      {
        accessorKey: 'stats.lastSignInDate',
        header: 'Last Sign-In',
        accessorFn: row => new Date(row.stats.lastSignInDate),
      },
    ],
    [userDetails.settings.preferredLanguage],
  );

  const table = useReactTable({
    data: employees,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Scene>
      <TextHeading1 text="Employees Overview" />

      <PaddingBottom amount={24} />
      <WhiteContainer>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header =>
                  header.index === 0 ? (
                    <TableHeader />
                  ) : (
                    <TableHeader
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}>
                      <TableHeaderContent
                        isSorted={header.column.getIsSorted()}
                        label={header.column.columnDef.header as string}
                      />
                    </TableHeader>
                  ),
                )}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <TableCell key={cell.id}>
                    <TableCellContent
                      columnId={cell.column.id}
                      value={cell.getValue()}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <p>Loading...</p>}

        {hasMore && !loadingMore && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <IconButton
              onClick={loadMoreUsers}
              icon={<AssetIconLoadMore />}
              text="Load more"
              backgroundColor={Palette.primarySubtleGreen}
            />
          </div>
        )}

        {loadingMore && <p>Loading more employees...</p>}
      </WhiteContainer>
      <PaddingBottom amount={54} />
    </Scene>
  );
};
