import {Api} from '../../api';
import {getAuthUserCompanyId} from '../../utils';
import {createAppAsyncThunk} from '../appAsyncThunk';
import {EventSelector} from './EventSelector';

// Fetch events thunk with pagination support
export const fetchEvents = createAppAsyncThunk(
  'events/fetchEvents',
  async (_, {getState}) => {
    try {
      const LIMIT_COUNT = 10;
      const {lastVisible} = EventSelector.getEvents(getState());
      const userCompanyId = await getAuthUserCompanyId();

      const {events, lastVisibleSnapshot} = await Api.event.getEvents(
        userCompanyId,
        LIMIT_COUNT,
        lastVisible,
      );

      // Return payload with events and lastVisible document snapshot
      return {
        events,
        lastVisible: lastVisibleSnapshot, // Make sure we pass the actual snapshot
        isNextPage: !!lastVisible, // Flag for pagination - true if this is not the first page
      };
    } catch (error) {
      console.error('Error fetching events:', error);
      throw new Error('Error fetching events');
    }
  },
);
