import React from 'react';

const TRANSITION_DURATION = '1s';
const TRANSITION_TIMING = 'ease-in-out';
const TRANSITION_PROPS = `${TRANSITION_DURATION} ${TRANSITION_TIMING}`;

interface GradientProps {
  className?: string;
  isAlternative?: boolean;
  alternativeColors?: {
    color1: string;
    color2: string;
    color3: string;
  };
}

export const Gradient: React.FC<GradientProps> = ({
  className,
  isAlternative = false,
  alternativeColors,
}) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1920 1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid slice">
      <g clipPath="url(#clip0_1096_4250)">
        <rect
          width="1920"
          height="1200"
          fill={isAlternative ? '#EFF4F2' : alternativeColors?.color1}
          style={{transition: `fill ${TRANSITION_PROPS}`}}
        />
        <g clipPath="url(#clip1_1096_4250)">
          <rect
            width="1920"
            height="1200"
            transform="translate(0 -80)"
            fill="white"
          />
          <rect
            width="1920"
            height="1200"
            transform="translate(0 -80)"
            fill={isAlternative ? '#EFF4F2' : alternativeColors?.color1}
            style={{transition: `fill ${TRANSITION_PROPS}`}}
          />
          <mask
            id="mask0_1096_4250"
            style={{maskType: 'alpha'}}
            maskUnits="userSpaceOnUse"
            x="0"
            y="-80"
            width="1920"
            height="1200">
            <rect
              x="1920"
              y="1120"
              width="1920"
              height="1200"
              transform="rotate(-180 1920 1120)"
              fill="url(#paint0_linear_1096_4250)"
            />
          </mask>
          <g mask="url(#mask0_1096_4250)">
            <g filter="url(#filter0_f_1096_4250)">
              <ellipse
                cx="787.999"
                cy="87.168"
                rx="1365.33"
                ry="650.215"
                fill={isAlternative ? '#E07A5F' : alternativeColors?.color2}
                style={{transition: `fill ${TRANSITION_PROPS}`}}
              />
            </g>
            <g filter="url(#filter1_f_1096_4250)">
              <ellipse
                cx="1328.13"
                cy="661.983"
                rx="1328.13"
                ry="661.983"
                transform="matrix(0.962545 -0.271122 0.754067 0.656798 -817.334 311.107)"
                fill={isAlternative ? '#F2CC8F' : alternativeColors?.color3}
                style={{transition: `fill ${TRANSITION_PROPS}`}}
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_1096_4250"
          x="-769.334"
          y="-755.047"
          width="3114.67"
          height="1684.43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="96"
            result="effect1_foregroundBlur_1096_4250"
          />
        </filter>
        <filter
          id="filter1_f_1096_4250"
          x="-704.525"
          y="-470.742"
          width="3329.52"
          height="1713.11"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="146"
            result="effect1_foregroundBlur_1096_4250"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1096_4250"
          x1="2880"
          y1="1000"
          x2="2880"
          y2="2080"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_1096_4250">
          <rect width="1920" height="1200" fill="white" />
        </clipPath>
        <clipPath id="clip1_1096_4250">
          <rect
            width="1920"
            height="1200"
            fill="white"
            transform="translate(0 -80)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
