import {
  deleteUser,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import {httpsCallable} from 'firebase/functions';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {firebaseFunctions} from '../../../../api/firebase';
import {useAppDispatch} from '../../../../hooks';
import {RootRoute} from '../../../../routes/RootRoute';
import {fetchCompany} from '../../../../store/company/CompanyThunks';
import {GlobalActions} from '../../../../store/global/GlobalActions';
import {fetchUserDetails} from '../../../../store/user/UserThunks';
import styled from 'styled-components';
import {TextHeading5} from '../../../../components';
import {Api} from '../../../../api';

interface GoogleSignInButtonProps {
  returnUrl: string | null;
}

export const GoogleSignInButton = ({returnUrl}: GoogleSignInButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onGoogleSignIn = useCallback(async () => {
    try {
      dispatch(GlobalActions.setIsBusy(true));
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      // Sign in with Google
      const result = await signInWithPopup(auth, provider);

      const additionalUserInfo = getAdditionalUserInfo(result);

      //If new user, sign them up correctly, and move them to the correct company, only if there's a match with a restricted domain
      if (additionalUserInfo?.isNewUser && result.user.email) {
        try {
          const company = await Api.company.getCompanyByDomain(
            result.user.email,
          );

          if (company) {
            let retryCount = 0;
            const maxRetries = 3;
            let success = false;

            while (retryCount < maxRetries && !success) {
              try {
                await Api.user.onMoveUnattachedUserToCompany(company.pincode);
                await new Promise(resolve => setTimeout(resolve, 1000));
                await auth.currentUser?.reload();
                const userDetails = await dispatch(fetchUserDetails()).unwrap();
                if (userDetails?.companyId === company.companyId) {
                  success = true;
                } else {
                  retryCount++;
                  if (retryCount === maxRetries) {
                    console.error(
                      'Failed to associate user with company after retries',
                    );
                    await deleteUser(result.user);
                    alert('Failed to set up your account. Please try again.');
                    return;
                  }
                  await new Promise(resolve => setTimeout(resolve, 2000));
                }
              } catch (error) {
                retryCount++;
                if (retryCount === maxRetries) {
                  console.error(
                    'Failed to move user to company after retries:',
                    error,
                  );
                  await deleteUser(result.user);
                  alert('Failed to set up your account. Please try again.');
                  return;
                }
                await new Promise(resolve => setTimeout(resolve, 2000));
              }
            }
          } else {
            await deleteUser(result.user);
            alert('You dont have access to this app');
            return;
          }
        } catch (error) {
          console.error('Error fetching company:', error);
        }
      }

      if (returnUrl && result.user) {
        await dispatch(fetchUserDetails());
        await dispatch(fetchCompany());
        navigate(returnUrl, {replace: true});
        return;
      }

      const checkStatus = httpsCallable<{isAdmin: boolean}>(
        firebaseFunctions,
        'onCheckAuth',
      );

      // Call the backend function to check if the user is an admin
      let response;
      try {
        response = await checkStatus();
      } catch (error) {
        throw new Error('You dont have access');
      }

      // @ts-ignore
      if (!response || !response.data || !response.data.isAdmin) {
        await signOut(auth);
        throw new Error('You dont have access.');
      }

      // Fetch user details and company data
      await dispatch(fetchUserDetails());
      await dispatch(fetchCompany());

      navigate(RootRoute.AuthAdminHome, {replace: true});
    } catch (error) {
      console.error('Google sign-in error:', error);
      //@ts-ignore
      alert(error.message || 'You dont have access.');
    } finally {
      dispatch(GlobalActions.setIsBusy(false));
    }
  }, [dispatch, navigate, returnUrl]);
  return (
    <StyledButton onClick={onGoogleSignIn}>
      <MaterialButtonState />
      <ButtonContentWrapper>
        <ButtonIcon>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{display: 'block'}}>
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </svg>
        </ButtonIcon>
        <TextHeading5
          center
          text={'Sign in with Google'}
          style={{
            flexGrow: 1,
            fontSize: 12,
            WebkitFlexGrow: 1,
          }}
        />
      </ButtonContentWrapper>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 36px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;
  min-width: min-content;

  &:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }

  &:disabled .gsi-material-button-contents {
    opacity: 38%;
  }

  &:disabled .gsi-material-button-icon {
    opacity: 38%;
  }

  &:not(:disabled):active .gsi-material-button-state,
  &:not(:disabled):focus .gsi-material-button-state {
    background-color: #303030;
    opacity: 12%;
  }

  &:not(:disabled):hover {
    -webkit-box-shadow:
      0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow:
      0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }

  &:not(:disabled):hover .gsi-material-button-state {
    background-color: #303030;
    opacity: 8%;
  }
`;

const MaterialButtonState = styled.div`
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const ButtonContentWrapper = styled.div`
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const ButtonIcon = styled.div`
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
`;
