import {configureStore} from '@reduxjs/toolkit';
import {UserReducer} from './user/UserReducer';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import {CompanyReducer} from './company/CompanyReducer';
import {GetStartedReducer} from './get-started/GetStartedReducer';
import {GlobalReducer} from './global/GlobalReducer';
import {EventReducer} from './events/EventReducer';
const persistConfig = {
  key: 'root',
  storage,
};

const persistedUserReducer = persistReducer(persistConfig, UserReducer);
const persistedCompanyReducer = persistReducer(persistConfig, CompanyReducer);

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    company: persistedCompanyReducer,
    getStarted: GetStartedReducer,
    global: GlobalReducer,
    events: EventReducer,
  },
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
