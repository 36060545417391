import {AnimatePresence, motion} from 'framer-motion';
import {Palette, Question, QuestionResponses, QuestionType} from 'hubbl-shared';
import {useCallback, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from 'styled-components';
import {Api} from '../../../../../../api';
import {
  FieldError,
  ManagedInput,
  PaddingBottom,
  PrimaryButton,
  TextHeading2,
  TextHeading4,
  TextHeading7,
  WhiteContainer,
} from '../../../../../../components';
import {AccordionSelector} from '../../../../../../components/accordion-selector/AccordionSelector';
import {CloseButton} from '../../../../../../components/buttons/close-button/CloseButton';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks';
import {GlobalActions} from '../../../../../../store/global/GlobalActions';
import {UserSelector} from '../../../../../../store/user/UserSelector';

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalWrapper = styled(motion.div)`
  width: 600px;
  max-width: 90%;
  transform-origin: center;
`;

const QuestionContainer = styled(motion.div)`
  margin-bottom: 2.5rem;

  border-radius: 12px;

  transition: all 0.2s ease;

  &:hover {
  }
`;

const QuestionTextContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 1.5rem;
  align-items: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const ProgressBar = styled.div<{progress: number}>`
  height: 4px;
  background: ${Palette.almostBlackTransparent10};
  border-radius: 2px;
  flex: 1;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: ${props => props.progress}%;
    background: ${Palette.primaryTerraCotta};
    transition: width 0.3s ease;
  }
`;

const RequiredBadge = styled.span`
  background: ${Palette.systemError};
  color: ${Palette.white};
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  eventId: string;
  companyId: string;
  questions: Question[];
  onSuccess: () => void;
}

type FormData = Record<string, string | string[]>;

export const QuestionsModal = ({
  isOpen,
  onClose,
  eventId,
  companyId,
  questions,
  onSuccess,
}: Props) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const {handleSubmit, control, trigger, reset} = useForm<FormData>({
    mode: 'onChange',
  });

  const userDetails = useAppSelector(UserSelector.getUserDetails);

  // Watch form values to update progress
  const progress = ((currentQuestionIndex + 1) / questions.length) * 100;
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    reset();
    setCurrentQuestionIndex(0);
    onClose();
  }, [reset, onClose]);

  const onSubmit = useCallback(
    async (formData: FormData) => {
      if (!userDetails) {
        return;
      }

      try {
        dispatch(GlobalActions.setIsBusy(true));
        const responses: QuestionResponses = {};

        questions.forEach(question => {
          responses[question.id] = [
            {
              user: {
                email: userDetails.email,
                fullName: userDetails.fullName,
                id: userDetails.id,
                department: userDetails.department.label.en,
              },
              questionId: question.id,
              selectedOptions:
                question.type !== QuestionType.FreeText
                  ? (formData[question.id] as string[]) || []
                  : [],
              freeTextResponse:
                question.type === QuestionType.FreeText ||
                question.allowFreeText
                  ? (formData[`${question.id}_freeText`] as string) || null
                  : null,
            },
          ];
        });

        await Api.event.acceptEventWithQuestionResponses(
          companyId,
          eventId,
          userDetails,
          responses,
        );

        onSuccess();
        onClose();
        setCurrentQuestionIndex(0);
        reset();
      } catch (error) {
        console.error('Failed to submit responses:', error);
      } finally {
        dispatch(GlobalActions.setIsBusy(false));
      }
    },
    [
      userDetails,
      questions,
      onClose,
      companyId,
      eventId,
      onSuccess,
      dispatch,
      reset,
    ],
  );

  const handleNext = useCallback(async () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (currentQuestion.isRequired) {
      const isValid = await trigger(currentQuestion.id);
      if (!isValid) {
        return;
      }
    }
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  }, [questions, currentQuestionIndex, trigger]);

  const handleBack = useCallback(() => {
    setCurrentQuestionIndex(prev => prev - 1);
  }, []);

  const handleSubmitClick = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          onClick={handleClose}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.2, ease: 'easeInOut'}}>
          <ModalWrapper
            onClick={e => e.stopPropagation()}
            initial={{y: 20, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: -20, opacity: 0}}
            transition={{duration: 0.2, ease: 'easeInOut'}}
            whileHover={{
              scale: 1.01,
              transition: {duration: 0.2, ease: 'easeInOut'},
            }}>
            <WhiteContainer
              color={Palette.transparent}
              gradient={`linear-gradient(135deg, ${Palette.primaryTerraCotta} -20%, ${Palette.primaryCherokee} 30%, ${Palette.primarySubtleGreen} 90%)`}>
              <CloseButton onClick={onClose} />
              <form
                onSubmit={e => e.preventDefault()}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    if (currentQuestionIndex === questions.length - 1) {
                      handleSubmitClick();
                    } else {
                      handleNext();
                    }
                  }
                }}>
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{delay: 0.1, duration: 0.2, ease: 'easeInOut'}}>
                  <PaddingBottom amount={16} />
                  <TextHeading2 text="Answer Questions" />
                  <PaddingBottom amount={16} />
                </motion.div>

                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{delay: 0.2, duration: 0.5, ease: 'easeInOut'}}>
                  <ProgressContainer>
                    <ProgressBar progress={progress} />
                    <TextHeading7
                      text={`Question ${currentQuestionIndex + 1} of ${
                        questions.length
                      }`}
                    />
                  </ProgressContainer>
                </motion.div>

                <AnimatePresence mode="wait">
                  {questions.map(
                    (question, index) =>
                      index === currentQuestionIndex && (
                        <QuestionContainer
                          key={question.id}
                          initial={{opacity: 0, x: 20}}
                          animate={{opacity: 1, x: 0}}
                          exit={{opacity: 0, x: -20}}
                          transition={{duration: 0.2, ease: 'easeInOut'}}>
                          <QuestionTextContainer>
                            <TextHeading4 text={question.questionText} />
                            {question.isRequired && (
                              <RequiredBadge>Required</RequiredBadge>
                            )}
                          </QuestionTextContainer>

                          {(question.type === QuestionType.SingleChoice ||
                            question.type === QuestionType.MutipleChoice) && (
                            <Controller
                              control={control}
                              name={question.id}
                              defaultValue={[]}
                              rules={{
                                required: question.isRequired
                                  ? 'This question is required'
                                  : undefined,
                              }}
                              render={({
                                field: {onChange, value},
                                fieldState: {error},
                              }) => (
                                <>
                                  <AccordionSelector
                                    data={question.options ?? []}
                                    accordionType={
                                      question.type ===
                                      QuestionType.SingleChoice
                                        ? 'select'
                                        : 'multiselect'
                                    }
                                    keyExtractor={item => item.id}
                                    labelExtractor={item => item.text}
                                    initialValue={
                                      Array.isArray(value)
                                        ? value
                                            .map(
                                              id =>
                                                question.options?.find(
                                                  opt => opt.id === id,
                                                ),
                                            )
                                            .filter(
                                              (
                                                opt,
                                              ): opt is {
                                                id: string;
                                                text: string;
                                              } => opt !== undefined,
                                            )
                                        : []
                                    }
                                    onSelectionChange={selectedItems => {
                                      onChange(
                                        selectedItems.map(item => item.id),
                                      );
                                    }}
                                  />
                                  {error && (
                                    <FieldError
                                      errorMessage={error.message ?? ''}
                                      hasError={true}
                                    />
                                  )}
                                </>
                              )}
                            />
                          )}

                          <PaddingBottom amount={16} />

                          {question.type === QuestionType.FreeText && (
                            <ManagedInput
                              isCompact
                              controlKey={`${question.id}_freeText`}
                              control={control}
                              placeholder="Enter your answer"
                              isRequiredMessage={
                                question.isRequired
                                  ? 'This question is required'
                                  : undefined
                              }
                            />
                          )}

                          {question.allowFreeText &&
                            question.type !== QuestionType.FreeText && (
                              <ManagedInput
                                isCompact
                                controlKey={`${question.id}_freeText`}
                                control={control}
                                placeholder="Enter any additional comments"
                              />
                            )}
                        </QuestionContainer>
                      ),
                  )}
                </AnimatePresence>

                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{delay: 0.3, duration: 0.2, ease: 'easeInOut'}}>
                  <ButtonContainer>
                    {currentQuestionIndex > 0 && (
                      <PrimaryButton
                        label="Back"
                        onClick={handleBack}
                        customWidth={120}
                      />
                    )}
                    {currentQuestionIndex === questions.length - 1 ? (
                      <PrimaryButton
                        label="Submit"
                        onClick={handleSubmitClick}
                      />
                    ) : (
                      <PrimaryButton
                        label="Next"
                        onClick={handleNext}
                        customWidth={120}
                      />
                    )}
                  </ButtonContainer>
                </motion.div>
              </form>
            </WhiteContainer>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};
