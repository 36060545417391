import {Palette} from 'hubbl-shared';
import {AssetIconProps} from '../types';
import {memo} from 'react';

export const AssetIconEyeOpen = memo(
  ({size = 24, color = Palette.almostBlack}: AssetIconProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.75 12C2.75 10.985 3.39346 9.16305 4.91524 7.58273C6.4072 6.03338 8.71554 4.75 12 4.75C15.2845 4.75 17.5928 6.03338 19.0848 7.58273C20.6065 9.16305 21.25 10.985 21.25 12C21.25 13.015 20.6065 14.837 19.0848 16.4173C17.5928 17.9666 15.2845 19.25 12 19.25C8.71554 19.25 6.4072 17.9666 4.91524 16.4173C3.39346 14.837 2.75 13.015 2.75 12ZM12 3.25C8.28446 3.25 5.5928 4.71662 3.83476 6.54227C2.10654 8.33695 1.25 10.515 1.25 12C1.25 13.485 2.10654 15.663 3.83476 17.4577C5.5928 19.2834 8.28446 20.75 12 20.75C15.7155 20.75 18.4072 19.2834 20.1652 17.4577C21.8935 15.663 22.75 13.485 22.75 12C22.75 10.515 21.8935 8.33695 20.1652 6.54227C18.4072 4.71662 15.7155 3.25 12 3.25ZM9.74991 12C9.74991 10.7574 10.7573 9.75 11.9999 9.75C13.2425 9.75 14.2499 10.7574 14.2499 12C14.2499 13.2426 13.2425 14.25 11.9999 14.25C10.7573 14.25 9.74991 13.2426 9.74991 12ZM11.9999 8.25C9.92884 8.25 8.24991 9.92893 8.24991 12C8.24991 14.0711 9.92884 15.75 11.9999 15.75C14.071 15.75 15.7499 14.0711 15.7499 12C15.7499 9.92893 14.071 8.25 11.9999 8.25Z"
          fill={color}
        />
      </svg>
    );
  },
);
