import {memo} from 'react';
import styled from 'styled-components';
import {Palette} from 'hubbl-shared';
import {UserInfoDisplay} from '../user-info/UserInfoDisplay';
import {PaddingRight} from '../padding/PaddingRight';

export const Topbar = memo(() => {
  return (
    <StyledTopbar>
      <UserInfoDisplay />
      <PaddingRight amount={24} />
    </StyledTopbar>
  );
});

const StyledTopbar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
  background-color: ${Palette.whiteTransparent60};
  height: 60px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0px 2.858px 17.148px 0px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;
`;

const StyledProfilePicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  object-fit: cover;
  object-position: center;
`;
