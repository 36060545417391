import {Palette} from 'hubbl-shared';
import styled from 'styled-components';

const StyledCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: ${Palette.almostBlack};
  font-size: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${Palette.whiteTransparent40};
  }
`;

interface CloseButtonProps {
  onClick: () => void;
}

export const CloseButton = ({onClick}: CloseButtonProps) => (
  <StyledCloseButton onClick={onClick}>&times;</StyledCloseButton>
);
