import {Palette} from 'hubbl-shared';
import styled from 'styled-components';

export const TableRow = styled.tr`
  background-color: ${() => Palette.white};
  &:hover {
    background-color: ${() => Palette.primarySubtleGreen};
    cursor: pointer;
  }
`;
