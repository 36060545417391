import {Palette} from 'hubbl-shared';
import {AssetIconProps} from '../types';
import {memo} from 'react';

export const AssetIconCheckmarkEmpty = memo(
  ({size = 24, color = Palette.almostBlack}: AssetIconProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.333333"
          y="0.333333"
          width="15.3333"
          height="15.3333"
          rx="2.33333"
          stroke={color}
          stroke-width="0.666667"
        />
      </svg>
    );
  },
);
