import {getSvgPath} from 'figma-squircle';
import {memo, useLayoutEffect, useRef, useState} from 'react';
import styled from 'styled-components';

interface SquircleImageProps {
  src: string;
  alt: string;
  height: number;
  borderRadius?: number;
}

export const SquircleImage = memo(
  ({src, alt, height, borderRadius = 24}: SquircleImageProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useLayoutEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      };

      updateWidth();
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const svgPath = getSvgPath({
      width: containerWidth,
      height,
      cornerRadius: borderRadius,
      cornerSmoothing: 1,
    });

    return (
      <Container ref={containerRef} style={{height}}>
        <StyledSvg
          width="100%"
          height={height}
          viewBox={`0 0 ${containerWidth} ${height}`}>
          <defs>
            <clipPath id={`squircle-clip-${containerWidth}-${height}`}>
              <path d={svgPath} />
            </clipPath>
          </defs>
          <image
            href={src}
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            clipPath={`url(#squircle-clip-${containerWidth}-${height})`}
          />
        </StyledSvg>
      </Container>
    );
  },
);

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSvg = styled.svg`
  display: block;
  width: 100%;
  box-shadow: 0px 2.858px 17.148px 0px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
`;
