import {useEffect} from 'react';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import {LoadingAnimation} from '../components/loading-animation/LoadingAnimation';
import {useAppDispatch} from '../hooks';
import {AdminWebRoute} from '../routes/AdminWebRoute';
import {ProtectedEventRoute} from '../routes/ProtectedEventRoute';
import {ProtectedAdminRoute} from '../routes/ProtectedAdminRoute';
import {RootRoute} from '../routes/RootRoute';
import {
  SceneEventEndpoint,
  SceneAdminHome,
  SceneBottomSheetCreateEventAddEditQuestions,
  SceneCreateEvent,
  SceneDashboard,
  SceneEmployeesOverview,
  SceneEventDetails,
  SceneEventsOverview,
  SceneGetStarted,
  SceneGetStartedCompleted,
  SceneInvited,
  SceneLogin,
} from '../scenes';
import {useAuth} from '../store/AuthProvider';
import {fetchCompany} from '../store/company/CompanyThunks';
import {fetchUserDetails} from '../store/user/UserThunks';

export const RootNavigator = () => {
  const {user, isLoading, isAdmin} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoading && user) {
      const currentPath = window.location.pathname;
      const params = new URLSearchParams(location.search);
      const returnUrl = params.get('returnUrl');

      // Fetch user details and company data regardless of user type
      dispatch(fetchUserDetails());
      dispatch(fetchCompany());

      if (isAdmin) {
        if (currentPath === RootRoute.LogIn) {
          // If admin is on login page, redirect to admin dashboard
          navigate(RootRoute.AuthAdminHome, {replace: true});
        }
      } else {
        if (currentPath === RootRoute.LogIn) {
          if (returnUrl) {
            // If there's a return URL, navigate there
            navigate(returnUrl, {replace: true});
          } else {
            // If no return URL and not admin, redirect to login
            navigate(RootRoute.LogIn, {replace: true});
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAdmin, isLoading, dispatch]);

  if (isLoading) {
    // Show loading screen while checking authentication
    return <LoadingAnimation />;
  }

  return (
    <Routes>
      <Route path={RootRoute.LogIn} element={<SceneLogin />} />
      <Route path={RootRoute.GetStarted} element={<SceneGetStarted />} />
      <Route
        path={RootRoute.GetStartedCompleted}
        element={<SceneGetStartedCompleted />}
      />
      <Route path={RootRoute.Invited} element={<SceneInvited />} />

      {/* Admin routes */}
      <Route element={<ProtectedAdminRoute />}>
        <Route path={RootRoute.AuthAdminHome} element={<SceneAdminHome />}>
          <Route index element={<SceneDashboard />} />
          <Route path={AdminWebRoute.Dashboard} element={<SceneDashboard />} />
          <Route
            path={AdminWebRoute.EventsOverview}
            element={<SceneEventsOverview />}
          />
          <Route
            path={`${AdminWebRoute.EventsOverview}/${AdminWebRoute.EventDetails}/:eventId`}
            element={<SceneEventDetails />}
          />
          <Route
            path={AdminWebRoute.EmployeesOverview}
            element={<SceneEmployeesOverview />}
          />
          <Route
            path={AdminWebRoute.CreateEvent}
            element={<SceneCreateEvent />}>
            <Route
              path={AdminWebRoute.CreateEventAddEditQuestions}
              element={<SceneBottomSheetCreateEventAddEditQuestions />}
            />
          </Route>
        </Route>
        {/* <Route
          path={RootRoute.AuthAdminLandingPage}
          element={<_SceneAdminLandingPage />}>
          <Route index element={<_SceneDashboard />} />

          <Route
            path={AdminWebRoute.Dashboard}
            element={<SceneDashboard />}></Route>
          <Route
            path={AdminWebRoute.Employees}
            element={<SceneEmployees />}></Route>
          <Route
            path={AdminWebRoute.AddInterestStatistic}
            element={<SceneAddInterestStatistic />}></Route>
          <Route
            path={AdminWebRoute.AddHubblSuggestion}
            element={<SceneAddHubblSuggestion />}></Route>

          <Route
            path="*"
            element={<Navigate to={AdminWebRoute.Dashboard} replace />}
          />
        </Route> */}
      </Route>

      {/* Event routes */}
      <Route element={<ProtectedEventRoute />}>
        <Route
          path={`${RootRoute.Event}/:eventId`}
          element={<SceneEventEndpoint />}
        />
      </Route>

      {/** TODO: Add not found scene */}
      <Route path="*" Component={() => <>'404 Page Not Found'</>} />
    </Routes>
  );
};
