import {memo} from 'react';
import {Control, Controller} from 'react-hook-form';
import {FieldError} from '../input/FieldError';
import {EmojiSelectorPopover} from './EmojiSelectorPopover';

interface ManagedEmojiSelectorPopoverProps {
  control: Control;
  controlKey: string;
  isRequiredMessage?: string;
  pattern?: {message: string; value: RegExp};
  customValidationFunc?: (currentValue: string) => true | string;
}

export const ManagedEmojiSelectorPopover = memo(
  ({
    control,
    controlKey,
    isRequiredMessage,
    pattern,
    customValidationFunc,
  }: ManagedEmojiSelectorPopoverProps) => {
    return (
      <Controller
        control={control}
        rules={{
          required: isRequiredMessage,
          pattern,
          validate: customValidationFunc,
        }}
        render={({fieldState: {error}, field: {onChange, value}}) => {
          return (
            <>
              <EmojiSelectorPopover
                onChangeEmoji={onChange}
                selectedEmoji={value}
              />
              {error && (
                <FieldError
                  errorMessage={error.message ?? ''}
                  hasError={Boolean(error)}
                />
              )}
            </>
          );
        }}
        name={controlKey}
      />
    );
  },
);
