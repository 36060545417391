import {useEffect, useState} from 'react';
import {Api} from '../../api';

export const useStorageImageUrl = (imageRef: string | undefined) => {
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const getImageUrl = async () => {
      try {
        if (imageRef) {
          const fetchedImageUrl =
            await Api.storage.getFirebaseStorageImageUrl(imageRef);

          setImageUrl(fetchedImageUrl);
        } else {
          setImageUrl(imageRef);
        }
      } catch (error) {
        setImageUrl(imageRef);
      }
    };
    getImageUrl();
  }, [imageRef]);

  return imageUrl;
};
