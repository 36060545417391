import {resetCompanyDetails, setApideckSession} from './CompanyReducer';
import {
  fetchCompany,
  addHRIStoCompany,
  fetchCompanyEmployees,
} from './CompanyThunks';

export const CompanyActions = {
  setApideckSession,
  fetchCompany,
  addHRIStoCompany,
  resetCompanyDetails,
  fetchCompanyEmployees,
};
