import {Palette} from 'hubbl-shared';
import {useCallback, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {TextHeading5} from '../text/TextHeading5';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
`;

const OptionContainer = styled.div<{
  isSelected: boolean;
  isFirst: boolean;
  isLast: boolean;
}>`
  height: 72px;
  padding: 0 24px 0 16px;
  background: ${props =>
    props.isSelected ? Palette.white : Palette.whiteTransparent60};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: ${props =>
    props.isFirst ? '16px 16px 0 0' : props.isLast ? '0 0 16px 16px' : '0'};

  &:hover {
    background: ${Palette.white};
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const scaleIn = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
`;

const RadioButton = styled.div<{isSelected: boolean}>`
  width: 16px;
  height: 16px;
  border: 1px solid ${Palette.almostBlack};
  border-radius: 8px;
  background: ${Palette.whiteTransparent60};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${Palette.almostBlack};
    border-radius: 4px;
    animation: ${props => (props.isSelected ? scaleIn : scaleOut)} 0.3s ease-out
      forwards;
  }
`;

const CheckMark = styled.div<{isSelected: boolean}>`
  width: 24px;
  height: 24px;
  border: 2px solid
    ${props => (props.isSelected ? Palette.almostBlack : Palette.almostBlack)};
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: ${Palette.almostBlack};
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    animation: ${props => (props.isSelected ? scaleIn : scaleOut)} 0.3s ease-out
      forwards;
  }
`;

interface Option {
  id: string;
  text: string;
}

interface Props {
  data: Option[];
  accordionType: 'select' | 'multiselect';
  keyExtractor: (item: Option) => string;
  labelExtractor: (item: Option) => string;
  onSelectionChange: (selectedItems: Option[]) => void;
  initialValue?: Option[];
}

export const AccordionSelector = ({
  data,
  accordionType,
  onSelectionChange,
  initialValue = [],
}: Props) => {
  const [selectedItems, setSelectedItems] = useState<Option[]>(initialValue);

  const handleOptionClick = useCallback(
    (option: Option) => {
      if (accordionType === 'select') {
        setSelectedItems([option]);
        onSelectionChange([option]);
      } else {
        const isSelected = selectedItems.some(item => item.id === option.id);
        const newSelectedItems = isSelected
          ? selectedItems.filter(item => item.id !== option.id)
          : [...selectedItems, option];
        setSelectedItems(newSelectedItems);
        onSelectionChange(newSelectedItems);
      }
    },
    [accordionType, selectedItems, onSelectionChange],
  );

  return (
    <Container>
      {data.map((option, index) => {
        const isSelected = selectedItems.some(item => item.id === option.id);
        return (
          <OptionContainer
            key={option.id}
            isSelected={isSelected}
            isFirst={index === 0}
            isLast={index === data.length - 1}
            onClick={() => handleOptionClick(option)}>
            <InnerContainer>
              {accordionType === 'multiselect' && (
                <CheckMark isSelected={isSelected} />
              )}
              <TextHeading5 text={option.text} />
            </InnerContainer>
            {accordionType === 'select' && (
              <RadioButton isSelected={isSelected} />
            )}
          </OptionContainer>
        );
      })}
    </Container>
  );
};
