import {
  resetUserDetails,
  addEventQuestion,
  removeEventQuestion,
  reorderQuestions,
  resetEventQuestions,
  updateEventQuestion,
} from './UserReducer';
import {
  fetchUserDetails,
  signInUser,
  createEvent,
  signInAdmin,
} from './UserThunks';

export const UserActions = {
  fetchUserDetails,
  addEventQuestion,
  removeEventQuestion,
  resetEventQuestions,
  updateEventQuestion,
  createEvent,
  reorderQuestions,
  resetUserDetails,
  signInUser,
  signInAdmin,
};
