import {getDownloadURL, ref as storageRef, uploadBytes} from 'firebase/storage';
import {firebaseStorage} from '../firebase';
import {FirebaseStorageFolder} from 'hubbl-shared';

const getFirebaseStorageImageUrl = async (imageRefPath: string) => {
  const imageRef = storageRef(firebaseStorage, imageRefPath);

  return getDownloadURL(imageRef);
};

const uploadImage = async (
  imageFile: File,
  companyId: string,
  eventId: string,
) => {
  const refUri = `${FirebaseStorageFolder.Companies}${companyId}/${FirebaseStorageFolder.Events}${eventId}`;

  const ref = storageRef(firebaseStorage, refUri);

  const snapshot = await uploadBytes(ref, imageFile);

  return snapshot.ref.fullPath;
};

export const storage = {
  uploadImage,
  getFirebaseStorageImageUrl,
};
