import {Palette} from 'hubbl-shared';
import {forwardRef, useCallback, useImperativeHandle, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {PaddingBottom} from '../padding/PaddingBottom';
import {Squircle} from '../squircle/Squircle';
import {TextHeading5} from '../text/TextHeading5';

interface ToastProps {
  message: string | null;
  isError?: boolean;
}

const fadeInOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
`;

const ToastWrapper = styled.div<{isVisible: boolean}>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  animation: ${fadeInOut} 3s ease;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
`;

export interface ToastRef {
  showToast: () => void;
}

export const Toast = forwardRef(({message, isError}: ToastProps, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  const showToast = useCallback(() => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 3000); // Hide after 3 seconds
  }, []);

  // Expose methods to the parent via ref
  useImperativeHandle(ref, () => ({
    showToast: () => {
      showToast();
    },
  }));

  if (!message || !isVisible) return null; // Don't render the toast if there's no message

  return (
    <ToastWrapper isVisible={isVisible}>
      {/* Use the Squircle container with desired dimensions */}
      <Squircle
        width={300}
        height={100}
        fillColor={isError ? Palette.systemError : Palette.systemApproved}
        borderRadius={16}>
        <PaddingBottom amount={16} />
        <TextHeading5 color={Palette.white} center text={message} />
        <PaddingBottom amount={16} />
      </Squircle>
    </ToastWrapper>
  );
});
