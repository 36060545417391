import React from 'react';
import {MenuItem} from 'react-pro-sidebar';
import {Link} from 'react-router-dom';
import {AdminWebRoute} from '../../../../../routes/AdminWebRoute';
import {TextHeading5} from '../../../../text/TextHeading5';
import {AssetIconChevron} from '../../../../../assets';

interface SidebarItemProps {
  title: string;
  to: AdminWebRoute;
  icon: React.ReactNode;
  currentRoute: string;
}

export const SidebarItem = ({
  icon,
  currentRoute,
  title,
  to,
}: SidebarItemProps) => {
  return (
    <Link style={{textDecoration: 'none'}} to={to}>
      <MenuItem
        suffix={
          <div style={{height: 20}}>
            <AssetIconChevron />
          </div>
        }
        style={{height: 72, transition: 'ease background-color 250ms'}}
        active={currentRoute.includes(to)} // Use startsWith for section-based highlighting
        icon={icon}>
        <TextHeading5 text={title} />
      </MenuItem>
    </Link>
  );
};
