import {Palette} from 'hubbl-shared';
import {AssetIconProps} from '../types';
import {memo} from 'react';

export const AssetIconCheckmarkFilled = memo(
  ({size = 16, color = Palette.almostBlack}: AssetIconProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          fill="white"
          stroke={color}
        />
        <path
          d="M5 7.24268L7.82843 10.0711L12.0711 5.82846"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  },
);
