import {ReactElement} from 'react';
import {RootRoute, WebRoute} from './RootRoute';

import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from '../store/AuthProvider';
import {LoadingAnimation} from '../components/loading-animation/LoadingAnimation';
interface ProtectedRouteProps {
  redirectPath?: WebRoute;
  children?: ReactElement;
}

export const ProtectedAdminRoute = ({
  redirectPath = RootRoute.LogIn,
  children,
}: ProtectedRouteProps) => {
  const {user, isLoading, isAdmin} = useAuth();

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (!user || !isAdmin) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
