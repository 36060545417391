import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '..';

const getCompanyDetails = createSelector(
  (state: RootState) => state.company.companyDetails,
  companyDetails => companyDetails,
);
const getCompanyEmployees = createSelector(
  (state: RootState) => state.company.employeeOverview,
  employees => employees,
);

const getApideckSession = createSelector(
  (state: RootState) => state.company.apideckSession,
  session => session,
);

export const CompanySelector = {
  getCompanyDetails,
  getApideckSession,
  getCompanyEmployees,
};
